import { Accordion, AccordionDetails, AccordionSummary, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import moneyFormat from '../../../../../hooks/moneyFormat';
import { getAllIncomeTypes } from '../../../../../data/api/incomes/IncomeRoutes';
import validErrors from '../../../../../hooks/validErrors';
import useIndex from '../../../../../hooks/useIndex';

const AgreementConfig = ({
    register,
    panel,
    handleExpand,
    setValue,
    errors
}) => {
    const { token } = useIndex();

    const [agAc, setAgAc] = useState(1000);
    const [movesAg, setMovesAg] = useState(1);
    const [giftMoves, setGiftMoves] = useState(null);
    const [errorSection, setErrorSection] = useState(false);
    const [incomeTypes, setIncomeTypes] = useState([]);

    useEffect(() => {
        loadPaymentMethods();
    }, []);

    useEffect(() => {
        setMovesAg((agAc / 1000) < 1 ? 0 : (agAc / 1000));
        setValue('agreement_amount', agAc);
    }, [agAc]);

    useEffect(() => {
        if (
            errors.agreement_type
            || errors.currency
            || errors.income_types_id
            || errors.days_to_pay
            || errors.days_breach
            || errors.days_rescission
        ) {
            setErrorSection(true);
        } else {
            setErrorSection(false);
        }
    }, [
        errors.agreement_type,
        errors.currency,
        errors.income_types_id,
        errors.days_to_pay,
        errors.days_breach,
        errors.days_rescission
    ]);

    useEffect(() => {
        if ((movesAg * 1000) > 0) {
            setAgAc(movesAg * 1000);
        }
        setValue('moves_amount', movesAg);
    }, [movesAg]);

    const loadPaymentMethods = async () => {
        try {
            const { data } = await getAllIncomeTypes(token);
            if (data?.success) {
                setIncomeTypes(data?.data);
                setValue('payment_type_desc', data?.data[3]['name']);
            }
        } catch (error) {
            validErrors(error);
        }
    }

    const handleChangePayType = (pay) => {
        setValue('payment_type_desc', incomeTypes[(pay - 1)]['name']);
    }

    const handleChangeGift = (e) => {
        if (e) {
            if (parseInt(e) <= Math.floor(movesAg / 2)) {
                setGiftMoves(parseInt(e));
            } else {
                setGiftMoves(Math.floor(movesAg / 2));
            }
        } else {
            setGiftMoves(null);
        }
    }

    return (
        <Accordion expanded={panel == 'Agreement'} onChange={handleExpand('Agreement')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
            >
                <Typography color={errorSection ? 'error' : 'default'}>Datos de contrato</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <Grid container spacing={2}>
                    {/* AGREEMENT TYPE */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Tipo Contrato'}
                            placeholder={'Tipo Contrato'}
                            defaultValue={'Contrato de Venta de Productos Digitales'}
                            {...register('agreement_type', { required: "Este campo es obligatorio" })}
                            error={errors.agreement_type}
                        />
                    </Grid>
                    {/* AGREEMENT AMOUNT */}
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel id="currency">Moneda</InputLabel>
                            <Select
                                readOnly
                                defaultValue={'COP'}
                                size="small"
                                labelId="currency"
                                id="agreement_currency"
                                label="Moneda"
                                {...register('currency', { required: "Este campo es obligatorio" })}
                                error={errors.currency}
                            >
                                <MenuItem key={1} value={'COP'}>COP</MenuItem>
                                <MenuItem key={2} value={'USD'}>USD</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            fullWidth
                            required
                            type="number"
                            size="small"
                            label={moneyFormat(parseInt(agAc || 0))}
                            placeholder="Valor Contrato"
                            value={agAc}
                            onChange={(e) => setAgAc(parseInt(e.target.value))}
                            inputProps={{
                                min: 1000
                            }}
                        // {...register('gift_moves')}
                        />
                    </Grid>
                    {/* AGREEMENT MOVES */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            type="number"
                            label="Jugadas"
                            placeholder="Jugadas"
                            value={movesAg}
                            onChange={(e) => setMovesAg(parseInt(e.target.value))}
                            inputProps={{
                                min: 1
                            }}
                        // {...register('moves_amount')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider><Typography color={'#CCC'}>Jugadas de regalo</Typography></Divider>
                    </Grid>

                    {/* GIFT MOVES */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            type={'number'}
                            label={'Regalo'}
                            placeholder={'Regalo'}
                            {...register('gift_moves')}
                            value={giftMoves}
                            onChange={(e) => { handleChangeGift(e.target.value) }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider><Typography color={'#CCC'}>Condiciones</Typography></Divider>
                    </Grid>
                    {/* PAYMENT TYPE */}
                    <Grid item xs={7}>
                        <FormControl fullWidth>
                            <InputLabel id="payment_method">Forma de pago</InputLabel>
                            <Select
                                defaultValue={3}
                                size="small"
                                labelId="payment_method"
                                id="payment_method_id"
                                label="Forma de pago"
                                {...register('income_types_id', { required: "Este campo es obligatorio" })}
                                error={errors.income_types_id}
                                onChange={(e) => {handleChangePayType(e.target.value)}}
                            >
                                {incomeTypes?.map((item) => (
                                    <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* PAYMENT DAYS */}
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            type={'number'}
                            label={'Dias para pago'}
                            placeholder={'Dias para pago'}
                            defaultValue={1}
                            {...register('days_to_pay', { required: "Este campo es obligatorio" })}
                            error={errors.days_to_pay}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider><Typography color={'#CCC'}>Resolución</Typography></Divider>
                    </Grid>

                    {/* AGREEMENT MOVES */}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            type={'number'}
                            label={'Dias Incumplimiento'}
                            placeholder={'Dias Incumplimiento'}
                            defaultValue={1}
                            {...register('days_breach', { required: "Este campo es obligatorio" })}
                            error={errors.days_breach}
                        />
                    </Grid>

                    {/* AGREEMENT MOVES */}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            type={'number'}
                            label={'Dias Rescision'}
                            placeholder={'Dias Rescision'}
                            defaultValue={1}
                            {...register('days_rescission', { required: "Este campo es obligatorio" })}
                            error={errors.days_rescission}
                        />
                    </Grid>

                </Grid>

            </AccordionDetails>
        </Accordion>
    )
}

export default AgreementConfig