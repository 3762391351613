import { LoadingButton } from '@mui/lab'
import { Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Grid, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { Notify } from 'notiflix';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { addIncomeCollection } from '../../../data/api/incomes/IncomeRoutes';
import useIndex from '../../../hooks/useIndex';
import validErrors from '../../../hooks/validErrors';

const nowDate = new Date(Date.now());
const minDate = `${nowDate.getFullYear()}-${nowDate.getMonth() + 1 > 9 ? nowDate.getMonth() + 1 : '0' + (nowDate.getMonth() + 1)}-${nowDate.getDate()}`;
const prefijoDesc = 'El prefijo es un texto o siglas cortas que se colocaran al inicio del código para identificar fácilmente quién creo el código, NO es obligatorio.';
const expiresAtDesc = 'Habilitar una fecha de expiración de los códigos, si no se selecciona su fecha de expiración será en el año 2099.';
const publicCodesDesc = 'Los códigos públicos son aquellos que podrán ser usados en los juegos que se encuentran fuera de la página de usuarios registrados, recuerda que esta opción depende también de la opción "Usuarios registrados".';
const privateCodesDesc = 'Los códigos privados son aquellos que podrán ser usados solo en los juegos que se encuentran dentro de la página de usuarios registrados, recuerda que esta opción solo es válida para usuarios registrados.';
const userRegisterDesc = 'Los códigos solo serán usados por usuarios que estén registrados en el sistema, esto facilitara el análisis de las jugadas realizadas.';
const movesByCodeDesc = 'Cantidad de jugadas por codigo creado, si es mayor a 1 solo se podran usar para usarios que esten registrados.';

const GameCodesForm = ({ loadData }) => {

    const { auth, token, validateUser } = useIndex();
    const { register, handleSubmit, reset } = useForm();
    const [submit, setSubmit] = useState(false);
    const [expiresDate, setExpiresDate] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [prefixCode, setPrefixCode] = useState('');
    const [movesByCode, setMovesByCode] = useState(0);

    const onSubmit = async (dataForm) => {
        setSubmit(true);
        if (dataForm['code_amount'] > 1) {
            dataForm['public_code'] = false;
        }
        try {
            const { data } = await addIncomeCollection(token, dataForm);
            if (data.success) {
                reset()
                setPrefixCode('');
                loadData()
            } else {
                Notify.failure(data.message);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
            validateUser();
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {auth?.role_id != 1 && (
                <Divider sx={{ marginBottom: '1rem' }}><Typography color={'#CCC'}>Tienes {auth?.moves} jugadas para crear</Typography></Divider>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormHelperText>{'Nombre de colección'}</FormHelperText>
                    <TextField
                        placeholder={'Nombre de colección'}
                        size={'small'}
                        fullWidth
                        {...register('name')}
                        disabled={submit}
                        inputProps={{
                            maxLength: 50
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormHelperText>{'Cantidad de codigos'}</FormHelperText>
                    <TextField
                        placeholder={'Cantidad de codigos min. 1'}
                        size={'small'}
                        type={'number'}
                        fullWidth
                        {...register('code_count')}
                        disabled={submit}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormHelperText>{'Jugadas por codigo '}<Tooltip title={movesByCodeDesc} enterDelay={500} arrow><i className="fa-regular fa-circle-question"></i></Tooltip></FormHelperText>
                    <TextField
                        placeholder={'Jugadas por codigo min. 1'}
                        size={'small'}
                        type={'number'}
                        fullWidth
                        {...register('code_amount')}
                        disabled={submit}
                        onChange={(e) => setMovesByCode(parseInt(e.target.value))}
                    />
                </Grid>

                <Grid item xs={12}><Divider><Typography color={'#CCC'}>Opciones</Typography></Divider></Grid>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                size={'small'}
                                type={'date'}
                                fullWidth
                                InputProps={{ inputProps: { min: minDate } }}
                                {...register('start_at')}
                                disabled={submit}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <FormHelperText>{'Fecha de Inicio'}</FormHelperText>
                        </Grid>

                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            defaultChecked={expiresDate}
                                            onChange={() => setExpiresDate(!expiresDate)}
                                            disabled={submit}
                                        />
                                    }
                                    label={
                                        <Typography><Tooltip title={expiresAtDesc} enterDelay={500} arrow><i className="fa-regular fa-circle-question"></i></Tooltip> Expiración de códigos</Typography>
                                    }
                                    labelPlacement="start" />
                            </FormGroup>

                            <TextField
                                disabled={!expiresDate}
                                size={'small'}
                                type={'date'}
                                fullWidth
                                InputProps={{ inputProps: { min: startDate ?? minDate } }}
                                {...register('expires_at')}
                            />
                            <FormHelperText>{'Fecha de Fin'}</FormHelperText>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={'Prefijo de codigo'}
                                placeholder={'Ejm: NAME'}
                                size={'small'}
                                fullWidth
                                {...register('prefix')}
                                disabled={submit}
                                value={prefixCode}
                                onChange={(e) => setPrefixCode(e.target.value.toUpperCase())}
                                inputProps={{
                                    maxLength: 10
                                }}
                            />
                            <FormHelperText>
                                {`Ejemplo de codigo: ${prefixCode && prefixCode.toUpperCase() + '_'}1A2B3C`} <Tooltip title={prefijoDesc} enterDelay={600} arrow><i className="fa-regular fa-circle-question"></i></Tooltip>
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked disabled={movesByCode > 1 || submit} {...register('public_code')} />}
                                    label={
                                        <Typography>{movesByCode > 1 ? (<del>Jugadores sin registrar</del>) : 'Jugadores sin registrar'}  <Tooltip title={publicCodesDesc} enterDelay={500} arrow><i className="fa-regular fa-circle-question"></i></Tooltip></Typography>
                                    }
                                />
                                <FormControlLabel
                                    control={<Checkbox defaultChecked {...register('private_code')} disabled={submit} />}
                                    label={
                                        <Typography>Jugadores Registrados <Tooltip title={privateCodesDesc} enterDelay={500} arrow><i className="fa-regular fa-circle-question"></i></Tooltip></Typography>
                                    }
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch disabled defaultChecked={false} {...register('user_register')} />}
                                    label={
                                        <Typography>Usuarios registrados <Tooltip title={userRegisterDesc} enterDelay={500} arrow><i className="fa-regular fa-circle-question"></i></Tooltip></Typography>
                                    }
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <LoadingButton
                        fullWidth
                        loading={submit}
                        loadingPosition="start"
                        startIcon={<i className="fa-solid fa-floppy-disk"></i>}
                        variant="contained"
                        size="small"
                        type='submit'
                    >
                        <span>Save</span>
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}

export default GameCodesForm