import { Stack, Chip, Card, Container } from '@mui/material';
import React from 'react';
import History from '../../history/History';

const UserHistory = ({
    user
}) => {
    return (
        <>
            <Container sx={{ padding: '0 1rem' }}>
                <Card sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '.5rem 0',
                    flexWrap: 'wrap'
                }}>
                    <Stack direction="row" spacing={1}>
                        <Chip label={`Realizadas: ${user?.games_count}`} color="info" />
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Chip label={`Pendientes: ${user?.moves}`} color="warning" />
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Chip label={`Ganadoras: ${user?.games_win_count}`} color="secondary" />
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Chip label={`Total: ${user?.codes_total}`} color="primary" />
                    </Stack>
                </Card>
            </Container>

            <History user_search={user?.id} />
        </>
    )
}

export default UserHistory