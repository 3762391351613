import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const UnauthorizedPage = () => {
    return (
        <>
            <ContainerError>
                <img src='https://i.imgur.com/qIufhof.png' />

                <h1>
                    <span>401</span> <br />
                    Unauthorized
                </h1>

                <p>No tienes permiso para ver este contenido.</p>
                <p className='info'>
                    Regresa a la pagina principal <Link to={'/ganacol/dashboard/'}>Ganacol</Link>
                </p>
            </ContainerError>
        </>
    )
}

export default UnauthorizedPage



const ContainerError = styled.div`
    text-align: center;
    margin: auto;
    padding: 4em;
    font-family: "Fira Code";
    img {
        width: 256px;
        height: 225px;
    }
    h1 {
        margin-top: 1rem;
        font-size: 35px;
        text-align: center;
        span {
            font-size: 60px;
        }
    }
    p {
        margin-top: 1rem;
    }
    p.info {
        margin-top: 4em;
        font-size: 12px;
        a {
        text-decoration: none;
        color: rgb(84, 84, 206);
        }
    }
`
