import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Tab,
    TextField,
    Typography
} from '@mui/material';
import { Confirm, Notify, Report } from 'notiflix';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Loader from '../../../components/loader/Loader';
import { getAllDocumentType } from '../../../data/api/document/DocumentRoutes';
import { updateUser, getAllUsersAvatars } from '../../../data/api/users/UserRoutes';
import useIndex from '../../../hooks/useIndex';
import userAvatar from '../../../assets/img/User-Avatar.png';
import styled from 'styled-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import validErrors from '../../../hooks/validErrors';

const Configuration = () => {
    const serverUrl = process.env.REACT_APP_BACK_URL;

    const { auth, token, validateUser } = useIndex();
    const { register, handleSubmit, setValue, watch } = useForm();
    const [submit, setSubmit] = useState(false);
    const [documentType, setDocumentType] = useState(null);
    const [avatarsData, setAvatarsData] = useState([]);
    const [userImage, setUserImage] = useState(auth?.avatar ?? userAvatar);
    const [load, setLoad] = useState(true);


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [valueTab, setValueTab] = useState('Anonimo');
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    }

    useEffect(() => {
        loadData();
        loadAvatars();
    }, []);

    useEffect(() => {
        setUserImage(serverUrl + auth?.avatar);
    }, [auth]);

    const loadAvatars = async () => {
        setLoad(true);
        try {
            const { data } = await getAllUsersAvatars();
            if (data?.success) {
                setAvatarsData(data.data);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setLoad(false);
        }
    }

    const loadData = async () => {
        try {
            const { data } = await getAllDocumentType();
            setDocumentType(data.data);
        } catch (error) {
            validErrors(error);
        }
    }

    const handleUserAvatar = (picture) => {
        setValue('avatar', picture['value']);
        setUserImage(serverUrl + picture['path']);
    }

    const disabledAcount = () => {
        Confirm.prompt(
            'Desactivar cuenta',
            '¿Seguro deseas desactivar tu cuenta?. Al hacerlo no perderas tus registros de juego, pero restringes el uso del juego desde tu cuenta y las jugadas que aun no hayas usado quedaran desactivadas, tambien evitaras recibir regalos, promociones e información que Fenabocol quiera brindarte. En caso de cambiar de opinion deberas ponerte en contacto con el administrador para activarla nuevamente. <br/><br/>Para desactivar tu cuenta ingresa tu contraseña actual.',
            '',
            'Confirmar',
            'Cancelar',
            (clientAnswer) => {disabledAcountConfirm(clientAnswer)},
            () => {},
            {
                messageMaxLength: 1923,
                plainText: false,
                width: '400px'
            }
        );
    }

    const disabledAcountConfirm = async (password) => {
    }

    const onSubmit = async (dataForm) => {
        setSubmit(true);
        try {
            const { data } = await updateUser(token, auth.id, dataForm);
            if (data.success) {
                validateUser();
                setValue('password', '');
                setValue('password_confirmation', '');
                setShowPassword(false);

                Report.success('Actualización exitosa', 'El usuario ha sido actualizado correctamente.', 'Ok');
            } else {
                Notify.failure(data.message);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
        }
    }

    return (
        <>
            {auth?.id ?
                <Container sx={{ paddingTop: '3rem', background: '#FFF', minHeight: '100%', borderLeft: '2px solid #CCC', borderRight: '2px solid #CCC' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>

                            {/* USER AVATAR */}
                            <Grid item xs={12} md={12} lg={4}>
                                {/* LATERAL USER */}
                                <UserImageSelect>
                                    {/* USER IMAGE */}
                                    <Box className="imgUstLat">
                                        <img src={userImage} alt="user-avatar" />
                                    </Box>
                                    {/* USER DESCRIPTION */}
                                    <Box className="descrUser">
                                        <Typography variant='h5'>{auth?.role ?? 'User'}</Typography>
                                        <Typography component={'p'} textAlign={'center'}>{auth?.name ?? 'User Name'}</Typography>
                                    </Box>
                                </UserImageSelect>

                                <Divider sx={{ margin: '1rem 0' }}><Typography color={'#CCC'}>Cambiar de Avatar</Typography></Divider>

                                {load ? (
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <TabContext value={valueTab}>
                                        {/* OPTIONS */}
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="set_avatar" centered variant="scrollable" scrollButtons="auto">
                                                {Object.keys(avatarsData).map((item) => (
                                                    <Tab label={item} value={item} />
                                                ))}
                                            </TabList>
                                        </Box>

                                        {/* PANELS */}
                                        {Object.keys(avatarsData).map((item) => (
                                            <TabPanel value={item}>
                                                <Grid container spacing={2}>
                                                    {avatarsData[item].map((picture) => (
                                                        <Grid item xs={3} md={2} lg={3}>
                                                            <ImageSelect onClick={() => handleUserAvatar(picture)}>
                                                                <img src={serverUrl + picture['path']} alt="user-avatar" className="imgUsrLatInt" />
                                                            </ImageSelect>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </TabPanel>
                                        ))}
                                    </TabContext>
                                )}
                                <Typography fontFamily={'arial'} variant='caption' sx={{ display: 'inline-flex' }}>Designed by |<a target={'_blank'} href='https://www.freepik.com/'>Freepik</a></Typography>
                            </Grid>

                            {/* USER INFORMATION */}
                            <Grid item xs={12} md={12} lg={8}>
                                {/* RESUME */}
                                <Grid container spacing={0}>
                                    <Card sx={{ width: '100%' }}>
                                        <CardContent>
                                            <Grid item xs={12}>
                                                <Typography variant='h5' textAlign={'center'}>Datos de usuario</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' component={'span'}>Nombre:</Typography> <Typography variant='subtitle2' component={'span'}>{auth?.name} {auth?.lastname}</Typography>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' component={'span'}>Correo:</Typography> <Typography variant='subtitle2' component={'span'}>{auth?.email}</Typography>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' component={'span'}>Telefono:</Typography> <Typography variant='subtitle2' component={'span'}>{auth?.phone}</Typography>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' component={'span'}>{auth?.document_type_name}:</Typography> <Typography variant='subtitle2' component={'span'}>{auth?.document}</Typography>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' component={'span'}>Jugadas:</Typography> <Typography variant='subtitle2' component={'span'}>{auth?.moves}</Typography>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' component={'span'}>Rol:</Typography> <Typography variant='subtitle2' component={'span'}>{auth?.role}</Typography>
                                                <Divider></Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' component={'span'}>Estado:</Typography> <Typography variant='subtitle2' component={'span'}>{auth?.status_name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button size='small' onClick={disabledAcount}>Desactivar cuenta</Button>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Divider sx={{ margin: '1rem 0' }}><Typography color={'#CCC'}>Modificar Información</Typography></Divider>

                                {/* FORM */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            size="small"
                                            defaultValue={auth?.name}
                                            {...register('name')}
                                            label={'Nombre'}
                                            placeholder={'Nombre'}
                                            disabled={submit}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            defaultValue={auth?.lastname}
                                            {...register('lastname')}
                                            label={'Apellido'}
                                            placeholder={'Apellido'}
                                            disabled={submit}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            defaultValue={auth?.email}
                                            {...register('email')}
                                            label={'Correo'}
                                            placeholder={'Correo'}
                                            disabled={submit}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            defaultValue={auth?.phone}
                                            {...register('phone')}
                                            label={'Telefono'}
                                            placeholder={'Telefono'}
                                            disabled={submit}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    defaultValue={auth?.document}
                                                    {...register('document')}
                                                    label={'Documento'}
                                                    placeholder={'Documento'}
                                                    disabled={submit}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="label_doc">Doc.</InputLabel>
                                                    <Select
                                                        defaultValue={auth?.document_type_id}
                                                        size="small"
                                                        {...register('document_type_id')}
                                                        labelId="label_doc"
                                                        id="document_type_id"
                                                        label="Doc"
                                                        disabled={submit}
                                                    >
                                                        {documentType && documentType.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>{`${item.short_name} - ${item.name}`}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <InputLabel htmlFor="password">Contraseña</InputLabel>

                                            <OutlinedInput
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Contraseña"
                                                label="Contraseña"
                                                {...register('password')}
                                                disabled={submit}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <InputLabel htmlFor="password_confirmation">Confirmar Contraseña</InputLabel>

                                            <OutlinedInput
                                                id="password_confirmation"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Confirmar Contraseña"
                                                label="Confirmar Contraseña"
                                                {...register('password_confirmation')}
                                                disabled={submit}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="tog-pass-conf"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} marginBottom={'3rem'}>
                                        <LoadingButton
                                            fullWidth
                                            loading={submit}
                                            loadingPosition="start"
                                            startIcon={<i className="fa-solid fa-floppy-disk"></i>}
                                            variant="contained"
                                            size="large"
                                            type='submit'
                                        >
                                            <span>Guardar</span>
                                        </LoadingButton>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Container>
                : (<Loader />)}

        </>
    )
}
const UserImageSelect = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .imgUstLat {
        width: 55%;
        aspect-ratio: 1/1;
        border: 2px solid #AAA;
        border-radius: 50%;
        background: #1c1c1c;
        overflow: hidden;
        padding: 0;
        img {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;
const ImageSelect = styled.div`
    width: 85%;
    aspect-ratio: 1/1;
    border: 1px solid #AAA;
    border-radius: 50%;
    background: #1c1c1c;
    overflow: hidden;
    padding: 0;
    transition: width .3s ease;
    img {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:hover {
        width: 100%;
        cursor: pointer;
    }
`;

export default Configuration