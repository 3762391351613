import clientAxios from "../config";

const registerUser = async (data) => clientAxios.post('/api/register', data);
const loginUser = async (data) => clientAxios.post('/api/login', data);
const logoutUser = async (token) => clientAxios.post('/api/logout', null, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const getUser = async (token) => clientAxios('/api/user', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const forgotPassword = async (data) => clientAxios.post('/api/forgot-password', data);
const validTokenResetPassword = async (token, userId) => clientAxios(`/api/valid-token/${token}/${userId}`);
const changePassword = async (userId, data) => clientAxios.post(`/api/change-password/${userId}`, data);

export {
    registerUser,
    loginUser,
    logoutUser,
    getUser,
    forgotPassword,
    validTokenResetPassword,
    changePassword,
}