import React from 'react'
import TextEditor from '../agreements/textEditor/TextEditor';

const Test = () => {
    return (
        <>
            <TextEditor />
        </>
    )
}

export default Test