import { Button, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getAllPrizes } from '../../../data/api/prizes/PrizeRoutes';
import useIndex from '../../../hooks/useIndex';
import validErrors from '../../../hooks/validErrors'
import Prize from './Prize';
import RowInfo from './prize/RowInfo';

const System = () => {

    const { auth, token } = useIndex();
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        
    }

    return (
        <Container sx={{ paddingTop: '1rem' }}>
            <Grid container spacing={2}>
                {/* PRIZE TABLE */}
                <Grid item xs={12}>
                    <Prize />
                </Grid>
                <Grid item xs={12}><Typography>Roles</Typography></Grid>
                <Grid item xs={12}><Typography>Permisos</Typography></Grid>
                <Grid item xs={12}><Typography>Game type</Typography></Grid>
                <Grid item xs={12}><Typography>Desactivar pagina</Typography></Grid>
                <Grid item xs={12}><Typography>Tipo de donaciones</Typography></Grid>
            </Grid>
        </Container>
    )
}

export default System