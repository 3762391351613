import { Accordion, AccordionDetails, AccordionSummary, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { getAllDocumentType } from '../../../../../data/api/document/DocumentRoutes';
import validErrors from '../../../../../hooks/validErrors';

const CompanyConfig = ({
    user,
    register,
    panel,
    handleExpand,
    errors
}) => {

    const [documentType, setDocumentType] = useState(null);
    const [errorSection, setErrorSection] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (
            errors.company_name
            || errors.company_email
            || errors.company_phone
            || errors.company_address
            || errors.document_type_id
            || errors.company_document
            || errors.signatory_name
            || errors.signatory_position
        ) {
            setErrorSection(true);
        } else {
            setErrorSection(false);
        }
    }, [
        errors.company_name,
        errors.company_email,
        errors.company_phone,
        errors.company_address,
        errors.document_type_id,
        errors.company_document,
        errors.signatory_name,
        errors.signatory_position,
    ]);

    const loadData = async () => {
        try {
            const { data } = await getAllDocumentType();
            setDocumentType(data.data);
        } catch (error) {
            validErrors(error);
        }
    }

    return (
        <Accordion expanded={panel == 'Company'} onChange={handleExpand('Company')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <Typography color={errorSection ? 'error' : 'default'}>Datos de Comprador</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {/* COMPANY NAME */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Nombre Comprador'}
                            placeholder={'Nombre Comprador'}
                            defaultValue={user?.name}
                            {...register('company_name', { required: "Este campo es obligatorio" })}
                            error={errors.company_name}
                        />
                    </Grid>

                    {/* COMPANY EMAIL */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Correo Comprador'}
                            placeholder={'Correo Comprador'}
                            defaultValue={user?.email}
                            {...register('company_email', { required: "Este campo es obligatorio" })}
                            error={errors.company_email}
                        />
                    </Grid>

                    {/* COMPANY PHONE */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Telefono Comprador'}
                            placeholder={'Telefono Comprador'}
                            defaultValue={user?.phone}
                            {...register('company_phone', { required: "Este campo es obligatorio" })}
                            error={errors.company_phone}
                        />
                    </Grid>

                    {/* COMPANY ADDRESS */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Direccion Comprador'}
                            placeholder={'Direccion Comprador'}
                            {...register('company_address', { required: "Este campo es obligatorio" })}
                            error={errors.company_address}
                        />
                    </Grid>

                    {/* COMPANY TYPE ID */}
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel id="label_doc">Doc.</InputLabel>
                            <Select
                                size="small"
                                labelId="label_doc"
                                id="document_type_id"
                                label="Doc"
                                defaultValue={user?.document_type_id}
                                {...register('document_type_id', { required: "Este campo es obligatorio" })}
                                error={errors.document_type_id}
                            >
                                {documentType && documentType.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{`${item.short_name} - ${item.name}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Documento Comprador'}
                            placeholder={'Documento Comprador'}
                            defaultValue={user?.document}
                            {...register('company_document', { required: "Este campo es obligatorio" })}
                            error={errors.company_document}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider><Typography color={'#CCC'}>Representante legal</Typography></Divider>
                    </Grid>

                    {/* COMPANY SIGNATORY */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Representante'}
                            placeholder={'Representante'}
                            {...register('signatory_name', { required: "Este campo es obligatorio" })}
                            error={errors.signatory_name}
                        />
                    </Grid>
                    {/* COMPANY SIGNATORY POSITION */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Posición'}
                            placeholder={'Posición'}
                            {...register('signatory_position', { required: "Este campo es obligatorio" })}
                            error={errors.signatory_position}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default CompanyConfig