import { Box, Menu, MenuItem, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'

const DocumentContainer = ({ documents, edit = false, actionDelete }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [fileIcon, setFileIcon] = useState({
        'pdf': 'fa-solid fa-file-pdf',
        'xls': 'fa-solid fa-file-excel',
        'xlsx': 'fa-solid fa-file-excel',
        'doc': 'fa-solid fa-file-word',
        'docx': 'fa-solid fa-file-word',
        'pptx': 'fa-solid fa-file-powerpoint',
        'pptm': 'fa-solid fa-file-powerpoint',
        'ppt': 'fa-solid fa-file-powerpoint',
    });

    return (
        <DocumentsContent>
            {documents?.map((item) => (
                <Tooltip key={item?.uuid} title={item?.name} placement="bottom" arrow>

                    <DocumentCard>
                        {edit && (
                            <Box>
                                <i className="options fas fa-ellipsis-v" onClick={handleClick}></i>
                                <Menu
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={() => {handleClose(); actionDelete(item?.id)}}>Eliminar</MenuItem>
                                </Menu>
                            </Box>
                        )}

                        <a href={`${process.env.REACT_APP_BACK_URL}/${item?.path}`} target='_blank'>
                            <i className={fileIcon[item?.type] ?? "fa-solid fa-file-lines"}></i>
                            <span>{item?.name}</span>
                        </a>
                    </DocumentCard>
                </Tooltip>
            ))}
        </DocumentsContent>
    )
}

export default DocumentContainer

const DocumentsContent = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`
const DocumentCard = styled.div`
    width: 150px;
    border: 1.5px dashed #CCC;
    border-radius: 7px;
    position: relative;
    margin: .25rem;
        
    i.options {
        position: absolute;
        top: -0.5rem;
        right: -1rem;
        cursor: pointer;
        margin-right: 1rem;
        margin-top: .75rem;
        color: #CCC;
        font-size: 1rem;
        border-radius: 50%;
        padding: .5rem .9rem;
        transition: all .3s;
        z-index: 1000;
        &:hover {
            background: rgba(0,0,0,.1);
            color: #AAA;
        }
    }

    a {
        padding: 15px 5px;
        text-align: center;
        color: #AAA;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        text-decoration: none;

        span {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: underline;
        }
        i {
            font-size: 5rem;
            color: #CCC;
        }
    }
`