import { Typography } from '@mui/material'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Balota = ({move, isModal = false, classHit}) => {
    return (
        <BalotaContent className={classHit}>
            <Typography className={isModal && `modalp`} component={'p'}>{move}</Typography>
        </BalotaContent>
    )
}

export default Balota
const rotate = keyframes`
    0%{transform: rotate(0deg);}
    25%{transform: rotate(15deg);}
    50%{transform: rotate(0deg);}
    75%{transform: rotate(-15deg);}
    100%{transform: rotate(0deg);}
`
const BalotaContent = styled.div`
    background: #FFF;
    aspect-ratio: 1/1;
    border: 1px solid #262a34;
    border-radius: 50%;
    box-shadow: inset -5px -5px 10px rgba(38, 42, 52, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    p {
        font-size: calc(3.5vw);
        font-weight: 600;
        font-family: 'Roboto Mono', monospace;
        &.modalp {
            font-size: clamp(16px, 2.5vw, 22px);
        }
    }
    &.hit {
        background: radial-gradient(circle, rgba(255,255,255,1) 30%, rgba(244,208,63,1) 90%, rgba(244,208,63,1) 100%);
        /* background: radial-gradient(circle, rgba(255,255,255,1) 30%, rgba(166,255,0,1) 90%, rgba(46,255,0,1) 100%); */
        animation: ${rotate} 1.5s linear infinite;
    }
    @media (max-width: 1080px) {
        p {
            font-size: calc(5vw);
            &.modalp {
                font-size: clamp(16px, 2.5vw, 22px);
            }
        }
    }
`