import { Box } from '@mui/material';
import React from 'react'
import styled from 'styled-components'
import PlayButton from '../../../components/button/PlayButton';
import setKey from '../../../hooks/setKey'

const InputGame = ({
    ballot,
    setBallot,
    ValidBall,
    disabled
}) => {

    const ValidBox = (item, valu) => {
        const { val, maxLen } = ValidValue(valu);
        const newballs = ballot.map((items) => {
            const newBall = {
                id: items.id,
                value: val,
                maxLength: maxLen
            }
            return items.id === item.id ? newBall : items;
        });
        setBallot(newballs);
    }

    const ValidValue = (val) => {
        val = val.toUpperCase();

        let regex = new RegExp("[BINGO0-9]");
        if (!regex.test(val)) { val = "" }

        let maxLen = 2;
        if (val.length === 1) {
            regex = new RegExp("[BINGO]");
            maxLen = regex.test(val) ? 1 : 2;
            val == 0 ? val = 1 : val = val;
        } else if (val.length === 2) {
            regex = new RegExp(/\d\d/, "i");
            if (!regex.test(val)) {
                val = String(val).substr(0, 1);
            }
            val = val < 1 ? 1 : val > 50 ? 50 : val;
        }
        return { val, maxLen };
    }

    const RandomGame = () => {
        let options = ['B', 'I', 'N', 'G', 'O'];
        for (let i = 1; i <= 50; i++) { options.push(i) }
        let newVals = [];
        do {
            newVals = ballot.map(() => { return options[Math.floor(Math.random() * options.length)] });
        } while (!ValidBall(newVals));
        AsignBalls(newVals);
    }

    const AsignBalls = (arr) => {
        const newArr = arr.map((item) => {
            const { val, maxLen } = ValidValue(String(item));
            return {
                id: setKey(),
                value: val,
                maxLength: maxLen
            }
        });
        setBallot(newArr);
    }

    return (
        <ContentInputs className='animate__animated animate__backInDown'>
            <InputContainer>
                {/* BOXES */}
                {ballot.map((item) => (
                    <InputGameBalota
                        key={item.id}
                        value={item.value}
                        maxLength={item.maxLength}
                        placeholder={'-'}
                        onChange={(e) => ValidBox(item, e.target.value)}
                    />
                ))}
                {/* RANDOM GAME */}
                <Box>
                    <PlayButton
                        content={<i className="fa-solid fa-rotate"></i>}
                        action={() => RandomGame()}
                        disabled={disabled}
                    />
                </Box>
            </InputContainer>

        </ContentInputs>
    )
}

export default InputGame

const ContentInputs = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
        flex-direction: column;
        align-items: center;
    }
`
const InputContainer = styled.div`
    width: 100%;
    max-width: 490px;
    padding: 5px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr) ;
    grid-gap: 3px;
    overflow: hidden;
    background: radial-gradient(circle, rgba(214,34,34,1) 0%, rgba(171,0,0,1) 100%);
`
const InputGameBalota = styled.input`
    width: 100%;
    aspect-ratio: 1/1;
    text-align: center;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: calc(2.5vw);
    line-height: calc(2.5vw);
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Roboto Mono', monospace;
    border: none;
    border-radius: 8px;
    @media (max-width: 1080px) {
        font-size: calc(5vw);
        line-height: calc(5vw);
    }
    @media (max-width: 576px) {
        font-size: calc(7vw);
        line-height: calc(7vw);
    }
`