import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moneyFormat from '../../../hooks/moneyFormat';
import validErrors from '../../../hooks/validErrors';
import { getAllPrizes } from '../../../data/api/prizes/PrizeRoutes';
import useIndex from '../../../hooks/useIndex';

const AwardsTable = () => {

    const { token } = useIndex();
    const [prizes, setPrizes] = useState([]);

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        try {
            const { data } = await getAllPrizes(token);
            if (data?.success) {
                setPrizes(data?.data);
            }
        } catch (error) {
            validErrors(error);
        }
    }

    return (
        <GanacolAwards>
            <thead>
                <tr>
                    <td><Typography variant='h6'><b>Aciertos</b></Typography></td>
                    <td><Typography variant='h6'><b>Premio</b></Typography></td>
                </tr>
            </thead>
            <tbody>
                {prizes?.map((item) => (
                    <tr>
                        <td>{item?.hits}</td>
                        <td>{item?.compensation ? moneyFormat(item?.prize) : item?.prize} {item?.compensation ? 'COP' : 'Jugadas'}</td>
                    </tr>
                ))}
            </tbody>
        </GanacolAwards>
    )
}

const GanacolAwards = styled.table`
    width: 100%;
    max-width: 500px;
    background: #FFF;
    border: 4px solid #d62222;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-radius: 10px;
    thead {
        text-align: center;
        tr td {
            border: 1px solid #BBB;
            border-bottom: 2px solid #d62222;
            &:nth-child(1) {
                border-right: 2px solid #d62222;
            }
        }
    }
    tbody {
        text-align: center;
        tr td {
            box-sizing: border-box;
            border: 1px solid #DDDDDD;
            &:nth-child(1) {
                border-right: 2px solid #d62222;
            }
        }
    }
`;

export default AwardsTable