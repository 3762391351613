import { Loading, Notify, Report } from 'notiflix';
import { useNavigate } from 'react-router-dom';
import { getUser, loginUser, logoutUser, registerUser } from '../data/api/auth/AuthRoutes';
import useIndex from './useIndex';
import validErrors from './validErrors';

const useAuth = () => {
    const navigate = useNavigate();
    const { setAuth, setLoading, token, setToken } = useIndex();

    // Login
    const login = async (data) => {
        setLoading(true);
        Loading.hourglass();
        try {
            const response = await loginUser(data);

            if (data.session) {
                localStorage.setItem('AUTH_TOKEN', response.data.token);
                sessionStorage.removeItem('AUTH_TOKEN');
            } else {
                sessionStorage.setItem('AUTH_TOKEN', response.data.token);
                localStorage.removeItem('AUTH_TOKEN');
            }
            setToken(response.data.token);
            Notify.success('Usuario ingresado correctamente');

            const authRes = await getUserAuth(response.data.token);
            if (authRes) {
                navigate('/ganacol/dashboard');
            }
        } catch (error) {
            validErrors(error);
            setAuth({});
        } finally {
            Loading.remove();
            setLoading(false);
        }
    }

    // Register
    const register = async (data) => {
        setLoading(true);
        Loading.hourglass();
        try {
            const response = await registerUser(data);
            localStorage.setItem('AUTH_TOKEN', response.data.token);
            setToken(response.data.token);
            Report.success('Registro exitoso', 'El usuario ha sido registrado con exito', 'Ok', () => getUserAuth(response.data.token));
            navigate('/ganacol/dashboard');
        } catch (error) {
            validErrors(error);
        } finally {
            Loading.remove();
            setLoading(false);
        }
    }

    // Logout
    const logout = async () => {
        setLoading(true);
        try {
            logoutUser(token);
            localStorage.removeItem('AUTH_TOKEN');
            sessionStorage.removeItem('AUTH_TOKEN');
            setToken(null);
            setAuth(undefined);
        } catch (error) {
            validErrors(error);
        } finally {
            navigate('/ganacol');
            setLoading(false);
        }
    }

    // Get User
    const getUserAuth = async (tokenAuth) => {
        setLoading(true);
        try {
            if (tokenAuth) {
                const { data } = await getUser(tokenAuth);
                if (data) {
                    setAuth(data);
                    setLoading(false);
                }
                return data;
            }
        } catch (error) {
            validErrors(error);
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    return {
        login,
        register,
        logout,
        getUserAuth
    }
}

export default useAuth