import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getAllUserStatistics } from '../../../../data/api/statistics/StatisticsRoute'
import useIndex from '../../../../hooks/useIndex'
import LineGraphic from '../../statistics/LineGraphic'
import BarGraphic from '../../statistics/BarGraphic'
import validErrors from '../../../../hooks/validErrors'

const UserStadistics = ({
    user
}) => {

    const { token } = useIndex();
    const [load, setLoad] = useState(true);
    const [dataGraphic, setDataGraphic] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoad(true);

        try {
            const { data } = await getAllUserStatistics(token, user?.id);
            setDataGraphic(data?.data);
        } catch (error) {
            validErrors(error);
        } finally {
            setLoad(false);
        }
    }

    return (
        <Box width={'100%'}>
            <Typography variant='h5' textAlign={'center'} >Estadisticas</Typography>

            <Grid container spacing={2}>

                {/* JUGADAS SEMANALES */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <CircularProgress color="error" />
                                    </Box>
                                </Box>
                            ) : (
                                <LineGraphic
                                    data={dataGraphic['week-games']}
                                    title={'Jugadas semanales'}
                                    subtitle={'Jugadas'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* JUEGOS GANADOS */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <CircularProgress color="error" />
                                    </Box>
                                </Box>
                            ) : (
                                <BarGraphic
                                    data={dataGraphic['codes-by-result']}
                                    title={'Resultado de jugadas'}
                                    subtitle={'Jugadas'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Box>
    )
}

export default UserStadistics