import clientAxios from "../config";

const getWinnerInfo = async (token, id) => clientAxios(`/api/winner/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const sentWinnerMail = async (token, data) => clientAxios.post(`/api/winner-mail`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const exportWinnerTiket = async (token, uuid) => clientAxios(`/winner-invoice/${uuid}`, {
    headers: {
        Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
});

export {
    getWinnerInfo,
    exportWinnerTiket,
    sentWinnerMail,
}