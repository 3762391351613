import React, { useState} from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components';
import Footer from './Footer';
import NavBar from './NavBar';

const LayoutIndex = () => {
    const [clicked, setClicked] = useState(false);
    const handleClicked = () => {
        setClicked(!clicked);
    }

    return (
        <>
            <NavBarContent>
                <div className={`${clicked && 'active'}`}>
                    <NavBar
                        clicked={clicked}
                        setClicked={setClicked}
                        handleClicked={handleClicked}
                    />
                </div>
            </NavBarContent>
            
            <OutletContainer>
                <Outlet />
            </OutletContainer>

            <FooterContainer>
                <div className={`${clicked && 'active'}`}>
                    <Footer />
                </div>
            </FooterContainer>
        </>
    )
}

export default LayoutIndex

const NavBarContent = styled.header`
    .active {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
`
const OutletContainer = styled.div`
    max-width: 100%;
    overflow: hidden;
    .active {
        padding-top: 100px;
        z-index: 2;
    }
`

const FooterContainer = styled.div`
    .active {
        z-index: 1;
    }
`