import { Box, Button, Card, Grid, Tab, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';
import SellerConfig from './configurations/SellerConfig';
import CompanyConfig from './configurations/CompanyConfig';
import AgreementConfig from './configurations/AgreementConfig';
import validErrors from '../../../../hooks/validErrors';
import { useForm } from 'react-hook-form';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import useIndex from '../../../../hooks/useIndex';
import { makeNewAgreement } from '../../../../data/api/agreements/AgreementRoute';
import styled from 'styled-components';
import { Loading } from 'notiflix';

const modules = {
    clipboard: {
        matchVisual: false, // Desactiva el ajuste visual de saltos de línea
    },
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }], // Encabezados y fuentes
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],           // Listas
        ['bold', 'italic', 'underline', 'strike'],              // Estilos de texto
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }], // Alineación de texto
        [{ 'color': [] }, { 'background': [] }],                // Colores
        ['link', 'image'],                                      // Enlaces e imágenes
        ['clean']                                               // Eliminar formato
    ],
};
const formats = [
    'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike',
    'align', 'color', 'background', 'link', 'image'
];

const TextEditor = ({
    user = [],
    loadData
}) => {
    const { token } = useIndex();

    const [title, setTitle] = useState('CONTRATO DE VENTA Y DISTRIBUCIÓN DE CÓDIGOS PARA PARTICIPACIÓN EN BINGO FENABOCOL');
    const [description, setDescription] = useState(`
        <p><strong>Entre:</strong></p>
        <p><strong>Fenabocol Org</strong>, con NIT 900.021.411-9, con domicilio en Bogota D.C. Calle 43a sur #78g - 28, debidamente representada por Edgar Arturo Fajardo Bernal, en su calidad de [cargo del representante legal], quien para los efectos del presente contrato se denominará <strong>"Vendedor"</strong>, por una parte;</p>
        <p><strong>Y por otra parte:</strong></p>
        <p><strong id="description_company_name">${user?.name || '[Nombre de la Empresa Compradora]'}</strong>, con ${user?.document_type_short || 'NIT'} ${user?.document || '[NIT de la empresa compradora]'}, con domicilio en [dirección de la empresa compradora], debidamente representada por [nombre del representante legal], en su calidad de [cargo del representante legal], quien para los efectos del presente contrato se denominará <strong>"Comprador"</strong>;</p>
        <p><strong>Considerando:</strong></p>
        <ol>
        <li>Que el <strong>Vendedor</strong> es propietaria y operadora del juego en línea <strong>Bingo Fenabocol</strong>, similar a una lotería, donde se comercializan códigos que otorgan la posibilidad de participar en jugadas de este juego.</li>
        <li>Que el <strong>Comprador</strong> está interesada en adquirir códigos para su distribución y uso dentro de la plataforma del juego mencionado, de acuerdo a las cláusulas que se detallan a continuación.</li>
        </ol>
        <p><strong>ACUERDAN</strong> celebrar el presente <strong>Contrato de Venta y Distribución de Códigos para Participación en Bingo Fenabocol</strong>, bajo las siguientes cláusulas:</p>
  `);
    const [termsConditions, setTermsConditions] = useState(`
        <h3>Cláusula Primera: Objeto del Contrato</h3>
        <p>El presente contrato tiene por objeto la venta de códigos por parte de <strong>El Vendedor</strong> a <strong>El Comprador</strong>, para que esta última pueda distribuir dichos códigos entre sus empleados, clientes, o terceros, para participar en el juego en línea <strong>Bingo Fenabocol</strong>, operado por <strong>Fenabocol Org</strong>.</p>
        <h3>Cláusula Segunda: Precio y Forma de Pago</h3><ol><li><strong>Precio por Código</strong>: Cada código tiene un valor unitario de <strong>1.000 COP (Mil Pesos Colombianos)</strong>.</li><li><strong>Valor Total del Contrato</strong>: El valor total de este contrato es de <strong>[valor en COP]</strong>, que corresponde a la venta de <strong>[número de códigos]</strong> códigos.</li><li><strong>Forma de Pago</strong>: El pago se realizará mediante [Metodo de pago], dentro de un plazo de <strong>[Plazo de pago]</strong> días hábiles a partir de la firma del presente contrato.</li><li><strong>Códigos Adicionales</strong>: Por cada <strong>[número de códigos]</strong> códigos adquiridos, <strong>El Vendedor</strong> podrá otorgar a <strong>El Comprador</strong> <strong>[codigos de regalo]</strong> códigos adicionales como promoción o regalo, sin costo adicional.</li></ol>
        <h3>Cláusula Tercera: Entrega de los Códigos</h3><ol><li><strong>Entrega Digital</strong>: <strong>El Vendedor</strong> se compromete a entregar los códigos de forma digital a <strong>El Comprador</strong> dentro de los <strong>[número de días]</strong> días hábiles siguientes a la confirmación del pago.</li><li><strong>Método de Entrega</strong>: Los códigos serán entregados a través de la asignación de dicha cantidad al usuario en la plataforma virtual de Fenabocol Org.</li></ol>
        <h3>Cláusula Cuarta: Obligaciones de las Partes</h3><ol><li><strong>Obligaciones del Vendedor:</strong></li></ol><ul><li>Garantizar que los códigos entregados a <strong>El Comprador</strong> son válidos y pueden ser utilizados para participar en el juego <strong>Bingo Fenabocol</strong>.</li><li>Proveer soporte técnico para cualquier inconveniente relacionado con la activación o uso de los códigos.</li></ul><ol><li><strong>Obligaciones de La Compradora:</strong></li></ol><ul><li>Realizar el pago conforme a lo estipulado en la Cláusula Segunda.</li><li>No comercializar los códigos a un precio mayor al establecido por <strong>El Vendedor</strong> sin autorización previa y por escrito.</li><li>No utilizar los códigos para fines ilícitos o fraudulentos.</li></ul>
        <h3>Cláusula Quinta: Términos y Condiciones del Uso de los Códigos</h3><ol><li><strong>Validez de los Códigos</strong>: Los códigos tendrán una vigencia de acuerdo a la asignación de fechas en los codigos que elija <strong>El Comprador</strong> . Pasado este plazo, los códigos expirarán automáticamente.</li><li><strong>Uso de los Códigos</strong>: Cada código permite una única jugada en el juego <strong>Bingo Fenabocol</strong>. Los códigos no son reembolsables ni intercambiables por dinero en efectivo.</li><li><strong>Promociones y Beneficios</strong>: <strong>El Vendedor</strong> podrá ofrecer jugadas adicionales o promociones bajo los términos definidos por esta. <strong>El Comprador</strong> podrá acceder a estas promociones siempre y cuando cumpla con los requisitos establecidos.</li></ol>
        <h3>Cláusula Sexta: Responsabilidad</h3><ol><li><strong>Exclusión de Responsabilidad</strong>: <strong>El Vendedor</strong> no se hace responsable por el uso indebido de los códigos por parte de <strong>El Comprador</strong> o de terceros a los que esta última entregue los códigos.</li><li><strong>Fuerza Mayor</strong>: Ninguna de las partes será responsable por el incumplimiento de sus obligaciones contractuales en caso de que dicho incumplimiento se deba a causas de fuerza mayor, tales como desastres naturales, conflictos sociales, fallos en sistemas tecnológicos fuera de su control, etc.</li></ol>
        <h3>Cláusula Séptima: Resolución del Contrato</h3><ol><li><strong>Incumplimiento</strong>: En caso de incumplimiento por cualquiera de las partes, la parte afectada podrá solicitar la resolución del contrato, notificando por escrito a la parte incumplidora con una antelación de <strong>[Dias de incumplimiento] </strong>días hábiles.</li><li><strong>Rescisión</strong>: Este contrato podrá ser rescindido por mutuo acuerdo entre las partes, con una notificación previa de <strong>[Dias de rescision]</strong> días hábiles.</li></ol>
        <h3>Cláusula Octava: Confidencialidad</h3><p>Ambas partes se comprometen a mantener confidencial la información relacionada con este contrato, los términos acordados y los detalles técnicos del juego <strong>Bingo Fenabocol</strong> y los códigos.</p>
        <h3>Cláusula Novena: Jurisdicción y Ley Aplicable</h3><p>Este contrato se regirá por las leyes de Colombia. En caso de controversia, las partes acuerdan someterse a la jurisdicción de los tribunales colombianos.</p>
    `);
    const [clauses, setClauses] = useState('');

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [open, setOpen] = useState(false);
    const [panel, setPanel] = useState('Company');
    const handleExpand = (panel) => (event, isExpanded) => {
        setPanel(isExpanded ? panel : false);
    };

    useEffect(() => { setValue('user_id', user?.id); }, []);
    useEffect(() => { setValue('description', description); }, [description]);
    useEffect(() => { setValue('terms_conditions', termsConditions); }, [termsConditions]);

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    const [valueTab, setValueTab] = useState('Descipción');
    const handleChange = (event, newValue) => { setValueTab(newValue); }

    const onSubmit = async (dataForm) => {
        dataForm['gift_moves'] = parseInt(dataForm['gift_moves']);
        Loading.hourglass();
        try {
            const response = await makeNewAgreement(token, dataForm);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'Contrato.pdf';

            if (contentDisposition != null) {
                const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=([^;\n]*)/);
                if (fileNameMatch != null && fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1].trim();
                }
            }

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            handleClose();
            loadData();
        } catch (error) {
            validErrors(error);
        } finally {
            Loading.remove();
        }
    }

    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}><i className='fa-solid fa-file-pdf'></i> <Typography variant='p'>Crear Contrato</Typography></Button>

            <Box
                width={'100%'}
                height={'100%'}
                position={'fixed'}
                top={'0'}
                left={open ? '0%' : '100%'}
                zIndex={1000}
                sx={{
                    background: '#EEE',
                    transition: 'all .3s ease',
                }}
            >
                <Card
                    sx={{
                        width: '100%',
                        height: '100%',
                        overflowY: 'scroll',
                        padding: '1rem',
                        background: '#F1F1F1',
                    }}
                >
                    <Card sx={{ marginBottom: '1rem', padding: '.3rem 1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <IconBack onClick={handleClose} >
                            <i className='fa-solid fa-x'></i> Cerrar
                        </IconBack>
                        <Button variant="contained" color='error' onClick={handleSubmit(onSubmit)}>
                            <i className='fa-solid fa-file-pdf'></i> <Typography variant='p'>Crear Contrato</Typography>
                        </Button>
                    </Card>

                    <Grid container spacing={2} sx={{ marginBottom: '2rem' }}>
                        {/* CONFIG */}
                        <Grid item xs={12} md={4}>
                            <Typography variant='h6' textAlign={'center'}>Configuración de contrato</Typography>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* SELLER */}
                                <SellerConfig register={register} panel={panel} handleExpand={handleExpand} errors={errors} />

                                {/* COMPANY */}
                                <CompanyConfig user={user} register={register} panel={panel} handleExpand={handleExpand} errors={errors} />

                                {/* AGREEMENT DATA */}
                                <AgreementConfig user={user} register={register} panel={panel} handleExpand={handleExpand} setValue={setValue} errors={errors} />
                            </form>
                        </Grid>

                        {/* TEXT EDITOR */}
                        <Grid item xs={12} md={8}>
                            <TextField
                                fullWidth
                                required
                                defaultValue={title}
                                size="small"
                                label={'Titulo'}
                                placeholder={'Titulo'}
                                onChange={(e) => { setTitle(e.target.value) }}
                                sx={{
                                    background: '#FFF'
                                }}
                                {...register('title', { required: "Este campo es obligatorio" })}
                                error={errors.title}
                            />
                            {errors.title && <Typography variant='body2' color={'error'} fontSize={'12px'}>{errors.title.message}</Typography>}

                            <TabContext value={valueTab}>
                                <TabList onChange={handleChange} aria-label="set_avatar" centered scrollButtons="auto">
                                    <Tab label={'Descipción'} value={'Descipción'} />
                                    <Tab label={'T&C'} value={'T&C'} />
                                    <Tab label={'Clausulas'} value={'Clausulas'} />
                                </TabList>

                                {/* DESCRIPCION */}
                                <TabPanel keepMounted value={'Descipción'} sx={{ margin: 0, padding: 0 }}>
                                    <ReactQuill
                                        style={{
                                            height: '100%',
                                            minHeight: '500px',
                                            width: '100%',
                                            background: '#FFF',
                                            padding: '0',
                                            margin: '0'
                                        }}
                                        theme="snow"
                                        value={description}
                                        onChange={setDescription}
                                        modules={modules}
                                        formats={formats}
                                        className="custom-quill-editor"
                                    />
                                </TabPanel>
                                {/* T&C */}
                                <TabPanel keepMounted value={'T&C'} sx={{ margin: 0, padding: 0 }}>
                                    <ReactQuill
                                        style={{
                                            height: '100%',
                                            minHeight: '500px',
                                            width: '100%',
                                            background: '#FFF',
                                            padding: '0',
                                            margin: '0'
                                        }}
                                        theme="snow"
                                        value={termsConditions}
                                        onChange={setTermsConditions}
                                        modules={modules}
                                        formats={formats}
                                        className="custom-quill-editor"
                                    />
                                </TabPanel>
                                {/* CLAUSES */}
                                <TabPanel keepMounted value={'Clausulas'} sx={{ margin: 0, padding: 0 }}>
                                    <ReactQuill
                                        style={{
                                            height: '100%',
                                            minHeight: '500px',
                                            width: '100%',
                                            background: '#FFF',
                                            padding: '0',
                                            margin: '0'
                                        }}
                                        theme="snow"
                                        value={clauses}
                                        onChange={setClauses}
                                        modules={modules}
                                        formats={formats}
                                        className="custom-quill-editor"
                                    />
                                </TabPanel>
                            </TabContext>

                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    )
}
const IconBack = styled.span`
    font-size: 1.2rem;
    cursor: pointer;
    position: relative;
    i {
        transition: all .3s ease;
        padding: 5px;
        box-sizing: border-box;
        border: 2px solid #FFF;
    }
    &:hover {
        text-decoration: underline;
        i {
            border: 2px solid #000;
            border-radius: 50%;
        }
    }

`;

export default TextEditor