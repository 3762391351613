import { Box, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useQuiosco from '../../../hooks/useQuiosco'
import useIndex from '../../../hooks/useIndex'

const LatBar = ({
    activeBar,
    setActiveBar,
    userAvatar
}) => {
    const [activeClass, setActiveClass] = useState('');
    const { userPages, handleChangeMenuOptions } = useQuiosco();
    const { auth } = useIndex();
    const currentLocation = useLocation();

    useEffect(() => {
        const width = window.innerWidth;
        width > 576 ? setActiveClass('closed') : setActiveClass('actRs');
    }, [activeBar]);

    const selectOptionMenu = (item) => {
        const width = window.innerWidth;
        handleChangeMenuOptions(item.id);
        width < 576 && setActiveBar(false);
    }

    return (
        <LateralBar id="lateralbar" className={`lateralbar ${activeBar && activeClass}`}>
            {/* TITLE */}
            <Box className="title">
                <Typography variant='h1'>Fenabocol</Typography>
                <i className="fa-solid fa-xmark" onClick={() => setActiveBar(false)}></i>
            </Box>
            {/* LATERAL USER */}
            <Box className="usrLat">
                {/* USER IMAGE */}
                <Box className="imgUstLat">
                    <img src={userAvatar} alt="user-avatar" className="imgUsrLatInt" />
                </Box>
                {/* USER DESCRIPTION */}
                <Box className="descrUser">
                    <Typography variant='h5' className="rol-cliente">{auth?.role ?? 'User'}</Typography>
                    <Typography component={'p'} className="nombre-usuario">{auth?.name ?? 'User Name'}</Typography>
                </Box>
            </Box>
            {/* LATERAL MENU */}
            <nav className="menu-lateral-princ">
                {/* LATERAL OPTIONS */}
                {userPages.map((item) => (
                    <Tooltip title={`${item.label}`} placement="right" arrow key={item.id}>
                        <Link to={`${item.link}`} onClick={() => {selectOptionMenu(item)}} className={`menu-opcion ${currentLocation?.pathname.includes(item.link) && 'select'}`}>
                            <i className={`${item.icon}`}></i><span>{`${item.label}`}</span>
                        </Link>
                    </Tooltip>
                ))}
            </nav>
        </LateralBar>
    )
}

export default LatBar

const LateralBar = styled.div`
    background: #333;
    color: #fff;
    height: 100vh;
    padding: 0;
    margin: 0;
    border-right: 1px solid;
    border-color: rgba(255,255,255,.1);
    width: 250px;
    transition: .5s;
    z-index: 1000;
    .title {
        width: 100%;
        h1 {
            font-size: 2rem;
            margin: 0;
            padding: 5px 20px;
            text-align: center;
            font-family: 'Lato';
        }
        i {
            display: none;
        }
    }
    .usrLat {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .imgUstLat {
            width: 55%;
            aspect-ratio: 1/1;
            border: 2px solid #AAA;
            border-radius: 50%;
            background: #1c1c1c;
            overflow: hidden;
            padding: 0;
            img {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .descrUser {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            font-family: 'Lato';
            letter-spacing: 1px;
            .rol-cliente {
                margin-bottom: 0;
            }
            .nombre-usuario {
                padding: 0;
                margin: 0;
            }
        }
    }
    .menu-lateral-princ {
        width: 100%;
        max-height: calc(100vh - 256px);
        margin-top: 16px;
        border-top: 1px solid rgba(255,255,255,.5);
        overflow: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: rgba(255,255,255,.3);
        }
        &::-webkit-scrollbar-thumb {
            background-color: #CCC;
            border-radius: 20px;
            border: 1px solid rgba(255,255,255,.3);
            cursor: pointer;
        }
        .menu-opcion {
            display: block;
            padding: 20px;
            color: #fff;
            text-decoration: none;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(255,255,255,.1);
            cursor: pointer;
            font-family: 'Lato';
            letter-spacing: 1px;
            line-height: 0;
            font-size: 1rem;
            text-transform: uppercase;
            &.select, &:hover {
                background: rgba(255,255,255,.1);
            }
            i {
                margin-right: 20px;
            }
        }
    }

    &.closed {
        width: 62px;
        .title {
            display: none;
            width: 100%;
            height: 45px;
            border-bottom: 1px solid;
            border-color: rgba(255,255,255,.1);
            h1 {
                display: none;
            }
        }
        .usrLat {
            .imgUstLat {
                width: 80%;
                margin: 10px 0px;
            }
            .descrUser {
                display: none;
            }
        }
        .menu-lateral-princ {
            max-height: calc(100vh - 84.8px);
            .menu-opcion {
                display: flex;
                justify-content: center;
                align-items: center;
                i {
                    margin: 0;
                    font-size: 1.5rem;
                }
                span {
                    display: none;
                }
            }
        }
    }
    overflow: hidden;

    @media (max-width: 576px) {
        position: absolute;
        top: 0;
        left: -100vw;
        max-width: 100%;
        z-index: 10;
        height: 100vh;
        overflow: hidden;
        transition: left .5s ease;
        .title {
            position: relative;
            h1 {
                font-size: 1.6rem;
            }
            i {
                display: inline-block;
                font-size: 1.6rem;
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translate(0, -50%);
                &:hover {
                    text-shadow: 0 0 2px #fff;
                }
            }
        }
        &.actRs{
			left: 0;
		}
        .usrLat .imgUstLat{
			/* width: 40%; */
		}
    }
`