import clientAxios from "../config";

const getAllUserRoutes = async (token) => clientAxios('/api/user-routes', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const validateRoute = async (token, data) => clientAxios.post('/api/user-route/path', data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const getUserById = async (token, id) => clientAxios(`/api/user/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const getUserByUuId = async (token, uuid) => clientAxios(`/api/user-uuid/${uuid}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const getAllUsers = async (token, rowsPerPage, page, userSearch) => clientAxios(`/api/users/${rowsPerPage}${userSearch && `/${userSearch}`}?page=${page}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const updateUser = async (token, id, data) => clientAxios.put(`/api/user/${id}`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const getAllUsersAvatars = async (token) => clientAxios(`/api/users-avatars`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export {
    getAllUserRoutes,
    validateRoute,
    getUserById,
    updateUser,
    getAllUsers,
    getAllUsersAvatars,
    getUserByUuId,
}