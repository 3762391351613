import React from "react"
import { Route, Routes } from "react-router-dom"
import Contact from "../pages/contact/Contact"
import Documents from "../pages/documents/Documents"
import Donations from "../pages/donations/Donations"
import Game from "../pages/ganacol/game/Game"
import Ganacol from "../pages/ganacol/Ganacol"
import History from "../pages/ganacol/history/History"
import Index from "../pages/index/Index"
import Dashboard from "../pages/layout/Dashboard/Dashboard"
import LayoutIndex from "../pages/layout/LayoutIndex"
// import Invoice from "../pages/legal/Invoice"
import TermsAndConditions from "../pages/legal/TermsAndConditions"
import Services from "../pages/services/Services"
import NotFound from '../pages/error/NotFound'
import Configuration from "../pages/ganacol/configuration/Configuration"
import Statistics from "../pages/ganacol/statistics/Statistics"
import ProtectedRoute from "./ProtectedRoute"
import UsersPage from "../pages/ganacol/users/UsersPage"
import Files from "../pages/ganacol/files/Files"
// import Incomes from "../pages/ganacol/incomes/Incomes"
import PageConstruction from '../pages/error/PageConstruction'
import Test from "../pages/ganacol/test/Test"
import GameCodes from "../pages/ganacol/gameCodes/GameCodes"
import License from "../pages/legal/License"
import useIndex from "../hooks/useIndex"
import ResetPassword from "../pages/auth/ResetPassword"
import System from "../pages/ganacol/system/System"
import IndexGanacol from "../pages/ganacol/IndexGanacol"
import AgreementsPage from "../pages/ganacol/agreements/AgreementsPage"

const AppRoutes = () => {
    const rolesGame = [1, 2, 5];
    const { auth } = useIndex();
    return (
        <Routes>
            <Route path="/" element={<LayoutIndex />}>
                <Route index element={<Index/>}></Route>
                <Route path="/documents" element={<Documents />}></Route>
                <Route path="/donations" element={<Donations />}></Route>
                <Route path="/services" element={<Services />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/ganacol" element={<Ganacol />}></Route>
                <Route path="/terms-conditions" element={<TermsAndConditions />}></Route>
                <Route path="/license" element={<License />}></Route>
                <Route path="/reset-password/:token/:userId" element={<ResetPassword />}></Route>
            </Route>
            <Route path="/ganacol/dashboard" element={<Dashboard />}>
                <Route index element={<IndexGanacol />}></Route>
                <Route element={<ProtectedRoute />}>
                    <Route path="/ganacol/dashboard/game" element={<Game />}></Route>
                    <Route path="/ganacol/dashboard/history" element={<History />}></Route>
                    <Route path="/ganacol/dashboard/configuration" element={<Configuration />}></Route>
                    <Route path="/ganacol/dashboard/statistics" element={<Statistics />}></Route>
                    <Route path="/ganacol/dashboard/users" element={<UsersPage />}></Route>
                    <Route path="/ganacol/dashboard/users/:uuid" element={<UsersPage />}></Route>
                    <Route path="/ganacol/dashboard/files/" element={<Files />}></Route>
                    <Route path="/ganacol/dashboard/incomes/" element={<PageConstruction />}></Route>
                    <Route path="/ganacol/dashboard/test/" element={<Test />}></Route>
                    <Route path="/ganacol/dashboard/codes" element={<GameCodes />}></Route>
                    <Route path="/ganacol/dashboard/system" element={<System />}></Route>
                    <Route path="/ganacol/dashboard/agreements" element={<AgreementsPage />}></Route>
                </Route>
            </Route>
            <Route path="*" element={<NotFound />}></Route>
        </Routes>
    )
}

export default AppRoutes