import { Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <FooterBar>
            <div className='container'>
                <div className='sec aboutus'>
                    <Typography variant='h2' fontFamily={'Francois One'}>Nosotros</Typography>
                    <Typography component={'p'} fontFamily={'Karla'}>
                        Fenabocol ORG (Federación Nacional de Bomberos de Colombia), una Institución de socorro sin ánimo de lucro, creada para la atención de emergencias como incendios, inundaciones, terremotos, accidentes de tránsito, maremotos, tsunamis y todas las demás calamidades conexas que se puedan presentar en cualquier lugar del país.
                    </Typography>

                    <ul className='sci'>
                        <li><a href='#/'><i className="fa-brands fa-facebook-f"></i></a></li>
                        <li><a href='#/'><i className="fa-brands fa-twitter"></i></a></li>
                        <li><a href='#/'><i className="fa-brands fa-instagram"></i></a></li>
                        <li><a href='#/'><i className="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </div>

                <div className='sec quicklinks'>
                    <Typography variant='h2' fontFamily={'Francois One'}>Conoce Más</Typography>

                    <ul>
                        <li><Link to={'/ganacol'}>Ganacol</Link></li>
                        <li><Link to={'/contact'}>Contacto</Link></li>
                        <li><Link to={'/donations'}>Donaciones</Link></li>
                        <li><Link to={'/documents'}>Documentos</Link></li>
                        <li><Link to={'/license'}>Licencia</Link></li>
                        <li><Link to={'/terms-conditions'}>Terminos & Condiciones</Link></li>
                    </ul>
                </div>

                <div className='sec quicklinks'>
                    <Typography variant='h2' fontFamily={'Francois One'}>Información</Typography>

                    <ul>
                        <li><p>Fuente de Información: Junta Coordinadora</p></li>
                        <li><p>Guionista: Edgar Arturo Fajardo Bernal</p></li>
                        <li><p>!! Viculate a nosotros !!</p></li>
                    </ul>
                </div>

                <div className='sec contact'>
                    <Typography variant='h2' fontFamily={'Francois One'}>Contactanos</Typography>

                    <ul className='info'>
                        <li>
                            <span><i className="fa-solid fa-location-dot"></i></span>
                            <span>Bogota D.C. <br /> Calle 43a sur #78g - 28 <br /> Barrio: Onasis</span>
                        </li>
                        <li>
                            <span><i className="fa-solid fa-phone"></i></span>
                            <p><a href='wa:300 2910614'>+57 300 2910614</a> <br />
                                <a href='tel:4536859'>+57 4536859</a></p>
                        </li>
                        <li>
                            <span><i className="fa-solid fa-envelope"></i></span>
                            <p><a href='mailto:febocolorg@gmail.com'>febocolorg@gmail.com</a></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='copyrightText'>
                <p>Copyright © {year} Fenabocol ORG</p>
            </div>
        </FooterBar>
    )
}

export default Footer

const FooterBar = styled.footer`
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px 0px 0px 0px;
    box-sizing: border-box;
    background-color: #333;
    z-index: 0;
    .container {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        grid-gap: 20px;
        padding: 0px 70px;
        box-sizing: border-box;
        .sec {
            h2 {
                position: relative;
                color: #FFF;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 15px;
                &:before{
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 50px;
                    height: 2px;
                    background: #FFEB3B;
                }
            }
            p {
                color: #FFF;
            }
            .sci {
                margin-top: 20px;
                padding: 0;
                display: grid;
                grid-template-columns: repeat(4, 50px);
                grid-gap: 10px;
                li {
                    list-style: none;
                    a {
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        background-color: #3E56CB;
                        display: grid;
                        align-content: center;
                        justify-content: center;
                        text-decoration: none;
                        border-radius: 4px;
                        .fa, .fa-brands {
                            color: #FFF;
                            font-size: 20px;
                        }
                        &:hover {
                            background-color: #6380FE;
                        }
                    }
                }
            }
        }
        .quicklinks {
            position: relative;
            ul {
                padding: 0;
                li {
                    list-style: none;
                    a {
                        color: #F7F7F7;
                        text-decoration: none;
                        margin-bottom: 10px;
                        display: inline-block;
                        &:hover {
                            color: #FFEB3B;
                        }
                    }
                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .contact {
            .info {
                position: relative;
                padding: 0;
                li {
                    display: grid;
                    grid-template-columns: 30px 1fr;
                    margin-bottom: 16px;
                    span:nth-child(1) {
                        color: #FFF;
                        font-size: 17px;
                    }
                    span {
                        color: #FFF;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    a {
                        color: #F7F7F7;
                        text-decoration: none;
                        &:hover {
                            color: #FFEB3B;
                        }
                    }
                }
            }
        }
    }
    .copyrightText {
        width: 100%;
        background: #444;
        padding: 1px 0;
        text-align: center;
        color: #FFF;
    }
    @media (max-width: 991px) {
        padding: 40px 0px 0px 0px;
        .container {
            padding: 0px 40px;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (max-width: 768px) {
        .container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
`