import { LoadingButton } from '@mui/lab';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import validErrors from '../../../../hooks/validErrors';
import { addPrize } from '../../../../data/api/prizes/PrizeRoutes';
import { Loading, Notify } from 'notiflix';
import moneyFormat from '../../../../hooks/moneyFormat';

const AddPrize = ({
    token,
    loadData,
    handleClose
}) => {
    const { register, handleSubmit, setValue, watch } = useForm();
    const [submit, setSubmit] = useState(false);
    const [prizeComp, setPrizeCom] = useState(0);

    useEffect(() => {
        console.log(prizeComp)
    }, [prizeComp]);

    const onSubmit = async (dataForm) => {
        setSubmit(true);
        Loading.arrows();
        try {
            const { data } = await addPrize(token, dataForm);

            if (data?.success) {
                Notify.success(data?.message);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
            loadData();
            handleClose();
            Loading.remove();
        }
    }

    return (
        <Card className='animate__animated animate__fadeInRight'
            sx={{
                width: '90%',
                maxWidth: '500px',
                paddingLeft: '1rem',
                paddingRight: '1rem',
            }}
        >
            <CardContent>
                <Typography variant='h5' textAlign={'center'} marginBottom={'1.3rem'}>Agregar Premio</Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {/* HITS */}
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                placeholder={'Aciertos'}
                                fullWidth
                                type={'number'}
                                {...register('hits')}
                            />
                        </Grid>
                        {/* PREMIO */}
                        <Grid item xs={6} md={8}>
                            <TextField
                                variant="outlined"
                                placeholder={'Premio'}
                                label={prizeComp}
                                fullWidth
                                onKeyUp={(e) => setPrizeCom(moneyFormat(parseInt(e.target.value)))}
                                type={'number'}
                                {...register('prize')}
                            />
                        </Grid>
                        {/* COMPENSATION */}
                        <Grid item xs={6} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="Compensacion">Compensación</InputLabel>
                                <Select
                                    labelId='Compensacion'
                                    id="compensation"
                                    defaultValue={0}
                                    label="Compensación"
                                    {...register('compensation')}
                                >
                                    <MenuItem value={0}>Jugadas</MenuItem>
                                    <MenuItem value={1}>COP</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* SUBMIT */}
                        <Grid item xs={12}>
                            <LoadingButton
                                fullWidth
                                loading={submit}
                                loadingPosition="start"
                                startIcon={<i className="fa-solid fa-floppy-disk"></i>}
                                variant="contained"
                                size="large"
                                type='submit'
                            >
                                <span>Guardar</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    )
}

export default AddPrize