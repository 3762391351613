import { LoadingButton } from '@mui/lab'
import { Grid, TextField } from '@mui/material'
import { Notify } from 'notiflix';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { validCode } from '../../../data/api/incomes/IncomeRoutes';
import useIndex from '../../../hooks/useIndex';
import validErrors from '../../../hooks/validErrors';

const ValidCodeForm = ({setOpenPay}) => {
    const { register, handleSubmit } = useForm();
    const { token, validateUser } = useIndex();

    const [submit, setSubmit] = useState(false);
    const [code, setCode] = useState('');

    const onSubmit = async (dataForm) => {
        setSubmit(true);

        try {
            const { data } = await validCode(token, dataForm);
            if (data?.success) {
                setCode('');
                validateUser();
                Notify.success(data?.message);
                setOpenPay(false);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <TextField
                        required
                        fullWidth
                        label={'Codigo'}
                        placeholder={'Codigo'}
                        autoComplete={false}
                        value={code}
                        autoFocus={true}
                        disabled={submit}
                        {...register('code')}
                        onChange={(e) => setCode(e.target.value.toUpperCase())}
                    />
                </Grid>
                <Grid item xs={12}>

                    <LoadingButton
                        fullWidth
                        loading={submit}
                        loadingPosition="start"
                        startIcon={<i className="fa-solid fa-magnifying-glass"></i>}
                        variant="contained"
                        size="large"
                        type='submit'
                    >
                        <span>Validar</span>
                    </LoadingButton>
                </Grid>
            </Grid>

        </form>
    )
}

export default ValidCodeForm