import axios from "axios";

const clientAxios = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true
});

export default clientAxios;