import { Notify } from 'notiflix';
import React, { useEffect, useState } from 'react'
import { createContext } from "react"
import { useNavigate } from 'react-router-dom';
import { getAllUserRoutes } from '../data/api/users/UserRoutes';
import useIndex from '../hooks/useIndex';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const { auth, token } = useIndex();

    const [userPages, setUserPages] = useState([]);
    const [actualPage, setActualPage] = useState({});

    // Initial Loads
    useEffect(() => {
        getUserPages()
    }, []);

    const handleChangeMenuOptions = (id) => {
        const newPage = userPages.filter(page => page.id === id)[0]
        setActualPage(newPage);
    }

    const getUserPages = async () => {
        try {
            const {data} = await getAllUserRoutes(token);
            setUserPages(data?.data);
        } catch (error) {
            if (auth?.id == undefined) {
                Notify.failure('No has iniciado sesion');
                navigate('/ganacol');
            }
        }
    }

    return (
        <AuthContext.Provider
            value={{
                userPages,
                setUserPages,
                actualPage,
                handleChangeMenuOptions,
            }}
        >{children}</AuthContext.Provider>
    )
}

export {AuthProvider}
export default AuthContext;