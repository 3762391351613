import React from 'react'
import styled from 'styled-components'

const NotificationBar = ({
    setActiveNot,
    activeNot
}) => {
    // 
    return (
        <NotificationContainer id="barNot" className={`animate__animated ${activeNot ? 'animate__slideInRight' : 'animate__slideOutRight'}`}>
            {/* <!-- CONTENEDOR OPCIONES MENU --> */}
            <div className={`menu-notif`}>
                <div className="cont-men-not">
                    {/* <!-- TITULAR NOTIFICACIONES --> */}
                    <div className="titular-notif">
                        <p className="titulo-notif-mnu">Notificaciones</p>
                        <i className="fa-solid fa-xmark" id="cerrar_notificaciones" onClick={() => setActiveNot(false)}></i>
                    </div>
                    {/* <!-- MENU DE OPCIONES --> */}
                    <nav className="opc-mnu">
                        {/* <!-- OPCION #1 --> */}
                        <a href="#/" className="opc-mnu-not" name="notifications">
                            <i className="fa-regular fa-bell"></i>
                            <span>Notifications</span>
                        </a>
                        {/* <!-- OPCION #2 --> */}
                        <a href="#/" className="opc-mnu-not" name="payments">
                            <i className="fa-solid fa-wallet"></i>
                            <span>Payments</span>
                        </a>
                        {/* <!-- OPCION #3 --> */}
                        <a href="#/" className="opc-mnu-not" name="access">
                            <i className="fa-solid fa-address-card"></i>
                            <span>Access</span>
                        </a>
                        {/* <!-- OPCION #4 --> */}
                        <a href="#/" className="opc-mnu-not" name="calendar">
                            <i className="fa-regular fa-calendar-days"></i>
                            <span>Calendar</span>
                        </a>
                        {/* <!-- OPCION #4 --> */}
                        <a href="#/" className="opc-mnu-not" name="mails">
                            <i className="fa-solid fa-envelope"></i>
                            <span>Mails</span>
                        </a>
                        {/* <!-- OPCION #4 --> */}
                        <a href="#/" className="opc-mnu-not" name="notes">
                            <i className="fa-regular fa-note-sticky"></i>
                            <span>Notes</span>
                        </a>
                        {/* <!-- OPCION #4 --> */}
                        <a href="#/" className="opc-mnu-not" name="security">
                            <i className="fa-solid fa-shield-halved"></i>
                            <span>Security</span>
                        </a>
                        {/* <!-- OPCION #4 --> */}
                        <a href="#/" className="opc-mnu-not" name="terms-conditions">
                            <i className="fa-solid fa-scroll"></i>
                            <span>Terms & Conditions</span>
                        </a>
                        {/* <!-- OPCION #4 --> */}
                        <a href="#/" className="opc-mnu-not" name="gifts">
                            <i className="fa-solid fa-gift"></i>
                            <span>Gifts</span>
                        </a>
                    </nav>
                    {/* <!-- FOOTER NOTIFICACIONES --> */}
                    <div className="foot-not">
                        <i className="fa-brands fa-css3-alt"></i>
                        <i className="fa-brands fa-html5"></i>
                        <i className="fa-brands fa-square-js"></i>
                        <i className="fa-brands fa-laravel"></i>
                        <i className="fa-brands fa-react"></i>
                        <i className="fa-brands fa-php"></i>
                        <i className="fa-solid fa-code"></i>
                    </div>
                </div>
            </div>
            {/* <!-- ALERTAS --> */}
            <div className="seccion-notif" id="notificaciones">
                Proximamente.
            </div>
        </NotificationContainer>
    )
}

export default NotificationBar

const NotificationContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    .menu-notif {
        position: absolute;
        top: 0;
        right: 0;
        width: 285px;
        height: 100%;
        max-width: 100%;
        background: #fff;
        .cont-men-not {
            position: relative;
            width: 100%;
            height: 100%;
            .titular-notif {
                width: 100%;
                height: 45px;
                background: #262a34;
                color: #fff;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .titulo-notif-mnu {
                    font-family: 'Lato';
                    font-size: 1.4rem;
                    line-height: 45px;
                    letter-spacing: 1px;
                    padding: 0;
                    margin: 0;
                }
                i {
                    font-size: 1.2rem;
                    &:hover {
                        cursor: pointer;
                        text-shadow: 0 0 3px #fff;
                        transition: .5s;
                    }
                }
                &.act i {
                    transform: rotate(180deg);
                }
            }
            .opc-mnu {
                width: 100%;
                .opc-mnu-not{
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    border-top: 1px solid;
                    border-color: #f1f1f1;
                    text-decoration: none;
                    color: #000;
                    transition: .3s;
                    span {
                        font-family: 'Lato';
                        letter-spacing: 1px;
                        font-size: 1.3rem;
                    }
                    i {
                        width: 40px;
                        height: 40px;
                        font-size: 1.3rem;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 50%;
                        background: #262a34;
                        color: #fff;
                        margin-right: 20px;
                    }
                    &:hover {
                        background: rgba(0,0,0,.1);
                    }
                }
            }
            .foot-not {
                width: 100%;
                padding: 5px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                background: #262a34;
                position: absolute;
                bottom: 0;
                left: 0;
                i {
                    padding: 5px;
                    color: #FFF;
                }
            }
        }
    }
    .seccion-notif {
        position: absolute;
        top: 50%;
        left: -150%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 100px;
        background: #fff;
        max-width: 95%;
        max-height: 95%;
        border: 1px solid #ccc;
        transition: .5s;
        &.en_pantalla {
            left: 50%;
        }
    }
`