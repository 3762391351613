import { Notify } from 'notiflix';
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Loader from '../components/loader/Loader';
import { validateRoute } from '../data/api/users/UserRoutes';
import useIndex from '../hooks/useIndex';
import UnauthorizedPage from '../pages/error/UnauthorizedPage';

const ProtectedRoute = () => {

    const { token } = useIndex();
    const currentLocation = useLocation();
    const [loading, setLoading] = useState(true);
    const [validPage, setValidPage] = useState();
    const [actualPage, setActualPage] = useState();

    useEffect(() => {
        if (currentLocation?.pathname != actualPage) {
            loadData();
            setActualPage(currentLocation?.pathname);
        }
    }, [currentLocation]);

    const loadData = async () => {
        setLoading(true);
        try {
            const { data } = await validateRoute(token, {'path':currentLocation?.pathname});
            setValidPage(data?.success);
        } catch (error) {
            const errorResponse = error?.response.data.message;
            setValidPage(error?.response.data.success);
            Notify.failure(errorResponse);
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (<Loader />)
    } else {
        if (validPage) {
            return <Outlet />
        } else {
            return <UnauthorizedPage />
        }
    }
}

export default ProtectedRoute