import React from 'react'
import styled from 'styled-components'

const BalotaContent = ({children}) => {
    return (
        <ContenedorBalotas>
            {children}
        </ContenedorBalotas>
    )
}

export default BalotaContent

const ContenedorBalotas = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: .5rem 1rem;
    border: 10px dotted #F4D03F;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5px;
    background: radial-gradient(circle, rgba(214,34,34,1) 0%, rgba(143,8,8,1) 100%);
    box-shadow: 0px 0px 16px 4px rgba(143,8,8,1);

    @media (max-width: 576px) {
        padding: .5rem .7rem;
        border: 7px dotted #F4D03F;
    }
`