import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import CompanyAgreements from '../../agreements/CompanyAgreements'

const UserAlliance = ({
    user = [],
    loadUser
}) => {

    const [countAgreement, setCountAgreement] = useState(0);

    return (
        <Box
            width={'100%'}
        >
            <Typography variant='h4' textAlign={'center'}>Convenios</Typography>

            {user && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='body2'>Nos complace anunciar nuestra alianza estratégica con {user?.name}, una colaboración que fortalece nuestro compromiso de ofrecer soluciones innovadoras y de calidad a nuestros clientes. Esta sinergia nos permitirá combinar nuestra experiencia con el liderazgo de {user?.name}, brindando mejores servicios y productos que responden a las crecientes demandas del mercado. Juntos, continuaremos impulsando la innovación y el crecimiento sostenido en beneficio de nuestros clientes y socios comerciales. </Typography>
                        <Typography variant='body2'>Alizna celebrada desde el: {user?.created_at}, tiempo en el que la compañia {user?.name} ha tenido un total de {countAgreement} contratos, {user?.collection_count} colecciones y {user?.codes_total} jugadas</Typography>
                        <Divider sx={{margin: '10px 0'}}></Divider>
                        <Typography variant='body2'>Nombre: {user?.name}</Typography>
                        <Typography variant='body2'>Correo: {user?.email}</Typography>
                        <Typography variant='body2'>Telefono: {user?.phone}</Typography>
                        <Typography variant='body2'>{user?.document_type_short}: {user?.document}</Typography>
                    </Grid>
                </Grid>
            )}

            {user?.id && (
                <CompanyAgreements user={user} loadUser={loadUser} setCountAgreement={setCountAgreement} />
            )}
        </Box>
    )
}

export default UserAlliance