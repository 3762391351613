import { Box, Typography } from '@mui/material'
import React, { createRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';

const Register = ({
    setPanel,
    codeWin,
    winData
}) => {

    const { register } = useAuth();

    const nameRef = createRef();
    const emailRef = createRef();
    const documentRef = createRef();
    const passwordRef = createRef();
    const passwordConfirmationRef = createRef();
    const agreeTermsRef = createRef();

    const handleSubmit = async e => {
        e.preventDefault();

        const data = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            document: documentRef.current.value,
            password: passwordRef.current.value,
            password_confirmation: passwordConfirmationRef.current.value,
            agree_terms: agreeTermsRef.current.checked ? true : null,
            codeWin: codeWin,
            winData: winData,
        }

        register(data);
    }

    return (
        <TabPane className={`tab-pane`} id="sign_up">
            <form 
                className="login-form"
                onSubmit={handleSubmit} 
                noValidate
            >
                <Box className="relative">
                    <input 
                        className="input-lg" 
                        id="name" 
                        name='name' 
                        placeholder="Name"
                        type="text"
                        ref={nameRef}
                     />
                    <i className="fa fa-user"></i>
                </Box>
                <Box className="relative">
                    <input 
                        className="input-lg" 
                        id="email" 
                        name='email' 
                        placeholder="E-mail Address"
                        type="email"
                        ref={emailRef}
                     />
                    <i className="fa fa-at"></i>
                </Box>
                <Box className="relative">
                    <input 
                        className="input-lg" 
                        id="document" 
                        name='document' 
                        placeholder="Document"
                        type="text"
                        ref={documentRef}
                     />
                    <i className="fa fa-id-card"></i>
                </Box>
                <Box className="relative">
                    <input 
                        className="input-lg" 
                        id="password" 
                        name='password' 
                        placeholder="Password"
                        type="password"
                        ref={passwordRef}
                    />
                    <i className="fa fa-lock"></i>
                </Box>
                <Box className="relative">
                    <input 
                        className="input-lg" 
                        id="password_confirmation" 
                        name='password_confirmation' 
                        placeholder="Repeat Password"
                        type="password"
                        ref={passwordConfirmationRef}
                    />
                    <i className="fa fa-lock"></i>
                </Box>
                <Box>
                    <button className="btn-success btn-lg" type="submit">Sign Up</button>
                </Box>
                <Box>
                    <input id="agree_terms" name='agree_terms' type="checkbox" ref={agreeTermsRef} defaultChecked />
                    <label className='agree-terms' htmlFor="agree_terms"> Acepto los <Link to={'/terms-conditions'}>Terminos & Condiciones</Link> </label>
                </Box>
                <hr />
                <Box>
                    <Typography textAlign={'center'}><a href='#/' onClick={() => setPanel(true)}>Ya te encuentras registrado?</a></Typography>
                </Box>
            </form>
        </TabPane>
    )
}

export default Register

const TabPane = styled.div`
    .relative {
        position: relative;
    }
    .agree-terms {
        color: #FFF;
    }
    a {
        cursor: pointer;
    }
    .login-form {
        .form-group {
            margin-right: 0;
            margin-left: 0;
        }

        i {
            position: absolute;
            top: 0;
            left: 19px;
            line-height: 52px;
            color: rgba(40, 52, 67, 1);
            z-index: 10;
            font-size: 16px;
        }

        .input-lg {
            width: 100%;
            font-size: 16px;
            height: 52px;
            padding: 10px 25px;
            margin-bottom: .6rem;
            border-radius: 0;
        }
    }
    .btn-success {
        width: 100%;
        background-color: #25a08d;
        background-image: none;
        padding: 8px 50px;
        margin: 20px 0px;
        border-radius: 40px;
        border: 1px solid #25a08d;
        -webkit-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        color: #FFF;

        &:focus, &:hover {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
        }

        &.active {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;

            &:hover {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
            }
        }

        &:active {
            &:hover, &:focus {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
            }

            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
        }
    }
`