import { Box } from '@mui/material'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const PageConstruction = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center'
        }}>
            <ContainerPage>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1536.2 755.1">
                    <rect x="96.7" y="27.1" fill="#808285" width="157.4" height="471.6" />
                    <rect x="458.3" fill="#808285" width="157.4" height="471.6" />
                    <polygon fill="#D1D3D4" points="489.6,475.3 219.3,475.3 219.3,72.3 216,68.8 216,51.3 464,51.3 489.6,90.7 " />
                    <path fill="#F4F4F4" d="M1482.2,755.1H54c-36.8,0-62.8-36.1-51.2-71l36.3-108.5h1458l36.3,108.5C1545,719,1519,755.1,1482.2,755.1z" />
                    <g>
                        <g>
                            <g>
                                <g>
                                    <polygon fill="#D1D3D4" points="95.8,582.5 77.8,582.5 77.8,452.9 86.8,438.5 95.8,452.9 				" />
                                    <path fill="#BCBEC0" d="M84.4,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C86.5,552.3,84.5,582.1,84.4,583.4z"/>
                                    <path fill="#BCBEC0" d="M89.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C90.6,499.1,92.5,546.6,89.5,583.4z"/>
                                    <path fill="#231F20" d="M98.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M80.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="114.3,582.5 96.3,582.5 96.3,452.9 105.3,438.5 114.3,452.9 				" />
                                    <path fill="#BCBEC0" d="M102.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C105,552.3,103,582.1,102.9,583.4z"/>
                                    <path fill="#BCBEC0" d="M108,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C109.1,499.1,111,546.6,108,583.4z"/>
                                    <path fill="#231F20" d="M116.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M98.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="132.8,582.5 114.8,582.5 114.8,452.9 123.8,438.5 132.8,452.9 				" />
                                    <path fill="#BCBEC0" d="M121.4,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C123.5,552.3,121.5,582.1,121.4,583.4z"/>
                                    <path fill="#BCBEC0" d="M126.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C127.6,499.1,129.6,546.6,126.5,583.4z"/>
                                    <path fill="#231F20" d="M135.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M117.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="151.3,582.5 133.3,582.5 133.3,452.9 142.3,438.5 151.3,452.9 				" />
                                    <path fill="#BCBEC0" d="M139.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C142.1,552.3,140,582.1,139.9,583.4z"/>
                                    <path fill="#BCBEC0" d="M145,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C146.1,499.1,148.1,546.6,145,583.4z"/>
                                    <path fill="#231F20" d="M153.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M135.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="169.3,582.5 151.3,582.5 151.3,452.9 160.3,438.5 169.3,452.9 				" />
                                    <path fill="#BCBEC0" d="M157.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C160.1,552.3,158,582.1,157.9,583.4z"/>
                                    <path fill="#BCBEC0" d="M163,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C164.1,499.1,166.1,546.6,163,583.4z"/>
                                    <path fill="#231F20" d="M171.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M153.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="187.8,582.5 169.8,582.5 169.8,452.9 178.8,438.5 187.8,452.9 				" />
                                    <path fill="#BCBEC0" d="M176.4,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C178.6,552.3,176.5,582.1,176.4,583.4z"/>
                                    <path fill="#BCBEC0" d="M181.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C182.7,499.1,184.6,546.6,181.5,583.4z"/>
                                    <path fill="#231F20" d="M190.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M172.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="206.3,582.5 188.3,582.5 188.3,452.9 197.3,438.5 206.3,452.9 				" />
                                    <path fill="#BCBEC0" d="M194.9,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C197.1,552.3,195,582.1,194.9,583.4z"/>
                                    <path fill="#BCBEC0" d="M200,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C201.2,499.1,203.1,546.6,200,583.4z"/>
                                    <path fill="#231F20" d="M208.8,585h-23V452.2l11.5-18.4l11.5,18.4V585z M190.8,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="224.8,582.5 206.8,582.5 206.8,452.9 215.8,438.5 224.8,452.9 				" />
                                    <path fill="#BCBEC0" d="M213.5,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C215.6,552.3,213.5,582.1,213.5,583.4z"/>
                                    <path fill="#BCBEC0" d="M218.5,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C219.7,499.1,221.6,546.6,218.5,583.4z"/>
                                    <path fill="#231F20" d="M227.3,585h-23V452.2l11.5-18.4l11.5,18.4V585z M209.3,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <polygon fill="#D1D3D4" points="243.1,582.5 225.1,582.5 225.1,452.9 234.1,438.5 243.1,452.9 				" />
                                    <path fill="#BCBEC0" d="M231.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C233.9,552.3,231.8,582.1,231.8,583.4z"/>
                                    <path fill="#BCBEC0" d="M236.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C238,499.1,239.9,546.6,236.8,583.4z"/>
                                    <path fill="#231F20" d="M245.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M227.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="261.6,582.5 243.6,582.5 243.6,452.9 252.6,438.5 261.6,452.9 				" />
                                    <path fill="#BCBEC0" d="M250.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C252.4,552.3,250.3,582.1,250.3,583.4z"/>
                                    <path fill="#BCBEC0" d="M255.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C256.5,499.1,258.4,546.6,255.3,583.4z"/>
                                    <path fill="#231F20" d="M264.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M246.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="280.1,582.5 262.1,582.5 262.1,452.9 271.1,438.5 280.1,452.9 				" />
                                    <path fill="#BCBEC0" d="M268.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C270.9,552.3,268.9,582.1,268.8,583.4z"/>
                                    <path fill="#BCBEC0" d="M273.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C275,499.1,276.9,546.6,273.8,583.4z"/>
                                    <path fill="#231F20" d="M282.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M264.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="298.6,582.5 280.6,582.5 280.6,452.9 289.6,438.5 298.6,452.9 				" />
                                    <path fill="#BCBEC0" d="M287.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C289.4,552.3,287.4,582.1,287.3,583.4z"/>
                                    <path fill="#BCBEC0" d="M292.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C293.5,499.1,295.4,546.6,292.3,583.4z"/>
                                    <path fill="#231F20" d="M301.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M283.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="316.6,582.5 298.6,582.5 298.6,452.9 307.6,438.5 316.6,452.9 				" />
                                    <path fill="#BCBEC0" d="M305.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C307.4,552.3,305.4,582.1,305.3,583.4z"/>
                                    <path fill="#BCBEC0" d="M310.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C311.5,499.1,313.4,546.6,310.3,583.4z"/>
                                    <path fill="#231F20" d="M319.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M301.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="335.1,582.5 317.1,582.5 317.1,452.9 326.1,438.5 335.1,452.9 				" />
                                    <path fill="#BCBEC0" d="M323.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C325.9,552.3,323.9,582.1,323.8,583.4z"/>
                                    <path fill="#BCBEC0" d="M328.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C330,499.1,331.9,546.6,328.8,583.4z"/>
                                    <path fill="#231F20" d="M337.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M319.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="353.6,582.5 335.6,582.5 335.6,452.9 344.6,438.5 353.6,452.9 				" />
                                    <path fill="#BCBEC0" d="M342.3,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C344.4,552.3,342.4,582.1,342.3,583.4z"/>
                                    <path fill="#BCBEC0" d="M347.3,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C348.5,499.1,350.4,546.6,347.3,583.4z"/>
                                    <path fill="#231F20" d="M356.1,585h-23V452.2l11.5-18.4l11.5,18.4V585z M338.1,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="372.1,582.5 354.1,582.5 354.1,452.9 363.1,438.5 372.1,452.9 				" />
                                    <path fill="#BCBEC0" d="M360.8,583.4l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C362.9,552.3,360.9,582.1,360.8,583.4z"/>
                                    <path fill="#BCBEC0" d="M365.8,583.4l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C367,499.1,368.9,546.6,365.8,583.4z"/>
                                    <path fill="#231F20" d="M374.6,585h-23V452.2l11.5-18.4l11.5,18.4V585z M356.6,580h13V453.6l-6.5-10.4l-6.5,10.4V580z" />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <polygon fill="#D1D3D4" points="390.6,582.4 372.6,582.4 372.6,452.8 381.6,438.4 390.6,452.8 				" />
                                    <path fill="#BCBEC0" d="M379.2,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C381.4,552.2,379.3,582.1,379.2,583.3z"/>
                                    <path fill="#BCBEC0" d="M384.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C385.5,499,387.4,546.5,384.3,583.3z"/>
                                    <path fill="#231F20" d="M393.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M375.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="409.1,582.4 391.1,582.4 391.1,452.8 400.1,438.4 409.1,452.8 				" />
                                    <path fill="#BCBEC0" d="M397.7,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C399.9,552.2,397.8,582.1,397.7,583.3z"/>
                                    <path fill="#BCBEC0" d="M402.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C404,499,405.9,546.5,402.8,583.3z"/>
                                    <path fill="#231F20" d="M411.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M393.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="427.6,582.4 409.6,582.4 409.6,452.8 418.6,438.4 427.6,452.8 				" />
                                    <path fill="#BCBEC0" d="M416.3,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C418.4,552.2,416.3,582.1,416.3,583.3z"/>
                                    <path fill="#BCBEC0" d="M421.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C422.5,499,424.4,546.5,421.3,583.3z"/>
                                    <path fill="#231F20" d="M430.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M412.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="446.1,582.4 428.1,582.4 428.1,452.8 437.1,438.4 446.1,452.8 				" />
                                    <path fill="#BCBEC0" d="M434.8,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C436.9,552.2,434.8,582.1,434.8,583.3z"/>
                                    <path fill="#BCBEC0" d="M439.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C441,499,442.9,546.5,439.8,583.3z"/>
                                    <path fill="#231F20" d="M448.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M430.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="464.1,582.4 446.1,582.4 446.1,452.8 455.1,438.4 464.1,452.8 				" />
                                    <path fill="#BCBEC0" d="M452.8,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C454.9,552.2,452.8,582.1,452.8,583.3z"/>
                                    <path fill="#BCBEC0" d="M457.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C459,499,460.9,546.5,457.8,583.3z"/>
                                    <path fill="#231F20" d="M466.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M448.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="482.6,582.4 464.6,582.4 464.6,452.8 473.6,438.4 482.6,452.8 				" />
                                    <path fill="#BCBEC0" d="M471.3,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C473.4,552.2,471.4,582.1,471.3,583.3z"/>
                                    <path fill="#BCBEC0" d="M476.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C477.5,499,479.4,546.5,476.3,583.3z"/>
                                    <path fill="#231F20" d="M485.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M467.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="501.1,582.4 483.1,582.4 483.1,452.8 492.1,438.4 501.1,452.8 				" />
                                    <path fill="#BCBEC0" d="M489.8,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C491.9,552.2,489.9,582.1,489.8,583.3z"/>
                                    <path fill="#BCBEC0" d="M494.8,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C496,499,497.9,546.5,494.8,583.3z"/>
                                    <path fill="#231F20" d="M503.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M485.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="519.6,582.4 501.6,582.4 501.6,452.8 510.6,438.4 519.6,452.8 				" />
                                    <path fill="#BCBEC0" d="M508.3,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C510.4,552.2,508.4,582.1,508.3,583.3z"/>
                                    <path fill="#BCBEC0" d="M513.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C514.5,499,516.4,546.5,513.3,583.3z"/>
                                    <path fill="#231F20" d="M522.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M504.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <polygon fill="#D1D3D4" points="537.9,582.4 519.9,582.4 519.9,452.8 528.9,438.4 537.9,452.8 				" />
                                    <path fill="#BCBEC0" d="M526.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C528.7,552.2,526.7,582.1,526.6,583.3z"/>
                                    <path fill="#BCBEC0" d="M531.6,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C532.8,499,534.7,546.5,531.6,583.3z"/>
                                    <path fill="#231F20" d="M540.4,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M522.4,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="556.4,582.4 538.4,582.4 538.4,452.8 547.4,438.4 556.4,452.8 				" />
                                    <path fill="#BCBEC0" d="M545.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C547.2,552.2,545.2,582.1,545.1,583.3z"/>
                                    <path fill="#BCBEC0" d="M550.1,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C551.3,499,553.2,546.5,550.1,583.3z"/>
                                    <path fill="#231F20" d="M558.9,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M540.9,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="574.9,582.4 556.9,582.4 556.9,452.8 565.9,438.4 574.9,452.8 				" />
                                    <path fill="#BCBEC0" d="M563.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C565.7,552.2,563.7,582.1,563.6,583.3z"/>
                                    <path fill="#BCBEC0" d="M568.6,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C569.8,499,571.7,546.5,568.6,583.3z"/>
                                    <path fill="#231F20" d="M577.4,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M559.4,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="593.4,582.4 575.4,582.4 575.4,452.8 584.4,438.4 593.4,452.8 				" />
                                    <path fill="#BCBEC0" d="M582.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C584.2,552.2,582.2,582.1,582.1,583.3z"/>
                                    <path fill="#BCBEC0" d="M587.1,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C588.3,499,590.2,546.5,587.1,583.3z"/>
                                    <path fill="#231F20" d="M595.9,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M577.9,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="611.4,582.4 593.4,582.4 593.4,452.8 602.4,438.4 611.4,452.8 				" />
                                    <path fill="#BCBEC0" d="M600.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C602.2,552.2,600.2,582.1,600.1,583.3z"/>
                                    <path fill="#BCBEC0" d="M605.1,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C606.3,499,608.2,546.5,605.1,583.3z"/>
                                    <path fill="#231F20" d="M613.9,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M595.9,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="630,582.4 612,582.4 612,452.8 621,438.4 630,452.8 				" />
                                    <path fill="#BCBEC0" d="M618.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C620.7,552.2,618.7,582.1,618.6,583.3z"/>
                                    <path fill="#BCBEC0" d="M623.6,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C624.8,499,626.7,546.5,623.6,583.3z"/>
                                    <path fill="#231F20" d="M632.5,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M614.5,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="648.5,582.4 630.5,582.4 630.5,452.8 639.5,438.4 648.5,452.8 				" />
                                    <path fill="#BCBEC0" d="M637.1,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C639.2,552.2,637.2,582.1,637.1,583.3z"/>
                                    <path fill="#BCBEC0" d="M642.2,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.4c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C643.3,499.1,645.2,546.5,642.2,583.3z"/>
                                    <path fill="#231F20" d="M651,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M633,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="667,582.4 649,582.4 649,452.8 658,438.4 667,452.8 				" />
                                    <path fill="#BCBEC0" d="M655.6,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C657.7,552.2,655.7,582.1,655.6,583.3z"/>
                                    <path fill="#BCBEC0" d="M660.7,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C661.8,499,663.7,546.5,660.7,583.3z"/>
                                    <path fill="#231F20" d="M669.5,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M651.5,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <polygon fill="#D1D3D4" points="685.5,582.4 667.5,582.4 667.5,452.8 676.5,438.4 685.5,452.8 				" />
                                    <path fill="#BCBEC0" d="M674.2,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C676.3,552.2,674.3,582.1,674.2,583.3z"/>
                                    <path fill="#BCBEC0" d="M679.2,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C680.4,499,682.3,546.5,679.2,583.3z"/>
                                    <path fill="#231F20" d="M688,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M670,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="704.1,582.4 686.1,582.4 686.1,452.8 695.1,438.4 704.1,452.8 				" />
                                    <path fill="#BCBEC0" d="M692.7,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C694.8,552.2,692.8,582.1,692.7,583.3z"/>
                                    <path fill="#BCBEC0" d="M697.7,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C698.9,499,700.8,546.5,697.7,583.3z"/>
                                    <path fill="#231F20" d="M706.6,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M688.6,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                                <g>
                                    <polygon fill="#D1D3D4" points="722.6,582.4 704.6,582.4 704.6,452.8 713.6,438.4 722.6,452.8 				" />
                                    <path fill="#BCBEC0" d="M711.2,583.3l-1-0.1c0-0.3,2.1-31,0.7-40.1c-1.4-9.2-0.7-21.2-0.1-23.6c0.4-1.6,2.1-63.2,0.7-77.7l1-0.1
                                c1.2,13-0.1,75.6-0.7,78.1c-0.6,2.1-1.3,14.1,0.1,23.2C713.3,552.2,711.3,582.1,711.2,583.3z"/>
                                    <path fill="#BCBEC0" d="M716.3,583.3l-1-0.1c3.1-36.8,1.2-84.2,0.3-104.5c-0.1-3.3-0.2-6-0.3-7.7c-0.4-12.6,1.4-27.8,1.4-28l1,0.1
                                c0,0.2-1.7,15.3-1.4,27.8c0.1,1.8,0.2,4.4,0.3,7.7C717.4,499,719.3,546.5,716.3,583.3z"/>
                                    <path fill="#231F20" d="M725.1,584.9h-23V452.1l11.5-18.4l11.5,18.4V584.9z M707.1,579.9h13V453.5l-6.5-10.4l-6.5,10.4V579.9z" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <polygon fill="#808285" points="1365.3,331.9 1164.2,331.9 1164.2,0 1325.1,0 1365.3,38.1 " />
                    <path fill="#D1D3D4" d="M1122.3,51.3v-3.2c1.8-1,3-3,3-5.2c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,2.2,1.2,4.2,3,5.2v3.2H1006V5.7h-29.4
                l-16.2,19v26.6l-53.2,0v9.9l3.6,2.1v268.5h270.3v-267l3.2-2V51.3H1122.3z"/>
                    <g>
                        <polyline fill="#FFFFFF" points="944.3,152.8 884,152.8 884,165.9 931.4,165.9 	" />
                        <polygon fill="#231F20" points="931.4,168.4 881.5,168.4 881.5,150.3 944.3,150.3 944.3,155.3 886.5,155.3 886.5,163.4 931.4,163.4 	
                    "/>
                    </g>
                    <g>
                        <polygon fill="#D36866" points="1406.1,318.7 1350.7,264.7 759.5,264.7 704.1,318.7 	" />
                        <path fill="#231F20" d="M1412.2,321.2H697.9l60.6-59h593.2L1412.2,321.2z M710.2,316.2h689.7l-50.3-49H760.5L710.2,316.2z" />
                    </g>
                    <g>
                        <polygon fill="#D1D3D4" points="722.1,345.7 713.1,336.7 713.1,318.7 1397.1,318.7 1397.1,336.7 1388.1,345.7 	" />
                        <path fill="#231F20" d="M1389.1,348.2H721l-10.5-10.5v-21.5h689v21.5L1389.1,348.2z M723.1,343.2H1387l7.5-7.5v-14.5h-679v14.5
                    L723.1,343.2z"/>
                    </g>
                    <g>
                        <rect x="722.1" y="345.7" fill="#FFFFFF" width="666" height="234" />
                        <path fill="#231F20" d="M1390.6,582.2h-671v-239h671V582.2z M724.6,577.2h661v-229h-661V577.2z" />
                    </g>
                    <polygon fill="#231F20" points="913.6,579.7 908.6,579.7 908.6,465.2 841.6,465.2 841.6,579.7 836.6,579.7 836.6,460.2 913.6,460.2 " />
                    <g>
                        <rect x="760.4" y="412.2" fill="#231F20" width="99.7" height="5" />
                        <g>
                            <rect x="760.4" y="384.2" fill="#231F20" width="99.7" height="5" />
                            <rect x="740.4" y="398.2" fill="#231F20" width="106.7" height="5" />
                            <rect x="790.9" y="386.7" fill="#231F20" width="5" height="14" />
                            <rect x="820.9" y="400.7" fill="#231F20" width="5" height="14" />
                            <rect x="767.6" y="400.7" fill="#231F20" width="5" height="14" />
                            <rect x="829.9" y="386.7" fill="#231F20" width="5" height="14" />
                        </g>
                    </g>
                    <g>
                        <rect x="962.8" y="468.2" fill="#231F20" width="99.7" height="5" />
                        <g>
                            <rect x="962.8" y="440.2" fill="#231F20" width="99.7" height="5" />
                            <rect x="942.8" y="454.2" fill="#231F20" width="106.7" height="5" />
                            <rect x="993.3" y="442.7" fill="#231F20" width="5" height="14" />
                            <rect x="1023.3" y="456.7" fill="#231F20" width="5" height="14" />
                            <rect x="969.9" y="456.7" fill="#231F20" width="5" height="14" />
                            <rect x="1032.3" y="442.7" fill="#231F20" width="5" height="14" />
                        </g>
                    </g>
                    <g>
                        <rect x="1276" y="523.6" fill="#231F20" width="99.7" height="5" />
                        <g>
                            <rect x="1276" y="495.6" fill="#231F20" width="99.7" height="5" />
                            <rect x="1256" y="509.6" fill="#231F20" width="106.7" height="5" />
                            <rect x="1306.5" y="498.1" fill="#231F20" width="5" height="14" />
                            <rect x="1336.5" y="512.1" fill="#231F20" width="5" height="14" />
                            <rect x="1283.2" y="512.1" fill="#231F20" width="5" height="14" />
                            <rect x="1345.5" y="498.1" fill="#231F20" width="5" height="14" />
                        </g>
                    </g>
                    <g>
                        <rect x="586.6" y="377.1" fill="#808285" width="29.1" height="246.4" />
                        <path fill="#231F20" d="M618.2,626h-34.1V374.6h34.1V626z M589.1,621h24.1V379.6h-24.1V621z" />
                    </g>
                    <g>
                        <rect x="191.7" y="378.3" fill="#808285" width="29.1" height="246.4" />
                        <path fill="#231F20" d="M223.2,627.2h-34.1V375.8h34.1V627.2z M194.2,622.2h24.1V380.8h-24.1V622.2z" />
                    </g>
                    <path fill="#231F20" d="M1188.3,484.5l-1.7-2.1l-245.8-304l16.2-15.7l299.8,255.2L1188.3,484.5z M947.6,178.7L1188.8,477l60.5-59
                L957.2,169.3L947.6,178.7z"/>
                    <rect x="1083.2" y="117.3" transform="matrix(0.716 -0.6981 0.6981 0.716 91.3557 846.1431)" fill="#231F20" width="5" height="387" />
                    <polygon fill="#231F20" points="1186.1,481.3 1167.2,394.4 1253.6,415.5 1252.4,420.4 1173.8,401.2 1191,480.2 " />
                    <path fill="#231F20" d="M1174.9,402.3l-78.8-32.5l-0.4-1l-30.1-78.6l78.8,32.5L1174.9,402.3z M1100,366l66.1,27.3l-25.6-66.8
                l-66.1-27.3L1100,366z"/>
                    <path fill="#231F20" d="M1077.2,302.1l-72.2-44.6l-0.3-0.5l-42.4-72.8l72.2,44.6l0.3,0.5L1077.2,302.1z M1008.7,253.9l54.1,33.4
                l-32-54.9l-54.1-33.4L1008.7,253.9z"/>
                    <path fill="#231F20" d="M975,197.2l-32.3-16.7l-14.8-15.2l14.6-14.2l0.7-0.7l2.2-0.1l0.8,0.8l13.1,13.4L975,197.2z M945.7,176.5
                l18.3,9.4l-9-18.5l-10.8-11l-9.3,9.1L945.7,176.5z"/>
                    <g>
                        <path fill="#C2C4C6" d="M1310,624.7h-247.5c-18.2,0-33-14.8-33-33v0c0-18.2,14.8-33,33-33H1310c18.2,0,33,14.8,33,33v0
                    C1343,610,1328.2,624.7,1310,624.7z"/>
                        <path fill="#231F20" d="M1310,627.2h-247.5c-19.6,0-35.5-15.9-35.5-35.5s15.9-35.5,35.5-35.5H1310c19.6,0,35.5,15.9,35.5,35.5
                    S1329.6,627.2,1310,627.2z M1062.5,561.2c-16.8,0-30.5,13.7-30.5,30.5s13.7,30.5,30.5,30.5H1310c16.8,0,30.5-13.7,30.5-30.5
                    s-13.7-30.5-30.5-30.5H1062.5z"/>
                    </g>
                    <g>
                        <g>
                            <ellipse fill="#DBDBDB" cx="1062.5" cy="591.2" rx="16.5" ry="15.9" />
                            <path fill="#231F20" d="M1062.5,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1073,609.6,1062.5,609.6z
                        M1062.5,577.7c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1070.2,577.7,1062.5,577.7z"/>
                        </g>
                        <g>
                            <ellipse fill="#DBDBDB" cx="1310" cy="591.2" rx="16.5" ry="15.9" />
                            <path fill="#231F20" d="M1310,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1320.5,609.6,1310,609.6z M1310,577.7
                        c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1317.7,577.7,1310,577.7z"/>
                        </g>
                        <g>
                            <ellipse fill="#DBDBDB" cx="1260.5" cy="591.2" rx="16.5" ry="15.9" />
                            <path fill="#231F20" d="M1260.5,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1271,609.6,1260.5,609.6z
                        M1260.5,577.7c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1268.2,577.7,1260.5,577.7z"/>
                        </g>
                        <g>
                            <ellipse fill="#DBDBDB" cx="1211" cy="591.2" rx="16.5" ry="15.9" />
                            <path fill="#231F20" d="M1211,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1221.5,609.6,1211,609.6z M1211,577.7
                        c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1218.7,577.7,1211,577.7z"/>
                        </g>
                        <g>
                            <ellipse fill="#DBDBDB" cx="1161.5" cy="591.2" rx="16.5" ry="15.9" />
                            <path fill="#231F20" d="M1161.5,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1172,609.6,1161.5,609.6z
                        M1161.5,577.7c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1169.2,577.7,1161.5,577.7z"/>
                        </g>
                        <g>
                            <ellipse fill="#DBDBDB" cx="1112" cy="591.2" rx="16.5" ry="15.9" />
                            <path fill="#231F20" d="M1112,609.6c-10.5,0-19-8.3-19-18.4s8.5-18.4,19-18.4s19,8.3,19,18.4S1122.5,609.6,1112,609.6z M1112,577.7
                        c-7.7,0-14,6-14,13.4s6.3,13.4,14,13.4s14-6,14-13.4S1119.7,577.7,1112,577.7z"/>
                        </g>
                    </g>
                    <g>
                        <polygon fill="#BCBEC0" points="1310,534 1310,451.5 1285.3,426.7 1219.3,426.7 1219.3,534 	" />
                        <path fill="#231F20" d="M1312.5,536.5h-95.8V424.2h69.5l26.2,26.2V536.5z M1221.8,531.5h85.8v-79l-23.3-23.3h-62.5V531.5z" />
                    </g>
                    <g>
                        <rect x="1219.3" y="534" fill="#808285" width="90.8" height="24.8" />
                        <path fill="#231F20" d="M1312.5,561.2h-95.8v-29.8h95.8V561.2z M1221.8,556.2h85.8v-19.8h-85.8V556.2z" />
                    </g>
                    <g>
                        <polygon fill="#808285" points="1070.8,558.7 1046,534 1219.3,534 1219.3,558.7 	" />
                        <path fill="#231F20" d="M1221.8,561.2h-152l-29.8-29.8h181.8V561.2z M1071.8,556.2h145v-19.8H1052L1071.8,556.2z" />
                    </g>
                    <g>
                        <polygon fill="#FFFFFF" points="1046,534 1037.8,525.7 1037.8,509.2 1079,426.7 1219.3,426.7 1219.3,534 	" />
                        <path fill="#231F20" d="M1221.8,536.5H1045l-9.7-9.7v-18.1l0.3-0.5l41.9-83.9h144.3V536.5z M1047,531.5h169.7V429.2h-136.2l-40.3,80.6
                    v14.9L1047,531.5z"/>
                    </g>
                    <path fill="#231F20" d="M1131,511.7h-80.8l35.5-71h45.3V511.7z M1058.3,506.7h67.7v-61h-37.2L1058.3,506.7z" />
                    <g>
                        <polygon fill="#D1D3D4" points="1109,426.7 1109,417.7 1118,408.7 1253,408.7 1262,417.7 1262,426.7 	" />
                        <path fill="#231F20" d="M1264.5,429.2h-158v-12.5l10.5-10.5H1254l10.5,10.5V429.2z M1111.5,424.2h148v-5.5l-7.5-7.5H1119l-7.5,7.5
                    V424.2z"/>
                    </g>
                    <Swinger id="swinger">
                        <polygon fill="#D36866" points="734,352.7 1022,352.7 1022,361.7 1013,361.7 1013,379.7 1022,379.7 1022,388.7 806,388.7 734,388.7 
                    734,379.7 743,379.7 743,361.7 734,361.7 	"/>
                        <path fill="#231F20" d="M1024.5,364.2v-14h-63.7l-72.4-117.7L816,350.2h-84.5v14h9v13h-9v14h293v-14h-9v-13H1024.5z M745.5,364.2h70.2
                    v13h-70.2V364.2z M820.7,364.2h135.4v13H820.7V364.2z M956.1,382.2v4H820.7v-4H956.1z M1010.5,377.2h-49.4v-13h49.4V377.2z
                    M820.7,359.2v-4h135.4v4H820.7z M888.4,242.1L955,350.2H821.9L888.4,242.1z M736.5,359.2v-4h79.2v4h-70.2H743H736.5z M736.5,382.2
                    h6.5h2.5h70.2v4h-79.2V382.2z M1019.5,382.2v4h-58.4v-4h49.4h2.5H1019.5z M1013,359.2h-2.5h-49.4v-4h58.4v4H1013z"/>
                    </Swinger>
                    <g>
                        <g>
                            <rect x="151.7" y="165.3" fill="#FFFFFF" width="504" height="180" />
                            <path fill="#231F20" d="M658.2,347.8h-509v-185h509V347.8z M154.2,342.8h499v-175h-499V342.8z" />
                        </g>
                        <g>
                            <g>
                                <rect x="151.7" y="132.7" fill="#BCBEC0" width="504" height="33" />
                                <path fill="#231F20" d="M658.2,168.2h-509v-38h509V168.2z M154.2,163.2h499v-28h-499V163.2z" />
                            </g>
                            <polygon fill="#231F20" points="654.6,168 583.8,136.6 511.7,166.8 439.8,136.6 367.7,166.8 366.7,166.4 295.8,136.6 223.6,166.8 
                        150.7,135 152.7,130.4 223.7,161.3 295.8,131.2 296.8,131.6 367.7,161.4 439.8,131.2 511.7,161.4 583.9,131.2 656.7,163.4 		"/>
                            <polygon fill="#231F20" points="152.7,168 150.6,163.4 223.6,131 295.7,161.4 367.7,131 439.7,161.4 511.7,131 583.6,161.3 
                        654.7,130.4 656.7,135 583.7,166.8 511.7,136.4 439.7,166.8 367.7,136.4 295.7,166.8 223.7,136.5 		"/>
                        </g>
                        <g>
                            <g>
                                <rect x="151.7" y="345.3" fill="#BCBEC0" width="504" height="33" />
                                <path fill="#231F20" d="M658.2,380.8h-509v-38h509V380.8z M154.2,375.8h499v-28h-499V375.8z" />
                            </g>
                            <polygon fill="#231F20" points="654.6,380.6 583.8,349.2 511.7,379.4 439.8,349.2 367.7,379.4 366.7,379 295.8,349.2 223.6,379.4 
                        150.7,347.6 152.7,343 223.7,373.9 295.8,343.8 296.8,344.2 367.7,373.9 439.8,343.8 511.7,373.9 583.9,343.8 656.7,376 		"/>
                            <polygon fill="#231F20" points="152.7,380.6 150.6,376 223.6,343.6 295.7,373.9 367.7,343.6 439.7,373.9 511.7,343.6 583.6,373.9 
                        654.7,343 656.7,347.6 583.7,379.4 511.7,349 439.7,379.4 367.7,349 295.7,379.4 223.7,349 		"/>
                        </g>
                    </g>
                    <path fill="#231F20" d="M1120.5,511.7h-23v-15.9c0-9.4,3.7-18.3,10.3-24.9l4.1-4.1c1.4-1.4,3.6-1.9,5.5-1.1c1.9,0.8,3.1,2.6,3.1,4.6
                V511.7z M1102.5,506.7h13v-36.4l-4.1,4.1c-5.7,5.7-8.9,13.3-8.9,21.4V506.7z"/>
                    <g>
                        <g>
                            <g>
                                <rect x="403.4" y="556.7" fill="#BCBEC0" width="60.7" height="60.7" />
                                <path fill="#A7A9AC" d="M418.2,619.2c0-0.2-3.4-20-4.1-27.1c-0.8-7.2,3.9-37.1,4.1-38.4l1,0.2c0,0.3-4.9,31.1-4.1,38.1
                            c0.8,7.1,4.1,26.8,4.1,27L418.2,619.2z"/>
                                <path fill="#A7A9AC" d="M426.6,619.1c-0.2-1.3-4.5-31.3,0.8-43.3c5.2-11.6-1.3-21-1.4-21.1l0.8-0.6c0.1,0.1,6.9,9.9,1.5,22.1
                            c-5.2,11.8-0.8,42.5-0.7,42.8L426.6,619.1z"/>
                                <path fill="#A7A9AC" d="M452.9,620.5c-0.2-1.4-4.6-33.5,0.2-44c4.7-10.1-2-22.3-2.1-22.5l0.9-0.5c0.3,0.5,7.1,12.8,2.1,23.4
                            c-4.8,10.2-0.2,43.1-0.2,43.4L452.9,620.5z"/>
                                <path fill="#231F20" d="M466.3,619.6h-65.2v-65.2h65.2V619.6z M405.7,615h56.1V559h-56.1V615z" />
                                <path fill="#A7A9AC" d="M438.9,618c0-0.2-5-15.6-1.9-28c3-12.1,1.5-33.2,1.5-33.4l1-0.1c0,0.2,1.5,21.4-1.5,33.7
                            c-3,12.1,1.8,27.3,1.9,27.4L438.9,618z"/>
                            </g>

                            <rect x="390.1" y="579.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -287.769 477.9242)" fill="#BCBEC0" width="85.8" height="13.5" />
                            <path fill="#A7A9AC" d="M399.3,616.7l-0.4-0.9c0.1-0.1,13.4-5.5,17.2-12.4c3.7-6.7,11.3-18,17.3-21.8c5.8-3.7,18.3-16.6,19.5-19.6
                        l0.9,0.4c-1.4,3.5-14.2,16.5-19.8,20.1c-5.9,3.8-13.4,14.9-17,21.5C413,611.1,399.8,616.5,399.3,616.7z"/>
                            <path fill="#A7A9AC" d="M406.9,617.6l-0.5-0.8c0.1-0.1,13.9-8.7,16.3-15.5c2.5-7.2,11.2-14.7,13.6-16.1c2.4-1.4,22.5-17.4,24.4-21.2
                        l0.9,0.5c-2.1,4-22.4,20.1-24.8,21.6c-2.2,1.3-10.7,8.7-13.1,15.6C421.1,608.7,407.5,617.3,406.9,617.6z"/>
                            <path fill="#231F20" d="M407.5,624.7l-12.8-12.8l63.9-63.9l12.8,12.8L407.5,624.7z M401.2,611.9l6.3,6.3l57.4-57.4l-6.3-6.3
                        L401.2,611.9z"/>
                            <rect x="396.6" y="549.9" fill="#808285" width="74.1" height="12.4" />
                            <g>
                                <path fill="#A7A9AC" d="M397,559.6l-0.3-0.9c0.4-0.2,10.9-3.7,18.2-3.7c7.5,0,40-1.7,47.5-5.5l0.4,0.9c-7.7,3.8-40.4,5.6-48,5.6
                            C407.7,556,397.1,559.5,397,559.6z"/>
                                <path fill="#A7A9AC" d="M396.9,555l-0.1-1c0.4-0.1,8.8-1.2,12.2-0.8c3.5,0.4,19.9-3.1,20.8-3.7l0.5,0.8c-1.2,0.8-18.1,4.2-21.5,3.8
                            C405.5,553.8,396.9,555,396.9,555z"/>
                                <path fill="#A7A9AC" d="M403,562.8c-0.1,0-0.1,0-0.2,0l0-1c2.8,0.1,7.5-0.9,13-2c7.2-1.4,15.4-3,21.9-3c11.4,0.1,27.7,4.9,27.8,5
                            l-0.3,1c-0.2,0-16.4-4.8-27.6-4.9c-6.4-0.1-14.5,1.5-21.7,3C410.6,561.9,405.9,562.8,403,562.8z"/>
                                <path fill="#A7A9AC" d="M463,558.5c-1.8,0-3.2-1.2-3.2-2.6s1.5-2.6,3.2-2.6s3.2,1.2,3.2,2.6S464.8,558.5,463,558.5z M463,554.3
                            c-1.2,0-2.2,0.7-2.2,1.6s1,1.6,2.2,1.6c1.2,0,2.2-0.7,2.2-1.6S464.2,554.3,463,554.3z"/>
                                <ellipse fill="#A7A9AC" cx="463.4" cy="556.2" rx="1.1" ry="0.8" />
                            </g>
                            <path fill="#231F20" d="M473,564.6h-78.7v-16.9H473V564.6z M398.9,560h69.6v-7.8h-69.6V560z" />
                            <rect x="396.6" y="611.7" fill="#808285" width="74.1" height="12.4" />
                            <path fill="#A7A9AC" d="M467,625c-2.3,0-4.9-0.9-7.7-2.8c-9.4-6.1-27.7-6.5-34.2-3.6c-6.9,3.2-28.8-1.7-29.8-1.9l0.2-1
                        c0.2,0,22.5,5,29.1,1.9c6.8-3.1,25.3-2.7,35.1,3.6c3.5,2.2,6.4,3.1,8.8,2.4c2.2-0.6,3.1-2.2,3.1-2.2l0.9,0.5c0,0.1-1.1,2-3.7,2.7
                        C468.3,624.9,467.7,625,467,625z"/>
                            <path fill="#A7A9AC" d="M470.7,618.4c-9.7-1-11.9-3-13.7-4.7c-1.2-1.1-2.1-1.9-5-2.1c-5.5-0.5-17.9,1.9-26.2,3.4
                        c-2.9,0.5-5.1,1-6.3,1.1c-4.6,0.6-22.1-3.8-22.8-4l0.2-1c0.2,0,18,4.5,22.4,3.9c1.2-0.2,3.4-0.6,6.3-1.1
                        c8.3-1.6,20.8-3.9,26.4-3.5c3.2,0.3,4.3,1.3,5.6,2.4c1.7,1.5,3.8,3.5,13.2,4.4L470.7,618.4z"/>
                            <path fill="#A7A9AC" d="M457.1,625.7c-0.1-0.1-13.3-5.3-23.3-3.5c-10.2,1.8-37.2-1.3-38.4-1.4l0.1-1c0.3,0,28,3.2,38.1,1.4
                        c10.3-1.8,23.3,3.3,23.9,3.5L457.1,625.7z"/>
                            <path fill="#231F20" d="M473,626.4h-78.7v-16.9H473V626.4z M398.9,621.8h69.6V614h-69.6V621.8z" />

                            <rect x="439.3" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -122.9819 1051.027)" fill="#808285" width="49.4" height="13.5" />
                            <path fill="#A7A9AC" d="M465.6,612.7l-3.7-8.8c-3.7-8.8-2.2-41.5-2.1-42.9l1,0c0,0.3-1.6,33.9,2.1,42.5l3.7,8.8L465.6,612.7z" />
                            <path fill="#A7A9AC" d="M468.9,612l-0.9-0.5c0.9-1.5-0.6-9.3-4.5-23.1c-2.4-8.5-0.1-25.4,0-26.1l1,0.1c0,0.2-2.3,17.4,0,25.7
                        C468.7,602.9,470,610.1,468.9,612z"/>
                            <path fill="#A7A9AC" d="M469.2,587.3c-5.5-8.7-3.2-24.4-3.1-25.1l1,0.2c0,0.2-2.4,15.9,3,24.4L469.2,587.3z" />
                            <path fill="#231F20" d="M473,614H455v-54H473V614z M459.6,609.4h8.9v-44.8h-8.9V609.4z" />

                            <rect x="378.7" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -183.6359 990.373)" fill="#808285" width="49.4" height="13.5" />
                            <path fill="#A7A9AC" d="M396,608.5l-1-0.3c0-0.1,2.9-9.1,5-20.4c2.1-11.2-3.6-27.5-3.7-27.6l0.9-0.3c0.2,0.7,5.9,16.7,3.7,28.2
                        C398.9,599.3,396,608.4,396,608.5z"/>
                            <path fill="#A7A9AC" d="M410,595.8c-0.5,0-1-0.8-4.7-10.3c-4.2-11.1-4.7-23.1-4.7-23.2l1,0c0,0.1,0.5,11.9,4.7,22.9
                        c3.3,8.6,4,9.6,4.1,9.8l-0.3,0.3l0,0.1h0.5l-0.4,0.5C410.1,595.8,410.1,595.8,410,595.8z"/>
                            <path fill="#A7A9AC" d="M396.9,612.2l-0.2-1c6.1-1,9.4-6.1,11.2-10.2c3.5-8.5,3.1-18.8,1.8-20.6c-2.3-3.3-4.5-17.6-4.6-18.2l1-0.1
                        c0,0.1,2.2,14.7,4.4,17.8c1.5,2.1,1.9,13-1.7,21.6C406.2,607.6,402.1,611.4,396.9,612.2z"/>
                            <path fill="#231F20" d="M412.4,614h-18.1v-54h18.1V614z M398.9,609.4h8.9v-44.8h-8.9V609.4z" />
                        </g>
                        <g>
                            <g>
                                <rect x="500.7" y="556.7" fill="#BCBEC0" width="60.7" height="60.7" />
                                <path fill="#A7A9AC" d="M515.5,619.2c0-0.2-3.4-20-4.1-27.1c-0.8-7.2,3.9-37.1,4.1-38.4l1,0.2c0,0.3-4.9,31.1-4.1,38.1
                            c0.8,7.1,4.1,26.8,4.1,27L515.5,619.2z"/>
                                <path fill="#A7A9AC" d="M523.9,619.1c-0.2-1.3-4.5-31.3,0.8-43.3c5.2-11.6-1.3-21-1.4-21.1l0.8-0.6c0.1,0.1,6.9,9.9,1.5,22.1
                            c-5.2,11.8-0.8,42.5-0.7,42.8L523.9,619.1z"/>
                                <path fill="#A7A9AC" d="M550.2,620.5c-0.2-1.4-4.6-33.5,0.2-44c4.7-10.1-2-22.3-2.1-22.5l0.9-0.5c0.3,0.5,7.1,12.8,2.1,23.4
                            c-4.8,10.2-0.2,43.1-0.2,43.4L550.2,620.5z"/>
                                <path fill="#231F20" d="M563.6,619.6h-65.2v-65.2h65.2V619.6z M503,615h56.1V559H503V615z" />
                                <path fill="#A7A9AC" d="M536.2,618c0-0.2-5-15.6-1.9-28c3-12.1,1.5-33.2,1.5-33.4l1-0.1c0,0.2,1.5,21.4-1.5,33.7
                            c-3,12.1,1.8,27.3,1.9,27.4L536.2,618z"/>
                            </g>

                            <rect x="487.4" y="579.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -259.2653 546.7381)" fill="#BCBEC0" width="85.8" height="13.5" />
                            <path fill="#A7A9AC" d="M496.6,616.7l-0.4-0.9c0.1-0.1,13.4-5.5,17.2-12.4c3.7-6.7,11.3-18,17.3-21.8c5.8-3.7,18.3-16.6,19.5-19.6
                        l0.9,0.4c-1.4,3.5-14.2,16.5-19.8,20.1c-5.9,3.8-13.4,14.9-17,21.5C510.4,611.1,497.1,616.5,496.6,616.7z"/>
                            <path fill="#A7A9AC" d="M504.2,617.6l-0.5-0.8c0.1-0.1,13.9-8.7,16.3-15.5c2.5-7.2,11.2-14.7,13.6-16.1c2.4-1.4,22.5-17.4,24.4-21.2
                        l0.9,0.5c-2.1,4-22.4,20.1-24.8,21.6c-2.2,1.3-10.7,8.7-13.1,15.6C518.4,608.7,504.8,617.3,504.2,617.6z"/>
                            <path fill="#231F20" d="M504.8,624.7L492,611.9l63.9-63.9l12.8,12.8L504.8,624.7z M498.5,611.9l6.3,6.3l57.4-57.4l-6.3-6.3
                        L498.5,611.9z"/>
                            <rect x="493.9" y="549.9" fill="#808285" width="74.1" height="12.4" />
                            <g>
                                <path fill="#A7A9AC" d="M494.3,559.6l-0.3-0.9c0.4-0.2,10.9-3.7,18.2-3.7c7.5,0,40-1.7,47.5-5.5l0.4,0.9c-7.7,3.8-40.4,5.6-48,5.6
                            C505,556,494.4,559.5,494.3,559.6z"/>
                                <path fill="#A7A9AC" d="M494.2,555l-0.1-1c0.4-0.1,8.8-1.2,12.2-0.8c3.5,0.4,19.9-3.1,20.8-3.7l0.5,0.8c-1.2,0.8-18.1,4.2-21.5,3.8
                            C502.9,553.8,494.3,555,494.2,555z"/>
                                <path fill="#A7A9AC" d="M500.3,562.8c-0.1,0-0.1,0-0.2,0l0-1c2.8,0.1,7.5-0.9,13-2c7.1-1.4,15.1-3,21.6-3c0.1,0,0.2,0,0.3,0
                            c11.4,0.1,27.7,4.9,27.8,5l-0.3,1c-0.2,0-16.4-4.8-27.6-4.9c-6.4-0.1-14.5,1.5-21.7,3C507.9,561.9,503.2,562.8,500.3,562.8z"/>
                                <path fill="#A7A9AC" d="M560.3,558.5c-1.8,0-3.2-1.2-3.2-2.6s1.5-2.6,3.2-2.6s3.2,1.2,3.2,2.6S562.1,558.5,560.3,558.5z
                            M560.3,554.3c-1.2,0-2.2,0.7-2.2,1.6s1,1.6,2.2,1.6c1.2,0,2.2-0.7,2.2-1.6S561.5,554.3,560.3,554.3z"/>
                                <ellipse fill="#A7A9AC" cx="560.8" cy="556.2" rx="1.1" ry="0.8" />
                            </g>
                            <path fill="#231F20" d="M570.4,564.6h-78.7v-16.9h78.7V564.6z M496.2,560h69.6v-7.8h-69.6V560z" />
                            <rect x="493.9" y="611.7" fill="#808285" width="74.1" height="12.4" />
                            <path fill="#A7A9AC" d="M564.3,625c-2.3,0-4.9-0.9-7.7-2.8c-9.4-6.1-27.7-6.5-34.2-3.6c-6.9,3.2-28.8-1.7-29.8-1.9l0.2-1
                        c0.2,0,22.5,5,29.1,1.9c6.8-3.1,25.3-2.7,35.1,3.6c3.5,2.2,6.4,3,8.8,2.4c2.2-0.6,3.1-2.2,3.1-2.2l0.9,0.5c0,0.1-1.1,2-3.7,2.7
                        C565.6,624.9,565,625,564.3,625z"/>
                            <path fill="#A7A9AC" d="M568,618.4c-9.7-1-11.9-3-13.7-4.7c-1.2-1.1-2.1-1.9-5-2.1c-5.5-0.5-17.9,1.9-26.2,3.4
                        c-2.9,0.5-5.1,1-6.3,1.1c-4.6,0.6-22.1-3.8-22.8-4l0.2-1c0.2,0,18,4.5,22.4,3.9c1.2-0.2,3.4-0.6,6.3-1.1
                        c8.3-1.6,20.8-3.9,26.4-3.5c3.2,0.3,4.3,1.3,5.6,2.4c1.7,1.5,3.8,3.5,13.2,4.4L568,618.4z"/>
                            <path fill="#A7A9AC" d="M554.4,625.7c-0.1-0.1-13.3-5.3-23.3-3.5c-10.2,1.8-37.2-1.3-38.4-1.4l0.1-1c0.3,0,28,3.2,38.1,1.4
                        c10.3-1.8,23.3,3.3,23.9,3.5L554.4,625.7z"/>
                            <path fill="#231F20" d="M570.4,626.4h-78.7v-16.9h78.7V626.4z M496.2,621.8h69.6V614h-69.6V621.8z" />

                            <rect x="536.6" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -25.6643 1148.3446)" fill="#808285" width="49.4" height="13.5" />
                            <path fill="#A7A9AC" d="M563,612.7l-3.7-8.8c-3.7-8.8-2.2-41.5-2.1-42.9l1,0c0,0.3-1.6,33.9,2.1,42.5l3.7,8.8L563,612.7z" />
                            <path fill="#A7A9AC" d="M566.2,612l-0.9-0.5c1.3-2.3-3.1-18-4.5-23.1c-2.4-8.5-0.1-25.4,0-26.1l1,0.1c0,0.2-2.3,17.4,0,25.7
                        C566,602.9,567.3,610.1,566.2,612z"/>
                            <path fill="#A7A9AC" d="M566.5,587.3c-5.5-8.7-3.2-24.4-3.1-25.1l1,0.2c0,0.2-2.4,15.9,3,24.4L566.5,587.3z" />
                            <path fill="#231F20" d="M570.4,614h-18.1v-54h18.1V614z M556.9,609.4h8.9v-44.8h-8.9V609.4z" />

                            <rect x="476" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -86.3183 1087.6906)" fill="#808285" width="49.4" height="13.5" />
                            <path fill="#A7A9AC" d="M493.3,608.5l-1-0.3c0-0.1,2.9-9.1,5-20.4c2.1-11.2-3.6-27.5-3.7-27.6l0.9-0.3c0.2,0.7,5.9,16.7,3.7,28.2
                        C496.2,599.3,493.3,608.4,493.3,608.5z"/>
                            <path fill="#A7A9AC" d="M507.4,595.8c-0.5,0-1-0.8-4.7-10.3c-4.2-11.1-4.7-23.1-4.7-23.2l1,0c0,0.1,0.5,11.9,4.7,22.9
                        c3.3,8.6,4,9.6,4.1,9.8l-0.3,0.3l0,0.1h0.5l-0.4,0.5C507.4,595.8,507.4,595.8,507.4,595.8z"/>
                            <path fill="#A7A9AC" d="M494.2,612.2l-0.2-1c6.1-1,9.4-6.1,11.2-10.2c3.5-8.5,3.1-18.8,1.8-20.6c-2.3-3.3-4.5-17.6-4.6-18.2l1-0.1
                        c0,0.1,2.2,14.7,4.4,17.8c1.5,2.1,1.9,13-1.7,21.6C503.5,607.6,499.4,611.4,494.2,612.2z"/>
                            <path fill="#231F20" d="M509.7,614h-18.1v-54h18.1V614z M496.2,609.4h8.9v-44.8h-8.9V609.4z" />
                        </g>
                        <g>
                            <g>
                                <rect x="462.2" y="480.3" fill="#BCBEC0" width="60.7" height="60.7" />
                                <path fill="#A7A9AC" d="M477,542.8c0-0.2-3.4-20-4.1-27.1c-0.8-7.2,3.9-37.1,4.1-38.4l1,0.2c0,0.3-4.9,31.1-4.1,38.1
                            c0.8,7.1,4.1,26.8,4.1,27L477,542.8z"/>
                                <path fill="#A7A9AC" d="M485.4,542.8c-0.2-1.3-4.5-31.3,0.8-43.3c5.2-11.7-1.3-21-1.4-21.1l0.8-0.6c0.1,0.1,6.9,9.9,1.5,22.1
                            c-5.2,11.8-0.8,42.5-0.7,42.8L485.4,542.8z"/>
                                <path fill="#A7A9AC" d="M511.7,544.1c-0.2-1.4-4.6-33.5,0.2-44c4.7-10.1-2-22.3-2.1-22.5l0.9-0.5c0.3,0.5,7.1,12.8,2.1,23.4
                            c-4.8,10.2-0.2,43.1-0.2,43.4L511.7,544.1z"/>
                                <path fill="#231F20" d="M525.1,543.3h-65.2V478h65.2V543.3z M464.5,538.7h56.1v-56.1h-56.1V538.7z" />
                                <path fill="#A7A9AC" d="M497.7,541.7c0-0.2-5-15.6-1.9-28c3-12.1,1.5-33.2,1.5-33.4l1-0.1c0,0.2,1.5,21.4-1.5,33.7
                            c-3,12.1,1.8,27.3,1.9,27.4L497.7,541.7z"/>
                            </g>

                            <rect x="449" y="503.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -216.5453 497.1539)" fill="#BCBEC0" width="85.8" height="13.5" />
                            <path fill="#A7A9AC" d="M458.1,540.4l-0.4-0.9c0.1-0.1,13.4-5.5,17.2-12.4c3.7-6.7,11.3-18,17.3-21.8c5.8-3.7,18.3-16.6,19.5-19.6
                        l0.9,0.4c-1.4,3.5-14.2,16.5-19.8,20.1c-5.9,3.8-13.4,14.9-17,21.5C471.9,534.7,458.7,540.2,458.1,540.4z"/>
                            <path fill="#A7A9AC" d="M465.7,541.3l-0.5-0.8c0.1-0.1,13.9-8.7,16.3-15.5c2.5-7.2,11.2-14.7,13.6-16.1c2.4-1.4,22.5-17.4,24.4-21.2
                        l0.9,0.5c-2.1,4-22.4,20.1-24.8,21.6c-2.2,1.3-10.7,8.7-13.1,15.6C479.9,532.4,466.3,540.9,465.7,541.3z"/>
                            <path fill="#231F20" d="M466.3,548.3l-12.8-12.8l63.9-63.9l12.8,12.8L466.3,548.3z M460,535.5l6.3,6.3l57.4-57.4l-6.3-6.3L460,535.5z
                        "/>
                            <rect x="455.5" y="473.6" fill="#808285" width="74.1" height="12.4" />
                            <g>
                                <path fill="#A7A9AC" d="M455.8,483.2l-0.3-0.9c0.4-0.2,10.9-3.7,18.2-3.7c7.5,0,40-1.7,47.5-5.5l0.4,0.9c-7.7,3.8-40.4,5.6-48,5.6
                            C466.5,479.6,455.9,483.2,455.8,483.2z"/>
                                <path fill="#A7A9AC" d="M455.7,478.7l-0.1-1c0.4,0,8.8-1.2,12.2-0.8c3.5,0.4,19.9-3.1,20.8-3.7l0.5,0.8c-1.2,0.8-18.1,4.2-21.5,3.8
                            C464.4,477.5,455.8,478.6,455.7,478.7z"/>
                                <path fill="#A7A9AC" d="M461.8,486.4c-0.1,0-0.1,0-0.2,0l0-1c2.8,0.1,7.5-0.9,13-2c7.2-1.4,15.4-3,21.9-3c11.4,0.1,27.7,4.9,27.8,5
                            l-0.3,1c-0.2,0-16.3-4.8-27.6-4.9c-6.4-0.1-14.5,1.5-21.7,3C469.4,485.5,464.7,486.4,461.8,486.4z"/>
                                <path fill="#A7A9AC" d="M521.8,482.2c-1.8,0-3.2-1.2-3.2-2.6c0-1.5,1.5-2.6,3.2-2.6s3.2,1.2,3.2,2.6
                            C525.1,481,523.6,482.2,521.8,482.2z M521.8,477.9c-1.2,0-2.2,0.7-2.2,1.6s1,1.6,2.2,1.6c1.2,0,2.2-0.7,2.2-1.6
                            S523.1,477.9,521.8,477.9z"/>
                                <ellipse fill="#A7A9AC" cx="522.3" cy="479.9" rx="1.1" ry="0.8" />
                            </g>
                            <path fill="#231F20" d="M531.9,488.2h-78.7v-16.9h78.7V488.2z M457.7,483.6h69.6v-7.8h-69.6V483.6z" />
                            <rect x="455.5" y="535.4" fill="#808285" width="74.1" height="12.4" />
                            <path fill="#A7A9AC" d="M525.9,548.6c-2.3,0-4.9-0.9-7.7-2.8c-9.4-6.1-27.7-6.5-34.2-3.6c-6.9,3.2-28.8-1.7-29.8-1.9l0.2-1
                        c0.2,0,22.5,5,29.1,1.9c6.8-3.1,25.3-2.7,35.1,3.6c3.5,2.2,6.4,3.1,8.8,2.4c2.2-0.6,3.1-2.2,3.1-2.2l0.9,0.5c0,0.1-1.1,2-3.7,2.7
                        C527.1,548.6,526.5,548.6,525.9,548.6z"/>
                            <path fill="#A7A9AC" d="M529.5,542c-9.7-1-11.9-3-13.7-4.7c-1.2-1.1-2.1-1.9-5-2.1c-5.5-0.5-17.9,1.9-26.2,3.4
                        c-2.9,0.5-5.1,1-6.3,1.1c-4.6,0.6-22.1-3.8-22.8-4l0.2-1c0.2,0,18,4.5,22.4,3.9c1.2-0.2,3.4-0.6,6.3-1.1
                        c8.3-1.6,20.8-3.9,26.4-3.5c3.2,0.3,4.3,1.3,5.6,2.4c1.7,1.5,3.8,3.5,13.2,4.4L529.5,542z"/>
                            <path fill="#A7A9AC" d="M515.9,549.4c-0.1-0.1-13.3-5.3-23.3-3.5c-10.2,1.8-37.2-1.3-38.4-1.4l0.1-1c0.3,0,28,3.2,38.1,1.4
                        c10.3-1.8,23.3,3.3,23.9,3.5L515.9,549.4z"/>
                            <path fill="#231F20" d="M531.9,550h-78.7v-16.9h78.7V550z M457.7,545.4h69.6v-7.8h-69.6V545.4z" />

                            <rect x="498.1" y="503.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 12.2019 1033.4915)" fill="#808285" width="49.4" height="13.5" />
                            <path fill="#A7A9AC" d="M524.5,536.3l-3.7-8.8c-3.7-8.8-2.2-41.5-2.1-42.9l1,0c0,0.3-1.6,33.9,2.1,42.5l3.7,8.8L524.5,536.3z" />
                            <path fill="#A7A9AC" d="M527.7,535.6l-0.9-0.5c0.9-1.5-0.6-9.3-4.5-23.1c-2.4-8.5-0.1-25.4,0-26.1l1,0.1c0,0.2-2.3,17.4,0,25.7
                        C527.5,526.6,528.8,533.7,527.7,535.6z"/>
                            <path fill="#A7A9AC" d="M528,510.9c-5.5-8.7-3.2-24.4-3.1-25.1l1,0.2c0,0.2-2.4,15.9,3,24.4L528,510.9z" />
                            <path fill="#231F20" d="M531.9,537.6h-18.1v-54h18.1V537.6z M518.4,533.1h8.9v-44.8h-8.9V533.1z" />

                            <rect x="437.5" y="503.9" transform="matrix(-3.724226e-14 -1 1 -3.724226e-14 -48.4521 972.8374)" fill="#808285" width="49.4" height="13.5" />
                            <path fill="#A7A9AC" d="M454.8,532.1l-1-0.3c0-0.1,2.9-9.1,5-20.4c2.1-11.2-3.6-27.5-3.7-27.6l0.9-0.3c0.2,0.7,5.9,16.7,3.7,28.2
                        C457.7,523,454.8,532.1,454.8,532.1z"/>
                            <path fill="#A7A9AC" d="M468.9,519.4c-0.5,0-1-0.8-4.7-10.3c-4.2-11.1-4.7-23.1-4.7-23.2l1,0c0,0.1,0.5,11.9,4.7,22.9
                        c3.3,8.6,4,9.6,4.1,9.8l-0.3,0.3l0,0l0.5,0l-0.4,0.5C469,519.4,468.9,519.4,468.9,519.4z"/>
                            <path fill="#A7A9AC" d="M455.7,535.8l-0.2-1c6.1-1,9.4-6.1,11.2-10.2c3.5-8.5,3.1-18.8,1.8-20.6c-2.3-3.3-4.5-17.6-4.6-18.2l1-0.1
                        c0,0.1,2.2,14.7,4.4,17.8c1.5,2.1,1.9,13-1.7,21.6C465,531.3,460.9,535,455.7,535.8z"/>
                            <path fill="#231F20" d="M471.2,537.6h-18.1v-54h18.1V537.6z M457.7,533.1h8.9v-44.8h-8.9V533.1z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect x="1368.8" y="556.7" fill="#BCBEC0" width="60.7" height="60.7" />
                            <path fill="#A7A9AC" d="M1383.6,619.2c0-0.2-3.4-20-4.1-27.1c-0.8-7.2,3.9-37.1,4.1-38.4l1,0.2c0,0.3-4.9,31.1-4.1,38.1
                        c0.8,7.1,4.1,26.8,4.1,27L1383.6,619.2z"/>
                            <path fill="#A7A9AC" d="M1392,619.1c-0.2-1.3-4.5-31.3,0.8-43.3c5.2-11.6-1.3-21-1.4-21.1l0.8-0.6c0.1,0.1,6.9,9.9,1.5,22.1
                        c-5.2,11.8-0.8,42.5-0.7,42.8L1392,619.1z"/>
                            <path fill="#A7A9AC" d="M1418.3,620.5c-0.2-1.4-4.6-33.5,0.2-44c4.7-10.1-2-22.3-2.1-22.5l0.9-0.5c0.3,0.5,7.1,12.8,2.1,23.4
                        c-4.8,10.2-0.2,43.1-0.2,43.4L1418.3,620.5z"/>
                            <path fill="#231F20" d="M1431.7,619.6h-65.2v-65.2h65.2V619.6z M1371.1,615h56.1V559h-56.1V615z" />
                            <path fill="#A7A9AC" d="M1404.3,618c0-0.2-5-15.6-1.9-28c3-12.1,1.5-33.2,1.5-33.4l1-0.1c0,0.2,1.5,21.4-1.5,33.7
                        c-3,12.1,1.8,27.3,1.9,27.4L1404.3,618z"/>
                        </g>

                        <rect x="1355.6" y="579.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.0025 1160.5829)" fill="#BCBEC0" width="85.8" height="13.5" />
                        <path fill="#A7A9AC" d="M1364.7,616.7l-0.4-0.9c0.1-0.1,13.4-5.5,17.2-12.4c3.7-6.7,11.3-18,17.3-21.8c5.8-3.7,18.3-16.6,19.5-19.6
                    l0.9,0.4c-1.4,3.5-14.2,16.5-19.8,20.1c-5.9,3.8-13.4,14.9-17,21.5C1378.5,611.1,1365.3,616.5,1364.7,616.7z"/>
                        <path fill="#A7A9AC" d="M1372.3,617.6l-0.5-0.8c0.1-0.1,13.9-8.7,16.3-15.5c2.5-7.2,11.2-14.7,13.6-16.1c2.4-1.4,22.5-17.4,24.4-21.2
                    l0.9,0.5c-2.1,4-22.4,20.1-24.8,21.6c-2.2,1.3-10.7,8.7-13.1,15.6C1386.5,608.7,1372.9,617.3,1372.3,617.6z"/>
                        <path fill="#231F20" d="M1372.9,624.7l-12.8-12.8L1424,548l12.8,12.8L1372.9,624.7z M1366.6,611.9l6.3,6.3l57.4-57.4l-6.3-6.3
                    L1366.6,611.9z"/>
                        <rect x="1362.1" y="549.9" fill="#808285" width="74.1" height="12.4" />
                        <g>
                            <path fill="#A7A9AC" d="M1362.4,559.6l-0.3-0.9c0.4-0.2,10.9-3.7,18.2-3.7c7.5,0,40-1.7,47.5-5.5l0.4,0.9c-7.7,3.8-40.4,5.6-48,5.6
                        C1373.2,556,1362.5,559.5,1362.4,559.6z"/>
                            <path fill="#A7A9AC" d="M1362.3,555l-0.1-1c0.4-0.1,8.8-1.2,12.2-0.8c3.5,0.4,19.9-3.1,20.8-3.7l0.5,0.8c-1.2,0.8-18.1,4.2-21.5,3.8
                        C1371,553.8,1362.4,555,1362.3,555z"/>
                            <path fill="#A7A9AC" d="M1368.4,562.8c-0.1,0-0.1,0-0.2,0l0-1c2.8,0.1,7.5-0.9,13-2c7.1-1.4,15.1-3,21.6-3c0.1,0,0.2,0,0.3,0
                        c11.4,0.1,27.7,4.9,27.8,5l-0.3,1c-0.2,0-16.4-4.8-27.6-4.9c-6.4-0.1-14.5,1.5-21.7,3C1376,561.9,1371.3,562.8,1368.4,562.8z"/>
                            <path fill="#A7A9AC" d="M1428.4,558.5c-1.8,0-3.2-1.2-3.2-2.6s1.5-2.6,3.2-2.6s3.2,1.2,3.2,2.6S1430.2,558.5,1428.4,558.5z
                        M1428.4,554.3c-1.2,0-2.2,0.7-2.2,1.6s1,1.6,2.2,1.6c1.2,0,2.2-0.7,2.2-1.6S1429.7,554.3,1428.4,554.3z"/>
                            <ellipse fill="#A7A9AC" cx="1428.9" cy="556.2" rx="1.1" ry="0.8" />
                        </g>
                        <path fill="#231F20" d="M1438.5,564.6h-78.7v-16.9h78.7V564.6z M1364.3,560h69.6v-7.8h-69.6V560z" />
                        <rect x="1362.1" y="611.7" fill="#808285" width="74.1" height="12.4" />
                        <path fill="#A7A9AC" d="M1432.5,625c-2.3,0-4.9-0.9-7.7-2.8c-9.4-6.1-27.7-6.5-34.2-3.6c-6.9,3.2-28.8-1.7-29.8-1.9l0.2-1
                    c0.2,0,22.5,5,29.1,1.9c6.8-3.1,25.3-2.7,35.1,3.6c3.5,2.2,6.4,3.1,8.8,2.4c2.2-0.6,3.1-2.2,3.1-2.2l0.9,0.5c0,0.1-1.1,2-3.7,2.7
                    C1433.7,624.9,1433.1,625,1432.5,625z"/>
                        <path fill="#A7A9AC" d="M1436.1,618.4c-9.7-1-11.9-3-13.7-4.7c-1.2-1.1-2.1-1.9-5-2.1c-5.5-0.5-17.9,1.9-26.2,3.4
                    c-2.9,0.5-5.1,1-6.3,1.1c-4.6,0.6-22.1-3.8-22.8-4l0.2-1c0.2,0,18,4.5,22.4,3.9c1.2-0.2,3.4-0.6,6.3-1.1c8.3-1.6,20.8-3.9,26.4-3.5
                    c3.2,0.3,4.3,1.3,5.6,2.4c1.7,1.5,3.8,3.5,13.2,4.4L1436.1,618.4z"/>
                        <path fill="#A7A9AC" d="M1422.5,625.7c-0.1-0.1-13.3-5.3-23.3-3.5c-10.2,1.8-37.2-1.3-38.4-1.4l0.1-1c0.3,0,28,3.2,38.1,1.4
                    c10.3-1.8,23.3,3.3,23.9,3.5L1422.5,625.7z"/>
                        <path fill="#231F20" d="M1438.5,626.4h-78.7v-16.9h78.7V626.4z M1364.3,621.8h69.6V614h-69.6V621.8z" />

                        <rect x="1404.7" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 842.4432 2016.4521)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M1431.1,612.7l-3.7-8.8c-3.7-8.8-2.2-41.5-2.1-42.9l1,0c0,0.3-1.6,33.9,2.1,42.5l3.7,8.8L1431.1,612.7z" />
                        <path fill="#A7A9AC" d="M1434.3,612l-0.9-0.5c1.3-2.3-3.1-18-4.5-23.1c-2.4-8.5-0.1-25.4,0-26.1l1,0.1c0,0.2-2.3,17.4,0,25.7
                    C1434.1,602.9,1435.4,610.1,1434.3,612z"/>
                        <path fill="#A7A9AC" d="M1434.6,587.3c-5.5-8.7-3.2-24.4-3.1-25.1l1,0.2c0,0.2-2.4,15.9,3,24.4L1434.6,587.3z" />
                        <path fill="#231F20" d="M1438.5,614h-18.1v-54h18.1V614z M1425,609.4h8.9v-44.8h-8.9V609.4z" />

                        <rect x="1344.1" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 781.7892 1955.7981)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M1361.4,608.5l-1-0.3c0-0.1,2.9-9.1,5-20.4c2.1-11.2-3.6-27.5-3.7-27.6l0.9-0.3c0.2,0.7,5.9,16.7,3.7,28.2
                    C1364.3,599.3,1361.4,608.4,1361.4,608.5z"/>
                        <path fill="#A7A9AC" d="M1375.5,595.8c-0.5,0-1-0.8-4.7-10.3c-4.2-11.1-4.7-23.1-4.7-23.2l1,0c0,0.1,0.5,11.9,4.7,22.9
                    c3.3,8.6,4,9.6,4.1,9.8l-0.3,0.3l0,0.1h0.5l-0.4,0.5C1375.6,595.8,1375.5,595.8,1375.5,595.8z"/>
                        <path fill="#A7A9AC" d="M1362.3,612.2l-0.2-1c6.1-1,9.4-6.1,11.2-10.2c3.5-8.5,3.1-18.8,1.8-20.6c-2.3-3.3-4.5-17.6-4.6-18.2l1-0.1
                    c0,0.1,2.2,14.7,4.4,17.8c1.5,2.1,1.9,13-1.7,21.6C1371.6,607.6,1367.5,611.4,1362.3,612.2z"/>
                        <path fill="#231F20" d="M1377.8,614h-18.1v-54h18.1V614z M1364.3,609.4h8.9v-44.8h-8.9V609.4z" />
                    </g>
                    <g>
                        <g>
                            <rect x="943.1" y="556.7" fill="#BCBEC0" width="60.7" height="60.7" />
                            <path fill="#A7A9AC" d="M957.9,619.2c0-0.2-3.4-20-4.1-27.1c-0.8-7.2,3.9-37.1,4.1-38.4l1,0.2c0,0.3-4.9,31.1-4.1,38.1
                        c0.8,7.1,4.1,26.8,4.1,27L957.9,619.2z"/>
                            <path fill="#A7A9AC" d="M966.3,619.1c-0.2-1.3-4.5-31.3,0.8-43.3c5.2-11.6-1.3-21-1.4-21.1l0.8-0.6c0.1,0.1,6.9,9.9,1.5,22.1
                        c-5.2,11.8-0.8,42.5-0.7,42.8L966.3,619.1z"/>
                            <path fill="#A7A9AC" d="M992.6,620.5c-0.2-1.4-4.6-33.5,0.2-44c4.7-10.1-2-22.3-2.1-22.5l0.9-0.5c0.3,0.5,7.1,12.8,2.1,23.4
                        c-4.8,10.2-0.2,43.1-0.2,43.4L992.6,620.5z"/>
                            <path fill="#231F20" d="M1006,619.6h-65.2v-65.2h65.2V619.6z M945.4,615h56.1V559h-56.1V615z" />
                            <path fill="#A7A9AC" d="M978.6,618c0-0.2-5-15.6-1.9-28c3-12.1,1.5-33.2,1.5-33.4l1-0.1c0,0.2,1.5,21.4-1.5,33.7
                        c-3,12.1,1.8,27.3,1.9,27.4L978.6,618z"/>
                        </g>

                        <rect x="929.8" y="579.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -129.696 859.546)" fill="#BCBEC0" width="85.8" height="13.5" />
                        <path fill="#A7A9AC" d="M939,616.7l-0.4-0.9c0.1-0.1,13.4-5.5,17.2-12.4c3.7-6.7,11.3-18,17.3-21.8c5.8-3.7,18.3-16.6,19.5-19.6
                    l0.9,0.4c-1.4,3.5-14.2,16.5-19.9,20.1c-5.9,3.8-13.4,14.9-17,21.5C952.7,611.1,939.5,616.5,939,616.7z"/>
                        <path fill="#A7A9AC" d="M946.6,617.6l-0.5-0.8c0.1-0.1,13.9-8.7,16.3-15.5c2.5-7.2,11.2-14.7,13.6-16.1c2.4-1.4,22.5-17.4,24.4-21.2
                    l0.9,0.5c-2.1,4-22.4,20.1-24.8,21.6c-2.2,1.3-10.7,8.7-13.1,15.6C960.8,608.7,947.2,617.3,946.6,617.6z"/>
                        <path fill="#231F20" d="M947.2,624.7l-12.8-12.8l63.9-63.9l12.8,12.8L947.2,624.7z M940.9,611.9l6.3,6.3l57.4-57.4l-6.3-6.3
                    L940.9,611.9z"/>
                        <rect x="936.3" y="549.9" fill="#808285" width="74.1" height="12.4" />
                        <g>
                            <path fill="#A7A9AC" d="M936.6,559.6l-0.3-0.9c0.4-0.2,10.9-3.7,18.2-3.7c7.5,0,40-1.7,47.5-5.5l0.4,0.9c-7.7,3.8-40.4,5.6-48,5.6
                        C947.4,556,936.8,559.5,936.6,559.6z"/>
                            <path fill="#A7A9AC" d="M936.6,555l-0.1-1c0.4,0,8.8-1.2,12.2-0.8c3.5,0.4,19.9-3.1,20.8-3.7l0.5,0.8c-1.2,0.8-18.1,4.2-21.5,3.8
                        C945.2,553.8,936.6,555,936.6,555z"/>
                            <path fill="#A7A9AC" d="M942.7,562.8c-0.1,0-0.1,0-0.2,0l0-1c2.8,0.1,7.5-0.9,13-2c7.1-1.4,15.1-3,21.6-3c0.1,0,0.2,0,0.3,0
                        c11.4,0.1,27.7,4.9,27.8,5l-0.3,1c-0.2,0-16.4-4.8-27.6-4.9c-6.4-0.1-14.5,1.5-21.7,3C950.3,561.9,945.6,562.8,942.7,562.8z"/>
                            <path fill="#A7A9AC" d="M1002.7,558.5c-1.8,0-3.2-1.2-3.2-2.6s1.5-2.6,3.2-2.6s3.2,1.2,3.2,2.6S1004.5,558.5,1002.7,558.5z
                        M1002.7,554.3c-1.2,0-2.2,0.7-2.2,1.6s1,1.6,2.2,1.6c1.2,0,2.2-0.7,2.2-1.6S1003.9,554.3,1002.7,554.3z"/>
                            <ellipse fill="#A7A9AC" cx="1003.1" cy="556.2" rx="1.1" ry="0.8" />
                        </g>
                        <path fill="#231F20" d="M1012.7,564.6H934v-16.9h78.7V564.6z M938.6,560h69.6v-7.8h-69.6V560z" />
                        <rect x="936.3" y="611.7" fill="#808285" width="74.1" height="12.4" />
                        <path fill="#A7A9AC" d="M1006.7,625c-2.3,0-4.9-0.9-7.7-2.8c-9.4-6.1-27.7-6.5-34.2-3.6c-6.9,3.2-28.8-1.7-29.8-1.9l0.2-1
                    c0.2,0,22.5,5,29.1,1.9c6.8-3.1,25.3-2.7,35.1,3.6c3.5,2.2,6.4,3.1,8.8,2.4c2.2-0.6,3.1-2.2,3.1-2.2l0.9,0.5c0,0.1-1.1,2-3.7,2.7
                    C1008,624.9,1007.4,625,1006.7,625z"/>
                        <path fill="#A7A9AC" d="M1010.4,618.4c-9.7-1-11.9-3-13.7-4.7c-1.2-1.1-2.1-1.9-5-2.1c-5.5-0.5-17.9,1.9-26.2,3.4
                    c-2.9,0.5-5.1,1-6.3,1.1c-4.6,0.6-22.1-3.8-22.8-4l0.2-1c0.2,0,18,4.5,22.4,3.9c1.2-0.2,3.4-0.6,6.3-1.1c8.3-1.6,20.8-3.9,26.4-3.5
                    c3.2,0.3,4.3,1.3,5.6,2.4c1.7,1.5,3.8,3.5,13.2,4.4L1010.4,618.4z"/>
                        <path fill="#A7A9AC" d="M996.8,625.7c-0.1-0.1-13.3-5.3-23.3-3.5c-10.2,1.8-37.2-1.3-38.4-1.4l0.1-1c0.3,0,28,3.2,38.1,1.4
                    c10.3-1.8,23.3,3.3,23.9,3.5L996.8,625.7z"/>
                        <path fill="#231F20" d="M1012.7,626.4H934v-16.9h78.7V626.4z M938.6,621.8h69.6V614h-69.6V621.8z" />

                        <rect x="979" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 416.7128 1590.7217)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M1005.3,612.7l-3.7-8.8c-3.7-8.8-2.2-41.5-2.1-42.9l1,0c0,0.3-1.6,33.9,2.1,42.5l3.7,8.8L1005.3,612.7z" />
                        <path fill="#A7A9AC" d="M1008.6,612l-0.9-0.5c1.3-2.3-3.1-18-4.5-23.1c-2.4-8.5-0.1-25.4,0-26.1l1,0.1c0,0.2-2.3,17.4,0,25.7
                    C1008.4,602.9,1009.7,610.1,1008.6,612z"/>
                        <path fill="#A7A9AC" d="M1008.9,587.3c-5.5-8.7-3.2-24.4-3.1-25.1l1,0.2c0,0.2-2.4,15.9,3,24.4L1008.9,587.3z" />
                        <path fill="#231F20" d="M1012.7,614h-18.1v-54h18.1V614z M999.3,609.4h8.9v-44.8h-8.9V609.4z" />

                        <rect x="918.4" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 356.0588 1530.0676)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M935.7,608.5l-1-0.3c0-0.1,2.9-9.1,5-20.4c2.1-11.2-3.6-27.5-3.7-27.6l0.9-0.3c0.2,0.7,5.9,16.7,3.7,28.2
                    C938.6,599.3,935.7,608.4,935.7,608.5z"/>
                        <path fill="#A7A9AC" d="M949.7,595.8c-0.5,0-1-0.8-4.7-10.3c-4.2-11.1-4.7-23.1-4.7-23.2l1,0c0,0.1,0.5,11.9,4.7,22.9
                    c3.3,8.6,4,9.6,4.1,9.8l-0.3,0.3l0,0.1h0.5l-0.4,0.5C949.8,595.8,949.8,595.8,949.7,595.8z"/>
                        <path fill="#A7A9AC" d="M936.6,612.2l-0.2-1c6.1-1,9.4-6.1,11.2-10.2c3.5-8.5,3.1-18.8,1.8-20.6c-2.3-3.3-4.5-17.6-4.6-18.2l1-0.1
                    c0,0.1,2.2,14.7,4.4,17.8c1.5,2.1,1.9,13-1.7,21.6C945.9,607.6,941.8,611.4,936.6,612.2z"/>
                        <path fill="#231F20" d="M952.1,614H934v-54h18.1V614z M938.6,609.4h8.9v-44.8h-8.9V609.4z" />
                    </g>
                    <g>
                        <g>
                            <rect x="1466.1" y="556.7" fill="#BCBEC0" width="60.7" height="60.7" />
                            <path fill="#A7A9AC" d="M1480.9,619.2c0-0.2-3.4-20-4.1-27.1c-0.8-7.2,3.9-37.1,4.1-38.4l1,0.2c0,0.3-4.9,31.1-4.1,38.1
                        c0.8,7.1,4.1,26.8,4.1,27L1480.9,619.2z"/>
                            <path fill="#A7A9AC" d="M1489.3,619.1c-0.2-1.3-4.5-31.3,0.8-43.3c5.2-11.6-1.3-21-1.4-21.1l0.8-0.6c0.1,0.1,6.9,9.9,1.5,22.1
                        c-5.2,11.8-0.8,42.5-0.7,42.8L1489.3,619.1z"/>
                            <path fill="#A7A9AC" d="M1515.6,620.5c-0.2-1.4-4.6-33.5,0.2-44c4.7-10.1-2-22.3-2.1-22.5l0.9-0.5c0.3,0.5,7.1,12.8,2.1,23.4
                        c-4.8,10.2-0.2,43.1-0.2,43.4L1515.6,620.5z"/>
                            <path fill="#231F20" d="M1529.1,619.6h-65.2v-65.2h65.2V619.6z M1468.4,615h56.1V559h-56.1V615z" />
                            <path fill="#A7A9AC" d="M1501.6,618c0-0.2-5-15.6-1.9-28c3-12.1,1.5-33.2,1.5-33.4l1-0.1c0,0.2,1.5,21.4-1.5,33.7
                        c-3,12.1,1.8,27.3,1.9,27.4L1501.6,618z"/>
                        </g>

                        <rect x="1452.9" y="579.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 23.5012 1229.3967)" fill="#BCBEC0" width="85.8" height="13.5" />
                        <path fill="#A7A9AC" d="M1462,616.7l-0.4-0.9c0.1-0.1,13.4-5.5,17.2-12.4c3.7-6.7,11.3-18,17.3-21.8c5.8-3.7,18.3-16.6,19.5-19.6
                    l0.9,0.4c-1.4,3.5-14.2,16.5-19.9,20.1c-5.9,3.8-13.4,14.9-17,21.5C1475.8,611.1,1462.6,616.5,1462,616.7z"/>
                        <path fill="#A7A9AC" d="M1469.6,617.6l-0.5-0.8c0.1-0.1,13.9-8.7,16.3-15.5c2.5-7.2,11.2-14.7,13.6-16.1c2.4-1.4,22.5-17.4,24.4-21.2
                    l0.9,0.5c-2.1,4-22.4,20.1-24.8,21.6c-2.2,1.3-10.7,8.7-13.1,15.6C1483.8,608.7,1470.2,617.3,1469.6,617.6z"/>
                        <path fill="#231F20" d="M1470.2,624.7l-12.8-12.8l63.9-63.9l12.8,12.8L1470.2,624.7z M1463.9,611.9l6.3,6.3l57.4-57.4l-6.3-6.3
                    L1463.9,611.9z"/>
                        <rect x="1459.4" y="549.9" fill="#808285" width="74.1" height="12.4" />
                        <g>
                            <path fill="#A7A9AC" d="M1459.7,559.6l-0.3-0.9c0.4-0.2,10.9-3.7,18.2-3.7c7.5,0,40-1.7,47.5-5.5l0.4,0.9c-7.7,3.8-40.4,5.6-48,5.6
                        C1470.5,556,1459.8,559.5,1459.7,559.6z"/>
                            <path fill="#A7A9AC" d="M1459.6,555l-0.1-1c0.4,0,8.8-1.2,12.2-0.8c3.5,0.4,19.9-3.1,20.8-3.7l0.5,0.8c-1.2,0.8-18.1,4.2-21.5,3.8
                        C1468.3,553.8,1459.7,555,1459.6,555z"/>
                            <path fill="#A7A9AC" d="M1465.7,562.8c-0.1,0-0.1,0-0.2,0l0-1c2.8,0.1,7.5-0.9,13-2c7.1-1.4,15.1-3,21.6-3c0.1,0,0.2,0,0.3,0
                        c11.4,0.1,27.7,4.9,27.8,5l-0.3,1c-0.2,0-16.4-4.8-27.6-4.9c-6.4-0.1-14.5,1.5-21.7,3C1473.3,561.9,1468.6,562.8,1465.7,562.8z"/>
                            <path fill="#A7A9AC" d="M1525.7,558.5c-1.8,0-3.2-1.2-3.2-2.6s1.5-2.6,3.2-2.6s3.2,1.2,3.2,2.6S1527.5,558.5,1525.7,558.5z
                        M1525.7,554.3c-1.2,0-2.2,0.7-2.2,1.6s1,1.6,2.2,1.6s2.2-0.7,2.2-1.6S1527,554.3,1525.7,554.3z"/>
                            <ellipse fill="#A7A9AC" cx="1526.2" cy="556.2" rx="1.1" ry="0.8" />
                        </g>
                        <path fill="#231F20" d="M1535.8,564.6h-78.7v-16.9h78.7V564.6z M1461.7,560h69.6v-7.8h-69.6V560z" />
                        <rect x="1459.4" y="611.7" fill="#808285" width="74.1" height="12.4" />
                        <path fill="#A7A9AC" d="M1529.8,625c-2.3,0-4.9-0.9-7.7-2.8c-9.4-6.1-27.7-6.5-34.2-3.6c-6.9,3.2-28.8-1.7-29.8-1.9l0.2-1
                    c0.2,0,22.5,5,29.1,1.9c6.8-3.1,25.3-2.7,35.1,3.6c3.5,2.2,6.4,3.1,8.8,2.4c2.2-0.6,3.1-2.2,3.1-2.2l0.9,0.5c0,0.1-1.1,2-3.7,2.7
                    C1531.1,624.9,1530.4,625,1529.8,625z"/>
                        <path fill="#A7A9AC" d="M1533.5,618.4c-9.7-1-11.9-3-13.7-4.7c-1.2-1.1-2.1-1.9-5-2.1c-5.5-0.5-17.9,1.9-26.2,3.4
                    c-2.9,0.5-5.1,1-6.3,1.1c-4.6,0.6-22.1-3.8-22.8-4l0.2-1c0.2,0,18,4.5,22.4,3.9c1.2-0.2,3.4-0.6,6.3-1.1c8.3-1.6,20.8-3.9,26.4-3.5
                    c3.2,0.3,4.3,1.3,5.6,2.4c1.7,1.5,3.8,3.5,13.2,4.4L1533.5,618.4z"/>
                        <path fill="#A7A9AC" d="M1519.8,625.7c-0.1-0.1-13.3-5.3-23.3-3.5c-10.2,1.8-37.2-1.3-38.4-1.4l0.1-1c0.3,0,28,3.2,38.1,1.4
                    c10.3-1.9,23.3,3.3,23.9,3.5L1519.8,625.7z"/>
                        <path fill="#231F20" d="M1535.8,626.4h-78.7v-16.9h78.7V626.4z M1461.7,621.8h69.6V614h-69.6V621.8z" />

                        <rect x="1502.1" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 939.7609 2113.7698)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M1528.4,612.7l-3.7-8.8c-3.7-8.8-2.2-41.5-2.1-42.9l1,0c0,0.3-1.6,33.9,2.1,42.5l3.7,8.8L1528.4,612.7z" />
                        <path fill="#A7A9AC" d="M1531.7,612l-0.9-0.5c0.9-1.5-0.6-9.3-4.5-23.1c-2.4-8.5-0.1-25.4,0-26.1l1,0.1c0,0.2-2.3,17.4,0,25.7
                    C1531.4,602.9,1532.7,610.1,1531.7,612z"/>
                        <path fill="#A7A9AC" d="M1531.9,587.3c-5.5-8.7-3.2-24.4-3.1-25.1l1,0.2c0,0.2-2.4,15.9,3,24.4L1531.9,587.3z" />
                        <path fill="#231F20" d="M1535.8,614h-18.1v-54h18.1V614z M1522.3,609.4h8.9v-44.8h-8.9V609.4z" />

                        <rect x="1441.4" y="580.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 879.1068 2053.1157)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M1458.7,608.5l-1-0.3c0-0.1,2.9-9.1,5-20.4c2.1-11.2-3.6-27.5-3.7-27.6l0.9-0.3c0.2,0.7,5.9,16.7,3.7,28.2
                    C1461.6,599.3,1458.7,608.4,1458.7,608.5z"/>
                        <path fill="#A7A9AC" d="M1472.8,595.8c-0.5,0-1-0.8-4.7-10.3c-4.2-11.1-4.7-23.1-4.7-23.2l1,0c0,0.1,0.5,11.9,4.7,22.9
                    c3.3,8.6,4,9.6,4.1,9.8l-0.3,0.3l0,0l0.5,0l-0.4,0.5C1472.9,595.8,1472.8,595.8,1472.8,595.8z"/>
                        <path fill="#A7A9AC" d="M1459.6,612.2l-0.2-1c6.1-1,9.4-6.1,11.2-10.2c3.5-8.5,3.1-18.8,1.8-20.6c-2.3-3.3-4.5-17.6-4.6-18.2l1-0.1
                    c0,0.1,2.2,14.7,4.4,17.8c1.5,2.1,1.9,13-1.7,21.6C1468.9,607.6,1464.8,611.4,1459.6,612.2z"/>
                        <path fill="#231F20" d="M1475.1,614h-18.1v-54h18.1V614z M1461.7,609.4h8.9v-44.8h-8.9V609.4z" />
                    </g>
                    <g>
                        <g>
                            <rect x="1427.6" y="480.3" fill="#BCBEC0" width="60.7" height="60.7" />
                            <path fill="#A7A9AC" d="M1442.4,542.8c0-0.2-3.4-20-4.1-27.1c-0.8-7.2,3.9-37.1,4.1-38.4l1,0.2c0,0.3-4.9,31.1-4.1,38.1
                        c0.8,7.1,4.1,26.8,4.1,27L1442.4,542.8z"/>
                            <path fill="#A7A9AC" d="M1450.8,542.8c-0.2-1.3-4.5-31.3,0.8-43.3c5.2-11.7-1.3-21-1.4-21.1l0.8-0.6c0.1,0.1,6.9,9.9,1.5,22.1
                        c-5.2,11.8-0.8,42.5-0.7,42.8L1450.8,542.8z"/>
                            <path fill="#A7A9AC" d="M1477.2,544.1c-0.2-1.4-4.6-33.5,0.2-44c4.7-10.1-2-22.3-2.1-22.5l0.9-0.5c0.3,0.5,7.1,12.8,2.1,23.4
                        c-4.8,10.2-0.2,43.1-0.2,43.4L1477.2,544.1z"/>
                            <path fill="#231F20" d="M1490.6,543.3h-65.2V478h65.2V543.3z M1429.9,538.7h56.1v-56.1h-56.1V538.7z" />
                            <path fill="#A7A9AC" d="M1463.1,541.7c0-0.2-5-15.6-1.9-28c3-12.1,1.5-33.2,1.5-33.4l1-0.1c0,0.2,1.5,21.4-1.5,33.7
                        c-3,12.1,1.8,27.3,1.9,27.4L1463.1,541.7z"/>
                        </g>

                        <rect x="1414.4" y="503.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 66.2212 1179.8125)" fill="#BCBEC0" width="85.8" height="13.5" />
                        <path fill="#A7A9AC" d="M1423.5,540.4l-0.4-0.9c0.1-0.1,13.4-5.5,17.2-12.4c3.7-6.7,11.3-18,17.3-21.8c5.8-3.7,18.3-16.6,19.5-19.6
                    l0.9,0.4c-1.4,3.5-14.2,16.5-19.8,20.1c-5.9,3.8-13.4,14.9-17,21.5C1437.3,534.7,1424.1,540.2,1423.5,540.4z"/>
                        <path fill="#A7A9AC" d="M1431.1,541.3l-0.5-0.8c0.1-0.1,13.9-8.7,16.3-15.5c2.5-7.2,11.2-14.7,13.6-16.1c2.4-1.4,22.5-17.4,24.4-21.2
                    l0.9,0.5c-2.1,4-22.4,20.1-24.8,21.6c-2.2,1.3-10.7,8.7-13.1,15.6C1445.3,532.4,1431.7,540.9,1431.1,541.3z"/>
                        <path fill="#231F20" d="M1431.7,548.3l-12.8-12.8l63.9-63.9l12.8,12.8L1431.7,548.3z M1425.4,535.5l6.3,6.3l57.4-57.4l-6.3-6.3
                    L1425.4,535.5z"/>
                        <rect x="1420.9" y="473.6" fill="#808285" width="74.1" height="12.4" />
                        <g>
                            <path fill="#A7A9AC" d="M1421.2,483.2l-0.3-0.9c0.4-0.2,10.9-3.7,18.2-3.7c7.5,0,40-1.7,47.5-5.5l0.4,0.9c-7.7,3.8-40.4,5.6-48,5.6
                        C1432,479.6,1421.3,483.2,1421.2,483.2z"/>
                            <path fill="#A7A9AC" d="M1421.1,478.7l-0.1-1c0.4,0,8.8-1.2,12.2-0.8c3.5,0.4,19.9-3.1,20.8-3.7l0.5,0.8c-1.2,0.8-18.1,4.2-21.5,3.8
                        C1429.8,477.5,1421.2,478.6,1421.1,478.7z"/>
                            <path fill="#A7A9AC" d="M1427.2,486.4c-0.1,0-0.1,0-0.2,0l0-1c2.8,0.1,7.5-0.9,13-2c7.2-1.4,15.4-3,21.9-3c11.4,0.1,27.7,4.9,27.8,5
                        l-0.3,1c-0.2,0-16.4-4.8-27.6-4.9c-6.4-0.1-14.5,1.5-21.7,3C1434.8,485.5,1430.1,486.4,1427.2,486.4z"/>
                            <path fill="#A7A9AC" d="M1487.2,482.2c-1.8,0-3.2-1.2-3.2-2.6c0-1.5,1.5-2.6,3.2-2.6s3.2,1.2,3.2,2.6
                        C1490.5,481,1489,482.2,1487.2,482.2z M1487.2,477.9c-1.2,0-2.2,0.7-2.2,1.6s1,1.6,2.2,1.6c1.2,0,2.2-0.7,2.2-1.6
                        S1488.5,477.9,1487.2,477.9z"/>
                            <ellipse fill="#A7A9AC" cx="1487.7" cy="479.9" rx="1.1" ry="0.8" />
                        </g>
                        <path fill="#231F20" d="M1497.3,488.2h-78.7v-16.9h78.7V488.2z M1423.2,483.6h69.6v-7.8h-69.6V483.6z" />
                        <rect x="1420.9" y="535.4" fill="#808285" width="74.1" height="12.4" />
                        <path fill="#A7A9AC" d="M1491.3,548.6c-2.3,0-4.9-0.9-7.7-2.8c-9.4-6.1-27.7-6.5-34.2-3.6c-6.9,3.2-28.8-1.7-29.8-1.9l0.2-1
                    c0.2,0,22.5,5,29.1,1.9c6.8-3.1,25.3-2.7,35.1,3.6c3.5,2.2,6.4,3,8.8,2.4c2.2-0.6,3.1-2.2,3.1-2.2l0.9,0.5c0,0.1-1.1,2-3.7,2.7
                    C1492.6,548.6,1491.9,548.6,1491.3,548.6z"/>
                        <path fill="#A7A9AC" d="M1495,542c-9.7-1-11.9-3-13.7-4.7c-1.2-1.1-2.1-1.9-5-2.1c-5.5-0.5-17.9,1.9-26.2,3.4c-2.9,0.5-5.1,1-6.3,1.1
                    c-4.6,0.6-22.1-3.8-22.8-4l0.2-1c0.2,0,18,4.5,22.4,3.9c1.2-0.2,3.4-0.6,6.3-1.1c8.3-1.6,20.8-3.9,26.4-3.5
                    c3.2,0.3,4.3,1.3,5.6,2.4c1.7,1.5,3.8,3.5,13.2,4.4L1495,542z"/>
                        <path fill="#A7A9AC" d="M1481.3,549.4c-0.1-0.1-13.3-5.3-23.3-3.5c-10.2,1.8-37.2-1.3-38.4-1.4l0.1-1c0.3,0,28,3.2,38.1,1.4
                    c10.3-1.8,23.3,3.3,23.9,3.5L1481.3,549.4z"/>
                        <path fill="#231F20" d="M1497.3,550h-78.7v-16.9h78.7V550z M1423.2,545.4h69.6v-7.8h-69.6V545.4z" />

                        <rect x="1463.6" y="503.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 977.6271 1998.9166)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M1489.9,536.3l-3.7-8.8c-3.7-8.8-2.2-41.5-2.1-42.9l1,0c0,0.3-1.6,33.9,2.1,42.5l3.7,8.8L1489.9,536.3z" />
                        <path fill="#A7A9AC" d="M1493.2,535.6l-0.9-0.5c1.3-2.3-3.1-18-4.5-23.1c-2.4-8.5-0.1-25.4,0-26.1l1,0.1c0,0.2-2.3,17.4,0,25.7
                    C1492.9,526.6,1494.2,533.7,1493.2,535.6z"/>
                        <path fill="#A7A9AC" d="M1493.4,510.9c-5.5-8.7-3.2-24.4-3.1-25.1l1,0.2c0,0.2-2.4,15.9,3,24.4L1493.4,510.9z" />
                        <path fill="#231F20" d="M1497.3,537.6h-18.1v-54h18.1V537.6z M1483.8,533.1h8.9v-44.8h-8.9V533.1z" />

                        <rect x="1402.9" y="503.9" transform="matrix(-3.724226e-14 -1 1 -3.724226e-14 916.973 1938.2626)" fill="#808285" width="49.4" height="13.5" />
                        <path fill="#A7A9AC" d="M1420.2,532.1l-1-0.3c0-0.1,2.9-9.1,5-20.4c2.1-11.2-3.6-27.5-3.7-27.6l0.9-0.3c0.2,0.7,5.9,16.7,3.7,28.2
                    C1423.1,523,1420.2,532,1420.2,532.1z"/>
                        <path fill="#A7A9AC" d="M1434.3,519.4c-0.5,0-1-0.8-4.7-10.3c-4.2-11.1-4.7-23.1-4.7-23.2l1,0c0,0.1,0.5,11.9,4.7,22.9
                    c3.3,8.6,4,9.6,4.1,9.8l-0.3,0.3l0,0l0.5,0l-0.4,0.5C1434.4,519.4,1434.3,519.4,1434.3,519.4z"/>
                        <path fill="#A7A9AC" d="M1421.1,535.8l-0.2-1c6.1-1,9.4-6.1,11.2-10.2c3.5-8.5,3.1-18.8,1.8-20.6c-2.3-3.3-4.5-17.6-4.6-18.2l1-0.1
                    c0,0.1,2.2,14.7,4.4,17.8c1.5,2.1,1.9,13-1.7,21.6C1430.4,531.3,1426.3,535,1421.1,535.8z"/>
                        <path fill="#231F20" d="M1436.6,537.6h-18.1v-54h18.1V537.6z M1423.2,533.1h8.9v-44.8h-8.9V533.1z" />
                    </g>
                    <rect x="1233" y="444.9" fill="#808285" width="17.5" height="70.9" />
                    <rect x="1256" y="444.9" fill="#808285" width="17.5" height="70.9" />
                    <path fill="#231F20" d="M888.4,237c-6.2,0-11.3-5.1-11.3-11.3s5.1-11.3,11.3-11.3s11.3,5.1,11.3,11.3S894.6,237,888.4,237z
                M888.4,219.4c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3s6.3-2.8,6.3-6.3S891.9,219.4,888.4,219.4z"/>
                    <path fill="#231F20" d="M888.4,219.4c-1.4,0-2.5-1.1-2.5-2.5v-50.9c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v50.9
                C890.9,218.3,889.8,219.4,888.4,219.4z"/>
                    <g>
                        <g>
                            <rect x="704.2" y="593.3" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M740.2,609.2h-38.5v-18.4h38.5V609.2z M706.7,604.2h28.5v-8.4h-28.5V604.2z" />
                        </g>
                        <g>
                            <rect x="744.4" y="593.3" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M780.3,609.2h-38.5v-18.4h38.5V609.2z M746.9,604.2h28.5v-8.4h-28.5V604.2z" />
                        </g>
                        <g>
                            <rect x="751.1" y="580" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M787,595.8h-38.5v-18.4H787V595.8z M753.6,590.8H782v-8.4h-28.5V590.8z" />
                        </g>
                        <g>
                            <rect x="710.9" y="580" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M746.9,595.8h-38.5v-18.4h38.5V595.8z M713.4,590.8h28.5v-8.4h-28.5V590.8z" />
                        </g>
                        <g>
                            <rect x="704.2" y="566.6" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M740.2,582.5h-38.5v-18.4h38.5V582.5z M706.7,577.5h28.5v-8.4h-28.5V577.5z" />
                        </g>
                        <g>
                            <rect x="744.4" y="566.6" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M780.3,582.5h-38.5v-18.4h38.5V582.5z M746.9,577.5h28.5v-8.4h-28.5V577.5z" />
                        </g>
                        <g>
                            <rect x="751.1" y="553.2" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M787,569.1h-38.5v-18.4H787V569.1z M753.6,564.1H782v-8.4h-28.5V564.1z" />
                        </g>
                        <g>
                            <rect x="697.5" y="553.2" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M733.5,569.1H695v-18.4h38.5V569.1z M700,564.1h28.5v-8.4H700V564.1z" />
                        </g>
                        <g>
                            <rect x="724.3" y="539.8" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M760.3,555.7h-38.5v-18.4h38.5V555.7z M726.8,550.7h28.5v-8.4h-28.5V550.7z" />
                        </g>
                        <g>
                            <rect x="791.2" y="593.3" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M827.2,609.2h-38.5v-18.4h38.5V609.2z M793.7,604.2h28.5v-8.4h-28.5V604.2z" />
                        </g>
                        <g>

                            <rect x="670.6" y="581.7" transform="matrix(0.6117 -0.7911 0.7911 0.6117 -198.5944 772.2253)" fill="#D36866" width="33.5" height="13.4" />
                            <path fill="#231F20" d="M682.8,609.2L668.3,598l23.5-30.4l14.5,11.2L682.8,609.2z M675.3,597.1l6.6,5.1l17.4-22.5l-6.6-5.1
                        L675.3,597.1z"/>
                        </g>
                    </g>
                    <circle fill="#231F20" cx="897.6" cy="521.2" r="4.5" />
                    <g id="sleeping">
                        <Zfive>
                            <path fill="#D36866" d="M1109.9,402.2v2.1l-4,10h4v2.4h-7v-2.1c2.6-6.4,4-9.8,4.1-10h-3.2v-2.3H1109.9z" />
                        </Zfive>
                        <Zfour>
                            <path fill="#D36866" d="M1100.5,416.7v2l-3.9,9.7h3.9v2.3h-6.7v-2.1c2.5-6.2,3.8-9.4,3.9-9.7h-3.1v-2.3H1100.5z" />
                        </Zfour>
                        <Zthree>
                            <path fill="#D36866" d="M1108.4,430.1v1.8l-3.5,8.7h3.5v2.1h-6.1v-1.9c2.3-5.6,3.4-8.5,3.5-8.7h-2.8v-2H1108.4z" />
                        </Zthree>
                        <Ztwo>
                            <path fill="#D36866" d="M1099.7,443v1.6l-3.1,7.8h3.1v1.9h-5.4v-1.7c2-5,3.1-7.6,3.2-7.8h-2.5V443H1099.7z" />
                        </Ztwo>
                        <Zone>
                            <path fill="#D36866" d="M1093.2,455.6v1.4l-2.7,6.7h2.7v1.6h-4.6v-1.4c1.7-4.3,2.6-6.5,2.7-6.7h-2.1v-1.6H1093.2z" />
                        </Zone>
                    </g>
                    <g>
                        <path d="M322.4,238.1l3.4,3.5l0,5.8h-5.5v-4.6h-2.8v19.4h2.8v-5.5h5.5v6.8l-3.4,3.4h-6.9l-3.4-3.5l0-21.9l3.5-3.5h6.7H322.4z" />
                        <path d="M341.6,241.6v21.9l-3.5,3.5h-6.3l-3.5-3.5v-21.9l3.5-3.5h6.3L341.6,241.6z M336,242.8h-2.2v19.3h2.2V242.8z" />
                        <path d="M363.5,238.1v28.8H358V248l-2.8,18.9H353l-3.1-19v19h-5.5v-28.8h7.4l2.1,14.5l1.9-14.5H363.5z" />
                        <path d="M372.2,238.1v28.8h-5.5v-28.8H372.2z" />
                        <path d="M389.1,238.1v28.8h-5.4l-2.9-11.8v11.8h-5.5v-28.8h5.4l2.9,11.5v-11.5H389.1z" />
                        <path d="M395.6,238.1l6.9,0l3.5,3.5l-0.1,5.2h-5.3l-0.1-4h-2.7v19.4h2.7v-5.9H399v-4.6h6.9l0,11.8l-3.4,3.4h-6.9l-3.4-3.5v-21.9
                    l3.3-3.3L395.6,238.1z"/>
                        <path d="M437,238.1l3.5,3.5v4.8h-5.3v-3.6h-3.1v7.3h4.9l3.5,3.5l0,9.8l-3.4,3.4h-6.9l-3.5-3.5v-4.1h5.2v2.8h3c0-2.9,0-5.4,0-7.7
                    h-4.8l-3.3-3.4l-0.1-9.5l3.5-3.5H437z"/>
                        <path d="M456.4,241.6v21.9l-3.5,3.5h-6.3l-3.5-3.5v-21.9l3.5-3.5h6.3L456.4,241.6z M450.8,242.8h-2.2v19.3h2.2V242.8z" />
                        <path d="M472.6,241.6v21.9l-3.5,3.5h-6.3l-3.5-3.5v-21.9l3.5-3.5h6.3L472.6,241.6z M467,242.8h-2.2v19.3h2.2V242.8z" />
                        <path d="M489.3,238.1v28.8h-5.4l-2.9-11.8v11.8h-5.5v-28.8h5.4l2.9,11.5v-11.5H489.3z" />
                    </g>
                </svg>
                <PageText>!Ups! La pagina esta en construcción <br/> Proximamente habran novedades.</PageText>
            </ContainerPage>
        </Box>
    )
}

export default PageConstruction

const swinging = keyframes`
    0%{transform: rotate(10deg);}
    50%{transform: rotate(-5deg)}
    100%{transform: rotate(10deg);}
`
const sleeping = keyframes`
    0%{transform: rotate(10deg);}
    50%{transform: rotate(-5deg)}
    100%{transform: rotate(10deg);}
`
const ContainerPage = styled.div`
    margin: 60px 0px;
    width: 80%;
    text-align: center;
    position: relative;
    @media (max-width: 768px) {
        width: 100%;
        min-height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
const PageText = styled.h3`
    position: absolute;
    width: 100%;
    bottom: 0px;
    text-align: center;
`
const Swinger = styled.g`
    transform-origin: top center;
    transform-box: fill-box;
    animation: ${swinging} 3.5s ease-in-out forwards infinite;
`
const Zone = styled.g`
    opacity:0;
    animation: ${sleeping} 3.5s ease-in-out forwards infinite;
`
const Ztwo = styled.g`
    opacity:0;
    animation: ${sleeping} 3.5s ease-in-out forwards infinite;
    animation-delay:0.4s;
`
const Zthree = styled.g`
    opacity:0;
    animation: ${sleeping} 3.5s ease-in-out forwards infinite;
    animation-delay:0.7s;
`
const Zfour = styled.g`
    opacity:0;
    animation: ${sleeping} 3.5s ease-in-out forwards infinite;
    animation-delay:1s;
`
const Zfive = styled.g`
    opacity:0;
    animation: ${sleeping} 3.5s ease-in-out forwards infinite;
    animation-delay:1.3s;
`