import clientAxios from "../config";

const makeNewAgreement = async (token, data) => clientAxios.post(`/api/agreement/`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
});

const loadCompanyAgreements = async (token, uuid) => clientAxios(`/api/agreement-company/${uuid}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const cancelAgreement = async (token, uuid) => clientAxios(`/api/agreement-cancel/${uuid}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const uploadCompanyAgreement = async (token, uuid, data) => clientAxios.post(`/api/agreement/${uuid}`, data, {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": 'multipart/form-data'
    }
});

export {
    makeNewAgreement,
    loadCompanyAgreements,
    uploadCompanyAgreement,
    cancelAgreement,
}