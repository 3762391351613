import { Box, Button, Container, Grid, Modal, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import PlayButton from '../../../components/button/PlayButton'
import Loader from '../../../components/loader/Loader'
import setKey from '../../../hooks/setKey'
import useIndex from '../../../hooks/useIndex'
import BackGroundImg from '../../../assets/img/imagen-sol.svg'
import { Notify } from 'notiflix'
import AddMoneyCard from '../account/AddMoneyCard'
import Resultado from './Resultado'
import InputGame from './InputGame'
import { makeFullGame, makePublicGame } from '../../../data/api/game/GameRoutes'
import AwardsTable from './AwardsTable'
import validErrors from '../../../hooks/validErrors'
import { sentWinnerMail } from '../../../data/api/winners/WinnersRoute'

const Game = ({
    handleOpen,
    setCodeWin,
    setWinData
}) => {

    const { auth, token, validateUser } = useIndex();
    const [openPay, setOpenPay] = useState(false);
    const [result, setResult] = useState({});
    const [game, setGame] = useState({});
    const [confirmHit, setConfirmHit] = useState({});
    const [load, setLoad] = useState(false);
    const [gameCode, setGameCode] = useState('');
    const [disBut, setDisBut] = useState(0);

    const [ballot, setBallot] = useState([
        { id: setKey(), value: '', maxLength: '1' },
        { id: setKey(), value: '', maxLength: '1' },
        { id: setKey(), value: '', maxLength: '1' },
        { id: setKey(), value: '', maxLength: '1' },
        { id: setKey(), value: '', maxLength: '1' },
        { id: setKey(), value: '', maxLength: '1' }
    ]);

    const ValidBall = (arr) => {
        let res = true;
        arr.map((item) => {
            const validLeng = arr.filter((item2) => item2 === item);
            if (validLeng.length > 1) { res = false; }
            return null;
        });
        return res;
    }

    const disabledButtonInGame = async () => {
        setDisBut(1);
        setTimeout(function () {
            setDisBut(0);
        }, 6000);
    }

    const PlayGame = async () => {
        const balls = ballot.map((item) => item.value);
        if (balls.includes("")) { Notify.failure("Debe completar todas las casillas"); return }
        if (!ValidBall(balls)) { Notify.failure("Se encontraron valores repetidos"); return }

        setLoad(true);
        try {
            const data = {
                'move1': balls[0],
                'move2': balls[1],
                'move3': balls[2],
                'move4': balls[3],
                'move5': balls[4],
                'move6': balls[5],
                'game_type_id': 1,
                'code': gameCode
            };
            const game = (auth && token) ? await makeFullGame(token, data) : await makePublicGame(data);

            if (game?.data.success) {
                Notify.success(game?.data.message);
                disabledButtonInGame();
                setResult(game?.data.data.result);
                setGame(game?.data.data.game);
                setConfirmHit(game?.data.data.confirm_hit);
                if (!(auth && token)) {
                    setWinData(game?.data.data);
                }
                if (game?.data.data.confirm_hit.win && !game?.data.data.confirm_hit.need_access) {
                    notifyUserWin(game?.data.data);
                }
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setLoad(false);
            if (auth && token) {
                validateUser();
            }
        }
    }

    const notifyUserWin = async (dat) => {
        try {
            const response = await sentWinnerMail(token, dat);
            console.log(response);
        } catch (error) {
            validErrors(error);
        }
    }

    return (
        <>
            {load && (
                <Loader />
            )}

            <ContainerBackGround>
                <ImgBackground src={BackGroundImg} />
            </ContainerBackGround>

            <Container sx={{
                position: 'relative',
                maxHeight: '100%',
                overflow: 'scroll'
            }}>
                {token == undefined && (
                    <ContentLogin>
                        <Button sx={{
                            position: 'relative',
                            top: '1rem',
                            left: '0rem',
                        }}
                            variant={'contained'}
                            color={'error'}
                            onClick={handleOpen}
                            size={'large'}
                        >
                            Ingresar
                        </Button>
                    </ContentLogin>
                )}

                <Grid container spacing={2} marginTop={'1rem'}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'} >
                        {token ? (
                            <TextAmount onClick={() => setOpenPay(true)}>
                                <Typography textAlign={'center'} variant={'h6'} component={'spam'} fontWeight={'500'}>Tienes {auth?.moves ?? 0} jugadas para realizar</Typography>
                                <Typography textAlign={'center'}>Recarga tu cuenta aqui.</Typography>
                            </TextAmount>
                        ) : (
                            <TextAmount>
                                <TextField
                                    fullWidth
                                    label={'Ingresa tu código'}
                                    placeholder={'Ingresa tu código'}
                                    value={gameCode}
                                    autoComplete={false}
                                    onChange={(e) => setGameCode(e.target.value.toUpperCase())}
                                    maxLength={10}
                                />
                            </TextAmount>
                        )}
                    </Grid>
                    {/* GAME */}
                    <Grid item xs={12}>
                        {/* BALOTAS */}
                        <Box display={'flex'} justifyContent={'center'}>
                            <Resultado result={result} game={game} confirmHit={confirmHit} handleOpen={handleOpen} setCodeWin={setCodeWin} />
                        </Box>
                    </Grid>

                    {/* INPUTS */}
                    <Grid item xs={12}>
                        <InputGame
                            ballot={ballot}
                            setBallot={setBallot}
                            ValidBall={ValidBall}
                            disabled={disBut}
                        />

                        <Box className='animate__animated animate__backInDown animate__delay-1s'
                            display={'flex'}
                            justifyContent={'center'}
                            padding={'1rem 0'}>
                            <Box sx={{ width: '90px' }}>
                                <PlayButton
                                    color='danger'
                                    content={'Play'}
                                    action={() => PlayGame()}
                                    disabled={disBut}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box display={'flex'} justifyContent={'center'} mt={'2rem'} mb={'2rem'}>
                    <AwardsTable />
                </Box>
            </Container>

            {/* ADD MONEY */}
            <Modal open={openPay}>
                <AddMoneyCard setOpenPay={setOpenPay} />
            </Modal>
        </>
    )
}

const rotate = keyframes`
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
`
const TextAmount = styled.div`
    font-size: 1.4rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    line-height: 1.334;
    color: #333;
    background: #FFF;
    padding: 8px;
    border: 3px solid #d62222;
    border-radius: 5px;
    font-weight: 400;
    cursor: pointer;
    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`
const ContainerBackGround = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .4;
    overflow: hidden;
`
const ImgBackground = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    scale: 2.3;
    filter: saturate(500%);
    animation: ${rotate} 60s linear infinite;
`
const ContentLogin = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
        justify-content: center;
    }
`

export default Game