import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getGamesByUser } from '../../../data/api/game/GameRoutes';
import styled from 'styled-components';
import useIndex from '../../../hooks/useIndex';
import GameResume from '../game/GameResume';
import { useDebounce } from 'react-use';
import validErrors from '../../../hooks/validErrors';

const History = ({
    user_search
}) => {
    const { auth, token } = useIndex();

    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [gameResult, setGameResult] = useState(null);
    const [open, setOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [codeSearch, setCodeSearch] = useState('');
    const [hitsSearch, setHitSearch] = useState('t');
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        loadData(hitsSearch, rowsPerPage, page, codeSearch);
    }, [rowsPerPage, page, hitsSearch]);

    const [isReady, cancel] = useDebounce(() => {
        setPage(1);
        loadData(hitsSearch, rowsPerPage, page, codeSearch);
    }, 1000, [codeSearch]);

    useEffect(() => {
        cancel();
    }, []);

    const loadData = async (h, rpp, p, cs) => {
        let userId = user_search ?? (auth?.role_id == process.env.REACT_APP_ADMIN_ROLE ? '0' : auth?.id);
        setLoadingData(true);

        try {
            const response = await getGamesByUser(token, h, userId, rpp, p, cs);
            if (response?.data.success) {
                setData(response?.data)
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setLoadingData(false);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setGameResult(null);
    };

    return (
        <>
            {loadingData ? (
                <Container sx={{
                    padding: '3rem 1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress color="error" />
                </Container>
            ) : (
                <Container sx={{ padding: '1rem' }}>
                    {!user_search && (
                        <Typography variant='h3' textAlign={'center'} marginBottom={'1.5rem'}>Revisa tus jugadas</Typography>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                boxShadow: '0px 0px 11px 1px rgba(51,51,51,.4)',
                                borderRadius: '5px',
                                padding: '1rem',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                background: '#FFF'
                            }}>
                                <Stack direction="row" spacing={1} marginBottom={'1rem'}>
                                    <Chip label={`Jugadas: ${data?.meta.total}`} color="info" />
                                </Stack>

                                {auth?.role_id == process.env.REACT_APP_ADMIN_ROLE && (
                                    <FormControl>
                                        <InputLabel id="hits">Aciertos</InputLabel>
                                        <Select
                                            labelId='hits'
                                            id="hits_search"
                                            value={hitsSearch}
                                            size="small"
                                            label="Aciertos"
                                            onChange={(e) => setHitSearch(e.target.value)}
                                        >
                                            <MenuItem value={'t'}>Todas</MenuItem>
                                            <MenuItem value={'0'}>0 Aciertos</MenuItem>
                                            <MenuItem value={'1'}>1 Aciertos</MenuItem>
                                            <MenuItem value={'2'}>2 Aciertos</MenuItem>
                                            <MenuItem value={'3'}>3 Aciertos</MenuItem>
                                            <MenuItem value={'4'}>4 Aciertos</MenuItem>
                                            <MenuItem value={'5'}>5 Aciertos</MenuItem>
                                            <MenuItem value={'6'}>6 Aciertos</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}

                                <TextField
                                    id="search_code"
                                    label="Codigo"
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={codeSearch}
                                    onChange={(e) => setCodeSearch(e.target.value.toUpperCase())}
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        maxWidth: '400px'
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ boxShadow: '0px 0px 11px 1px rgba(51,51,51,.4)', borderRadius: '5px', background: '#FFF' }}>
                                <TableContainer sx={{ maxHeight: user_search ? '40vh' : '80vh', maxWidth: '100%', borderRadius: '5px 5px 0 0' }}>
                                    <Table stickyHeader aria-label="result table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>ID</strong></TableCell>
                                                {user_search ? (
                                                    <>
                                                        <TableCell><strong>Jugada</strong></TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableCell><strong>Jugada1</strong></TableCell>
                                                        <TableCell><strong>Jugada2</strong></TableCell>
                                                        <TableCell><strong>Jugada3</strong></TableCell>
                                                        <TableCell><strong>Jugada4</strong></TableCell>
                                                        <TableCell><strong>Jugada5</strong></TableCell>
                                                        <TableCell><strong>Jugada6</strong></TableCell>
                                                    </>
                                                )}
                                                <TableCell><strong>Aciertos</strong></TableCell>
                                                <TableCell><strong>Codigo</strong></TableCell>
                                                {!user_search && auth?.role_id == process.env.REACT_APP_ADMIN_ROLE && (
                                                    <TableCell><strong>Usuario</strong></TableCell>
                                                )}
                                                <TableCell><i className="fa-regular fa-eye"></i></TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {data ? data?.data.map((item) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={item?.id}>
                                                        <TableCell size={'small'} >{item?.id}</TableCell>
                                                        {user_search ? (
                                                            <>
                                                                <TableCell size={'small'} >{item?.move1} - {item?.move2} - {item?.move3} - {item?.move4} - {item?.move5} - {item?.move6}</TableCell>
                                                                <TableCell size={'small'} >{item?.hits ?? 0}</TableCell>
                                                                <TableCell size={'small'} >{item?.code}</TableCell>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TableCell>{item?.move1}</TableCell>
                                                                <TableCell>{item?.move2}</TableCell>
                                                                <TableCell>{item?.move3}</TableCell>
                                                                <TableCell>{item?.move4}</TableCell>
                                                                <TableCell>{item?.move5}</TableCell>
                                                                <TableCell>{item?.move6}</TableCell>
                                                                <TableCell>{item?.hits ?? 0}</TableCell>
                                                                <TableCell>{item?.code}</TableCell>
                                                                {auth?.role_id == process.env.REACT_APP_ADMIN_ROLE && (
                                                                    <TableCell size={user_search ? 'small' : 'medium'} >{item?.user}</TableCell>
                                                                )}
                                                            </>
                                                        )}
                                                        <TableCell size={user_search ? 'small' : 'medium'} >
                                                            <Button
                                                                sx={{
                                                                    minWidth: '0'
                                                                }}
                                                                size="small"
                                                                variant={'outlined'}
                                                                onClick={() => { setGameResult(item); handleOpen(); }}
                                                            >
                                                                <i className="fa-regular fa-eye"></i>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }) : (
                                                <TableRow hover role="checkbox" tabIndex={-1}>
                                                    <TableCell colSpan={10} align={'center'}>No hay registros.</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>



                                <Stack spacing={2} sx={{ borderTop: '1px solid rgba(0,0,0,.1)', borderRadius: '0 0 5px 5px' }}>
                                    <PaginationContainer>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <Typography variant={'subtitle2'}>Filas:</Typography>
                                            <FormControl sx={{ m: 1, maxWidth: 100 }}>
                                                <Select
                                                    size="small"
                                                    defaultValue={rowsPerPage}
                                                    onChange={handleChangeRowsPerPage}
                                                    autoWidth
                                                >
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={25}>25</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Pagination
                                            count={data?.meta.last_page}
                                            showFirstButton
                                            showLastButton
                                            defaultPage={page}
                                            onChange={handleChangePage}
                                        />
                                    </PaginationContainer>
                                </Stack>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <GameResume
                    gameResult={gameResult}
                    handleClose={handleClose}
                />
            </Modal>
        </>
    )
}

export default History

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`