import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';

const SellerConfig = ({
    register,
    panel,
    handleExpand,
    errors
}) => {
    const [errorSection, setErrorSection] = useState(false);

    useEffect(() => {
        if (
            errors.fbc_name
            || errors.fbc_address
            || errors.fbc_email
            || errors.fbc_phone
        ) {
            setErrorSection(true);
        } else {
            setErrorSection(false);
        }
    }, [
        errors.fbc_name,
        errors.fbc_address,
        errors.fbc_email,
        errors.fbc_phone,
    ]);

    return (
        <Accordion expanded={panel == 'Seller'} onChange={handleExpand('Seller')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography color={errorSection ? 'error' : 'default'}>Datos de Vendedor</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {/* SELLER NAME */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Vendedor'}
                            placeholder={'Vendedor'}
                            defaultValue={'Fenabocol'}
                            {...register("fbc_name", { required: "Este campo es obligatorio" })}
                            error={errors.fbc_name}
                        />
                        {errors.fbc_name && <Typography variant='body2' color={'error'} fontSize={'12px'}>{errors.fbc_name.message}</Typography>}
                    </Grid>

                    {/* SELLER ADDRESS */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Dirección Fenabocol'}
                            placeholder={'Dirección Fenabocol'}
                            defaultValue={'Calle 43a sur #78g - 28 Bogotá'}
                            {...register('fbc_address', { required: "Este campo es obligatorio" })}
                            error={errors.fbc_address}
                        />
                        {errors.fbc_address && <Typography variant='body2' color={'error'} fontSize={'12px'}>{errors.fbc_address.message}</Typography>}
                    </Grid>

                    {/* SELLER EMAIL */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Correo Fenabocol'}
                            placeholder={'Correo Fenabocol'}
                            defaultValue={'fenabocolorg@gmail.com'}
                            {...register('fbc_email', { required: "Este campo es obligatorio" })}
                            error={errors.fbc_email}
                        />
                    </Grid>

                    {/* SELLER PHONE */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={'Telefono Fenabocol'}
                            placeholder={'Telefono Fenabocol'}
                            defaultValue={'3002910614'}
                            {...register('fbc_phone', { required: "Este campo es obligatorio" })}
                            error={errors.fbc_phone}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default SellerConfig