import { Box, Typography } from '@mui/material'
import { Confirm, Loading, Notify } from 'notiflix'
import React, { createRef } from 'react'
import styled from 'styled-components'
import { forgotPassword } from '../../data/api/auth/AuthRoutes'
import useAuth from '../../hooks/useAuth'
import validErrors from '../../hooks/validErrors'

const Login = ({
    codeWin,
    winData
}) => {

    const { login } = useAuth();

    const emailRef = createRef();
    const passwordRef = createRef();
    const sessionRef = createRef();

    const handleSubmit = async e => {
        e.preventDefault();
        const data = {
            email: emailRef.current.value,
            password: passwordRef.current.value,
            session: sessionRef.current.checked,
            codeWin: codeWin,
            winData: winData,
        }
        login(data);
    }

    const forgotPass = () => {
        Confirm.prompt(
            '¿Olvidaste la contraseña?',
            'Ingresa tu correo electronico.',
            '',
            'Enviar',
            'Cancelar',
            (email) => {
                sendEmailForgot(email)
            },
            () => {}
        );
    }

    const sendEmailForgot = async (email) => {
        Loading.hourglass();
        try {
            const { data } = await forgotPassword({'email': email});

            if (data?.success) {
                Notify.success('Se ha enviado a tu correo un link para cambiar tu contraseña.');
            }
        } catch (error) {
            validErrors(error);
        } finally {
            Loading.remove(3000);
        }
    }

    return (
        <TabPane className={`tab-pane`} id="login">
            <form className="login-form" onSubmit={handleSubmit} noValidate>
                <Box className="relative">
                    <input
                        className="input-lg"
                        id="email"
                        name='email'
                        placeholder="E-mail Address"
                        type="email"
                        ref={emailRef}
                    />
                    <i className="fa fa-user"></i>
                </Box>
                <Box className="relative">
                    <input
                        className="input-lg"
                        id="password"
                        name='password'
                        placeholder="Password"
                        type="password"
                        ref={passwordRef}
                    />
                    <i className="fa fa-lock"></i>
                </Box>
                <Box className="">
                    <button className="btn-success btn-lg" type="submit">Login</button>
                </Box>
                <Box className="checkbox">
                    <input
                        id="stay-sign"
                        name='stay-sign'
                        type="checkbox"
                        ref={sessionRef}
                        defaultChecked
                    />
                    <label htmlFor="stay-sign">Mantener sesión iniciada</label>
                </Box>
                <hr />
                <Box>
                    <Typography
                        textAlign={'center'}
                    >
                        <a href='#/' onClick={forgotPass}>¿Olvidaste tu contraseña?</a>
                    </Typography>
                </Box>
            </form>
        </TabPane>
    )
}

export default Login

const TabPane = styled.div`
    .relative {
        position: relative;
    }
    .login-form {
        .form-group {
            margin-right: 0;
            margin-left: 0;
        }

        i {
            position: absolute;
            top: 0;
            left: 19px;
            line-height: 52px;
            color: rgba(40, 52, 67, 1);
            z-index: 100;
            font-size: 16px;
        }

        .input-lg {
            width: 100%;
            font-size: 16px;
            height: 52px;
            padding: 10px 25px;
            margin-bottom: .6rem;
            border-radius: 0;
        }
    }
    .btn-success {
        width: 100%;
        background-color: #25a08d;
        background-image: none;
        padding: 8px 50px;
        margin: 20px 0px;
        border-radius: 40px;
        border: 1px solid #25a08d;
        -webkit-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        color: #FFF;

        &:focus, &:hover {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
        }

        &.active {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;

            &:hover {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
            }
        }

        &:active {
            &:hover, &:focus {
            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
            }

            background-color: #25a08d;
            border-color: #25a08d;
            box-shadow: 0px 5px 35px -5px #25a08d;
            text-shadow: 0 0 8px #fff;
            outline: none;
        }
    }
`