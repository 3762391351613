import { Container, Typography } from '@mui/material'
import React from 'react'

const TermsAndConditions = () => {
  return (
    <Container sx={{
      padding: '3rem 0'
    }}>
      <Typography variant='h2' component={'h1'} textAlign={'center'}>Terminos y Condiciones</Typography>
      <br/>

      <Typography component={'p'}>Estos Términos y Condiciones de Uso (en adelante, "Términos de Uso") rigen el acceso y uso de nuestra página web y servicios (en adelante, "Servicios”), Incluyendo cualquier documento sensible. Al acceder y utilizar nuestros Servicios, usted acepta estar obligado por estos Términos de Uso, así como por la Ley 1581 de 2012 de Colombia y sus reglamentaciones.</Typography>
      <Typography component={'p'}>Al acceder y utilizar nuestros Servicios, acepta estar obligado por estos Términos y cualquier otra política o normativa a la que se haga referencia en ellos. Si no está de acuerdo con estos Términos, le pedimos que abandone inmediatamente nuestra página web y que no utilice ninguno de nuestros Servicios. Por lo anterior, se recomienda la visita periódica a la sección de términos y condiciones.</Typography>

      <br/>
      <Typography component={'p'}>El usuario será responsable por cualquier uso indebido, anormal o ilícito que se haga dé y en El Sitio y sus contenidos. En especial, el usuario se compromete a no:</Typography>
      <ul>
        <li>No descargar las imágenes, textos y demás contenido publicado en EL SITIO, a menos que expresamente se mencione lo contrario.</li>
        <li>No utilizar contenidos (imágenes, textos, vídeos) para publicidad sin previa autorización.</li>
        <li>No realizar actividades técnicas que impidan la libre administración, uso y publicación de los contenidos por parte de EL SITIO debidamente autorizados por LA EMPRESA.</li>
        <li>No incurrir en conductas ilícitas como daños o ataques informáticos, interceptación de comunicaciones, infracciones al derecho de autor, uso no autorizado de terminales, usurpación de identidad, revelación de secretos o falsedad en los documentos.</li>
        <li>No alterar el uso de las marcas, logos y demás signos distintivos, tanto de LA EMPRESA como de terceros que aparezcan en El Sitio. En todo caso, el usuario entiende y acepta que LA EMPRESA se reserva el derecho de tomar acciones de índole administrativo y legal contra cualquier usuario que, a juicio de la entidad responsable, haga uso indebido de EL SITIO. Lo anterior implica que el usuario se hace responsable de indemnizar cualquier daño causado tanto a la entidad responsable como a terceros que vean vulnerados sus derechos con su actuación.</li>
      </ul>

      <Typography variant='h5'>Protección de Datos Personales</Typography>
      <Typography component={'p'}>
        En cumplimiento de la Ley 1581 de 2012 de Colombia, informamos que todos los datos personales que usted nos proporciona al acceder y utilizar nuestros Servicios serán tratados de acuerdo con nuestra Política de Privacidad.<br/>
        Al aceptar estos Términos de Uso, usted reconoce y acepta que podemos recolectar, almacenar, usar y divulgar su información personal para los fines establecidos en nuestra Política de Privacidad.
      </Typography>
      <br/>
    
      <Typography variant='h5'>Uso de Nuestros Servicios</Typography>
      <Typography component={'p'}>
        Nuestros Servicios se ofrecen únicamente para su uso personal y no comercial. Usted no puede utilizar nuestros Servicios con fines comerciales sin nuestra autorización previa y por escrito.<br/>
        Usted es el único responsable de cualquier actividad realizada en su cuenta y de mantener la confidencialidad de su contraseña. Si tiene alguna sospecha de que su cuenta ha sido comprometida, debe notificarnos de inmediato.<br/>
        Usted acepta no utilizar nuestros Servicios para ningún propósito ilegal o no autorizado, incluyendo, entre otros, publicar contenido ilegal, difamatorio, ofensivo, obsceno o de cualquier otra manera inapropiado. Usted se compromete a cumplir con todas las leyes, normativas y requisitos aplicables al utilizar nuestros Servicios.<br/>
      </Typography>
      <br/>

      <Typography variant='h5'>Uso Aceptable</Typography>
      <Typography component={'p'}>
        Usted reconoce y acepta que nuestros Servicios son solo para uso personal y no comercial. Usted se compromete a no utilizar nuestros Servicios para fines ilegales, inapropiados o fraudulentos.<br/>
        Usted acepta no realizar actividades que puedan dañar, deshabilitar, sobrecargar o interferir con nuestros Servicios, incluyendo, entre otras, el uso de cualquier software o herramienta automatizada para acceder a nuestros Servicios.
      </Typography>
      <br/>

      <Typography variant='h5'>Emails, contenido y promociones</Typography>
      <Typography component={'p'}>Acepta recibir de vez en cuando nuestros mensajes y materiales de promoción, por correo postal, correo electrónico o cualquier otro formulario de contacto que nos proporciones (incluido tu número de teléfono para llamadas o mensajes de texto). Si no desea recibir dichos materiales o avisos de promociones, simplemente avísanos en cualquier momento.</Typography>
      <br/>

      <Typography variant='h5'>Contenido y Propiedad Intelectual</Typography>
      <Typography component={'p'}>Todo el contenido que se muestra en nuestros Servicios, incluyendo, entre otros, texto, gráficos, logotipos, imágenes, audio y video, es propiedad exclusiva nuestra o de nuestros licenciantes y está protegido por las leyes de propiedad intelectual aplicables. Usted acepta no copiar, reproducir, distribuir, transmitir, mostrar, vender, licenciar o explotar ningún contenido que se muestre en nuestros Servicios sin nuestra autorización previa y por escrito.</Typography>
      <br/>

      <Typography variant='h5'>Propiedad Intelectual</Typography>
      <Typography component={'p'}>
        Usted reconoce y acepta que todos los derechos de propiedad intelectual sobre nuestros Servicios, incluyendo, entre otros, marcas, nombres comerciales, diseños, patentes, derechos de autor y bases de datos, son propiedad exclusiva nuestra o de nuestros licenciantes.<br/>
        Usted acepta no reproducir, distribuir, modificar, adaptar, traducir, crear trabajos derivados, vender, alquilar, sublicenciar, transferir, publicar, exhibir, transmitir o explotar de cualquier otra manera nuestros Servicios o cualquier contenido relacionado, sin nuestro previo consentimiento por escrito.<br/>
        Este sitio Web incluye avisos sobre servicios y productos propios e información de copyright, cuyos términos deben observarse y seguirse. Ningún contenido de este sitio puede ser copiado, reproducido, recopilado, cargado, publicado, transmitido, distribuido, o utilizado para la creación de servicios derivados de la entidad responsable sin su consentimiento previo por escrito, salvo que LA EMPRESA le otorgue su permiso para acceder y visualizar las páginas Web dentro de este sitio, únicamente en su equipo y para su uso personal y no comercial.
      </Typography>
      <br/>

      <Typography variant='h5'>Responsabilidad</Typography>
      <Typography component={'p'}>Usted reconoce y acepta que utiliza nuestros Servicios bajo su propio riesgo. No seremos responsables de ninguna pérdida o daño, incluyendo, entre otros, daños directos, indirectos, consecuentes, especiales, ejemplares o punitivos, que surjan de su uso de nuestros Servicios o de cualquier interrupción o suspensión de nuestros Servicios.</Typography>
      <br/>

      <Typography variant='h5'>Indemnización</Typography>
      <Typography component={'p'}>Usted acepta indemnizarnos y defendernos, así como a nuestros afiliados, empleados, directores, agentes y licenciantes, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, presentados por cualquier tercero debido a su incumplimiento de estos Términos o su violación de cualquier ley o derecho de terceros.</Typography>
      <br/>

      <Typography variant='h5'>Modificaciones</Typography>
      <Typography component={'p'}>Nos reservamos el derecho de modificar estos Términos en cualquier momento y a nuestra entera discreción. Cualquier modificación entrará en vigor inmediatamente después de su publicación en nuestra página web. Le recomendamos que revise regularmente estos Términos para asegurarse de que está al tanto de cualquier cambio.</Typography>
      <br/>

      <Typography variant='h5'>Terminación</Typography>
      <Typography component={'p'}>
        Nos reservamos el derecho de cancelar o suspender su acceso a nuestros Servicios en cualquier momento y por cualquier motivo, sin previo aviso. En caso de cancelación o suspensión, su cuenta y todos los datos asociados con ella pueden ser eliminados permanentemente.<br/>
        Usted reconoce que no tendrá derecho a ninguna compensación por cualquier interrupción, cancelación o suspensión de nuestros Servicios, incluyendo cualquier pérdida de acceso o datos asociados.
      </Typography>
      <br/>

      <Typography variant='h5'>Ley Aplicable y Jurisdicción</Typography>
      <Typography component={'p'}>Estos Términos de Terminación se regirán e interpretarán de acuerdo con las leyes del país donde está ubicada nuestra empresa. Cualquier disputa que surja dé o en relación con estos Términos de Terminación estará sujeta a la jurisdicción exclusiva de los tribunales de ese país.</Typography>
      <br/>

      <Typography variant='h5'>Terminación de Acuerdo Completo</Typography>
      <Typography component={'p'}>Estos Términos de Terminación representan el acuerdo completo entre usted y nosotros con respecto a su acceso y uso de nuestros Servicios. Cualquier término o condición que sea declarado inválido o inaplicable por un tribunal no afectará la validez o aplicabilidad de los restantes términos y condiciones.</Typography>
      <br/>

      <Typography component={'p'}>
        LA EMPRESA es titular de todos los derechos sobre el software de la página Web, así como de los derechos de propiedad industrial e intelectual referidos a los contenidos que en ella se incluyan, a excepción de los derechos sobre productos y servicios que no son propiedad de esta Compañía, cuyas marcas están registradas a favor de sus respectivos titulares, y como tal son reconocidas por LA EMPRESA.<br/>
        LA EMPRESA no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad intelectual, secretos empresariales o sobre cualquier otra propiedad o derecho relacionado con la página Web y sus contenidos.
      </Typography>
      <br/>

      <Typography variant='h5'>Normas de Confidencialidad y Protección de Datos</Typography>
      <Typography component={'p'}>
        Se considera norma de confidencialidad y protección de datos toda aquella información personal que el usuario ingresa libre y voluntariamente a la red de LA EMPRESA, así como aquellas que son de obligatorio ingreso, tales como nombre de usuario y palabra clave (password). El usuario podrá ingresar sus datos personales durante su registro al sitio Web. El usuario puede modificar o actualizar parte de esta información en cualquier momento, de esta manera LA EMPRESA podrá brindarle servicios y contenidos mas acordes con su perfil.<br/>
        LA EMPRESA, se compromete a adoptar una política de confidencialidad y protección de datos, con el objeto de proteger la privacidad de la información personal obtenida a través de su Web Site.
      </Typography>
      <br/>

      <Typography variant='h5'>Protección de la Información Personal</Typography>
      <Typography component={'p'}>
        La información proporcionada por el usuario al registrarse en LA EMPRESA, está resguardada tecnológicamente y sólo podrán acceder a ella, el mismo usuario a través de una palabra clave (password) o en los casos en que el usuario lo requiera, los integrantes del área designada a esta tarea.<br/>
        El usuario es el único responsable de mantener su palabra clave (password) y la información de su cuenta. Para disminuir los riesgos LA EMPRESA recomienda al usuario salir de su cuenta y cerrar la ventana de su navegador cuando finalice su actividad, más aún si comparte su computadora con alguien o utiliza una computadora en un lugar público como una biblioteca o un ?café Internet?.<br/>
        Si bien LA EMPRESA posee un sistema de protección tecnológico que va desde sus servidores hasta la salida a Internet, ninguna transmisión por Internet puede garantizar su seguridad al 100%. LA EMPRESA no puede garantizar que la información ingresada a su sitio web o transmitida utilizando su servicio sea completamente segura, con lo cual el usuario corre su propio riesgo.
      </Typography>
      <br/>

      <Typography variant='h5'>Confidencialidad de la Información</Typography>
      <Typography component={'p'}>LA EMPRESA no compartirá ni revelará la información confidencial con terceros, excepto que tenga expresa autorización de quienes se suscribieron, o cuando ha sido requerido por orden judicial o legal, o para proteger los derechos de propiedad intelectual u otros derechos de LA EMPRESA.</Typography>
      <br/>

      <Typography variant='h5'>Aceptación de los términos</Typography>
      <Typography component={'p'}>
        Esta declaración de Confidencialidad y Protección de Datos está sujeta a los términos y condiciones de LA EMPRESA, con lo cual constituye un acuerdo legal entre el usuario y LA EMPRESA.<br/>
        Si el usuario utiliza los servicios de LA EMPRESA, significa que ha leído, entendido y aceptado los términos antes expuestos. Si no está de acuerdo con ellos, tiene la opción de no proporcionar ninguna información personal, o no utilizar el servicio de LA EMPRESA.
      </Typography>
    </Container>
  )
}

export default TermsAndConditions