import Notiflix from 'notiflix';
import React, { createContext, useEffect, useState } from 'react'
import { getUser } from '../data/api/auth/AuthRoutes';
import validErrors from '../hooks/validErrors';

const IndexContext = createContext();

const IndexProvider = ({children}) => {

    Notiflix.Notify.init({timeout: 5000, position: 'right-bottom'});
    Notiflix.Report.init({success: {backOverlayColor: 'rgba(0,0,0,0.5)'}});

    const [auth, setAuth] = useState();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem('AUTH_TOKEN') || sessionStorage.getItem('AUTH_TOKEN'));

    useEffect(() => {
        if (token) {
            setLoading(true);
            try {
                validateUser();
            } catch (error) {
                validErrors(error);
            } finally {
                setLoading(false);
            }
        }
    }, []);

    const validateUser = async () => {
        const { data } = await getUser(token);
        setAuth(data);
    }

    return (
        <IndexContext.Provider
            value={{
                auth,
                setAuth,
                loading,
                setLoading,
                token,
                setToken,
                validateUser
            }}
        >
            {children}
        </IndexContext.Provider>
    )
}

export {
    IndexProvider
}

export default IndexContext;