import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Switch, Typography } from '@mui/material'
import { Confirm, Report } from 'notiflix';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { getRoles } from '../../../../data/api/roles/RoleRoutes';
import { updateUser } from '../../../../data/api/users/UserRoutes';
import useIndex from '../../../../hooks/useIndex';
import validErrors from '../../../../hooks/validErrors';

const UserConfig = ({
    user,
    loadData
}) => {
    const { token } = useIndex();
    const { register, handleSubmit, setValue, reset } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [roles, setRoles] = useState([]);
    const [load, setLoad] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        loadRoles();
    }, []);

    const loadRoles = async () => {
        const { data } = await getRoles(token);

        if (data) {
            setRoles(data?.data);
        }
    }

    const sendUserData = async (dataSend) => {
        setLoad(true);
        try {
            const { data } = await updateUser(token, user?.id, dataSend);
            if (data?.success) {
                Report.success(
                    'Exito',
                    data?.message,
                    'Okay',
                    {
                        textAlign: 'center'
                    }
                );
                reset();
                loadData();
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setLoad(false);
        }
    }

    const onSubmit = async (dataForm) => {
        dataForm['document'] = user?.document;
        dataForm['document_type_id'] = user?.document_type_id;
        dataForm['email'] = user?.email;
        dataForm['name'] = user?.name;
        dataForm['phone'] = user?.phone;
        dataForm['game_enabled'] ? dataForm['game_enabled'] = 1 : dataForm['game_enabled'] = 0;

        if (dataForm['password'].trim() != '') {
            dataForm['password_confirmation'] = dataForm['password'];
        }

        Confirm.show(
            'Confirmar Cambios',
            'Estas seguro de realizar cambios',
            'Si',
            'No',
            () => sendUserData(dataForm),
            () => { }
        );
    }

    return (
        <Container>
            {/* USER INFO */}
            <Card sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                padding: '.5rem 0',
                flexWrap: 'wrap'
            }}>
                <CardContent>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant='h5' textAlign={'center'}>Datos de usuario</Typography>
                        </Grid>
                        {/* NOMBRE */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>Nombre:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.name} {user?.lastname}</Typography>
                            <Divider></Divider>
                        </Grid>
                        {/* CORREO */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>Correo:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.email}</Typography>
                            <Divider></Divider>
                        </Grid>
                        {/* TELEFONO */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>Telefono:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.phone}</Typography>
                            <Divider></Divider>
                        </Grid>
                        {/* DOCUMENTO */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>{user?.document_type_short}:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.document}</Typography>
                            <Divider></Divider>
                        </Grid>
                        {/* JUGADAS */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>Jugadas:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.moves}</Typography>
                            <Divider></Divider>
                        </Grid>
                        {/* ROL */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>Rol:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.role}</Typography>
                            <Divider></Divider>
                        </Grid>
                        {/* ESTADO */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>Estado:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.status_name}</Typography>
                            <Divider></Divider>
                        </Grid>
                        {/* HABILITADO PARA JUGAR */}
                        <Grid item xs={12}>
                            <Typography variant='subtitle' component={'span'}>Habilitado para jugar:</Typography> <Typography variant='subtitle2' component={'span'}>{user?.game_enabled_name}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Divider sx={{ margin: '1rem 0' }}><Typography color={'#CCC'}>Modificar Información</Typography></Divider>

            {/* CONFIG INFO */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>

                    {/* ROL */}
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="user_role_label">Rol</InputLabel>
                            <Select
                                labelId='user_role_label'
                                id="role_id"
                                value={user?.role_id}
                                label="Rol"
                                disabled
                                {...register('role_id')}
                            >
                                {roles?.map((item) => (
                                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="password">Contraseña</InputLabel>

                            <OutlinedInput
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Contraseña"
                                label="Contraseña"
                                {...register('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>

                    {/* USER STATUS */}
                    <Grid item xs={12} md={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch defaultValue={user?.status} defaultChecked={user?.status} {...register('status')} />}
                                label={
                                    <Typography>Puede acceder</Typography>
                                }
                            />
                            <FormControlLabel
                                control={<Switch defaultValue={user?.game_enabled} defaultChecked={user?.game_enabled} {...register('game_enabled')} />}
                                label={
                                    <Typography>Puede jugar</Typography>
                                }
                            />
                        </FormGroup>
                    </Grid>

                    {/* SAVE CONFIGURATION */}
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            loading={false}
                            loadingPosition="start"
                            startIcon={<i className="fa-solid fa-floppy-disk"></i>}
                            variant="contained"
                            size="small"
                            type='submit'
                        >
                            <span>Guardar</span>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default UserConfig