import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import LoveEarth from '../../assets/gif/LoveEarth.gif'
import ExpandGallery from '../../components/gallery/ExpandGallery'
import setKey from '../../hooks/setKey'

import FirManI1 from '../../assets/img/career-gd6019cac5_1280.jpg'
import FirManI2 from '../../assets/img/rescue-g8c01b5b97_1280.jpg'
import FirManI3 from '../../assets/img/fire-gffcacbf7c_1280.jpg'
import FirManI4 from '../../assets/img/vehicle-g4cbd79310_1280.jpg'
import FirManI5 from '../../assets/img/fire-g6d1eac978_1280.jpg'
import FirManI6 from '../../assets/img/extinguisher-g51873462c_1280.jpg'
import FirManI7 from '../../assets/img/turnbuckle-g5cd1b80e3_1280.jpg'
import FirManI8 from '../../assets/img/equipment-gc33d18c51_1280.jpg'
import FirManI9 from '../../assets/img/fire-fighters-g64d031898_1280.jpg'
import FirManI10 from '../../assets/img/extinguisher-g27e494cbe_1280.jpg'
import FirManI12 from '../../assets/img/machine-gea8e3ff50_1280.jpg'
import FirManI13 from '../../assets/img/pressurized-water-pipe-g442733aa4_1280.jpg'
import FirManI14 from '../../assets/img/signal-g12270fee8_1280.jpg'
import FirManI15 from '../../assets/img/slippery-gcb6acb070_1280.jpg'
import FirManI16 from '../../assets/img/volunteering-g16943517e_1280.jpg'
import FirManI17 from '../../assets/img/fire-engine-gebdbfac74_1280.jpg'
import FirManI18 from '../../assets/img/firefighter-g60ac6187d_1280.jpg'
import FirManI19 from '../../assets/img/fire-fighters-g7eb8391cc_1280.jpg'

const Services = () => {

    const FiremanCourseImage = [
        { id: setKey(), image: FirManI1 },
        { id: setKey(), image: FirManI2 },
        { id: setKey(), image: FirManI3 },
        { id: setKey(), image: FirManI4 },
        { id: setKey(), image: FirManI5 },
    ];
    const FiremanEquipImage = [
        { id: setKey(), image: FirManI6 },
        { id: setKey(), image: FirManI7 },
        { id: setKey(), image: FirManI8 },
        { id: setKey(), image: FirManI9 },
        { id: setKey(), image: FirManI10 },
    ];
    const FiremanMaintenanceImage = [
        { id: setKey(), image: FirManI12 },
        { id: setKey(), image: FirManI13 },
    ];
    const FiremanSignalsImage = [
        { id: setKey(), image: FirManI7 },
        { id: setKey(), image: FirManI14 },
        { id: setKey(), image: FirManI15 },
    ];
    const FiremanTrainingImage = [
        { id: setKey(), image: FirManI16 },
        { id: setKey(), image: FirManI17 },
        { id: setKey(), image: FirManI18 },
        { id: setKey(), image: FirManI19 },
    ];

    return (
        <Container maxWidth={'xl'}>
            <Box padding={'40px 0px'}>
                <Typography variant='h2' component={'h1'} textAlign={'center'}>Nuestros Servicios</Typography>

                <Grid container spacing={2} marginBottom={6}>
                    {/* BANER */}
                    <Grid item xs={12}>
                        <Typography
                            className='animate__animated animate__fadeIn'
                            textAlign={'justify'}
                        >
                            Tener equipos y elementos para la protección y prevención de desastres e incendios es fundamental para garantizar la seguridad de las personas y proteger sus bienes. Estos elementos incluyen equipos de extinción de incendios, detectores de humo, alarmas, equipos de evacuación y rescate, entre otros. Su importancia radica en que nos permiten actuar de manera rápida y efectiva ante una emergencia, minimizando los daños y evitando situaciones aún más catastróficas. Además, contar con estos elementos y equipos adecuados también puede reducir el riesgo de incendios y otros desastres, ya que nos permite detectar y prevenir situaciones de riesgo antes de que se conviertan en una emergencia. En resumen, la importancia de tener equipos y elementos para la protección y prevención de desastres e incendios radica en que nos permiten actuar de manera preventiva y efectiva, reducir los daños y garantizar la seguridad de las personas.
                        </Typography>
                    </Grid>
                </Grid>

                <GifDoc src={LoveEarth} alt={'Servicios Sociales'} />

                <Grid container spacing={6}>

                    {/* CURSO BOMBEROS */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: '0px 0px 15px -6px #333' }}>
                            <CardContent>
                                <Typography variant='h3' textAlign={'center'}>Cursos para Bomberos</Typography>
                                <Typography marginBottom={4}>
                                    Para nosotros es importante saber que nuestros Bomberos tienen la mejor capacitación, es por ello que nosotros contamos con varios cursos que mejoraran las habilidades y capacidades de los bomberos que nos brindan sus servicios.
                                </Typography>

                                <ExpandGallery
                                    Images={FiremanCourseImage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* EQUIPOS CONTRA INCENDIOS */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: '0px 0px 15px -6px #333' }}>
                            <CardContent>
                                <Typography variant='h3' textAlign={'center'}>Equipos contra incendio y su mantenimiento</Typography>
                                <Typography marginBottom={4}>
                                    Colombia es uno de los países con la mejor reglamentación de sismo resistencia para construcciones de la región. En ella destacan muchas medidas que se toman en el primer mundo, una, es la obligatoriedad de la implementación de sistemas contra incendios.
                                </Typography>

                                <ExpandGallery
                                    Images={FiremanEquipImage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* MANTENIMIENTO DE REDES */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: '0px 0px 15px -6px #333' }}>
                            <CardContent>
                                <Typography variant='h3' textAlign={'center'}>Mantenimiento de redes hidráulicas</Typography>
                                <Typography marginBottom={4}>
                                    El mantenimiento de las Redes Hidráulicas se debe hacer con frecuencia para de este modo evitar daños en las mismas, es por ello que aquí en Fenabocol ORG te brindamos este servicio.
                                </Typography>

                                <ExpandGallery
                                    Images={FiremanMaintenanceImage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* SEÑALIZACION */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: '0px 0px 15px -6px #333' }}>
                            <CardContent>
                                <Typography variant='h3' textAlign={'center'}>Señalización</Typography>
                                <Typography marginBottom={4}>
                                    Actualmente existen en Colombia leyes y resoluciones relacionadas con las normas en señalización, que buscan asegurar la integridad y salud del ser humano en el ámbito laboral y en lugares públicos, para ello Fenabocol ORG te ayuda a realizar la debida señalización.
                                </Typography>

                                <ExpandGallery
                                    Images={FiremanSignalsImage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* CAPACITACION */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: '0px 0px 15px -6px #333' }}>
                            <CardContent>
                                <Typography variant='h3' textAlign={'center'}>Capacitación para brigadas</Typography>
                                <Typography marginBottom={4}>
                                    Aquí en Fenabocol ORG nos comprometemos con nuestros brigadistas, es por ello que capacitamos a nuestro personal, así como brindamos el servicio para capacitar a otras personas que quieran ser brigadistas.
                                </Typography>

                                <ExpandGallery
                                    Images={FiremanTrainingImage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Box>
        </Container>
    )
}

export default Services

const GifDoc = styled.img`
  width: 100%;
  max-width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .3;
  z-index: -1;
`