import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useIndex from '../../hooks/useIndex'
import AuthLayout from '../auth/AuthLayout'
import Game from './game/Game'

const Ganacol = () => {

    const navigate = useNavigate();
    const { auth, token, loading } = useIndex();
    
    const [open, setOpen] = useState(false);
    const [codeWin, setCodeWin] = useState('');
    const [winData, setWinData] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (!loading && (auth?.id != undefined && token != undefined)) {
            navigate('/ganacol/dashboard');
        }
    }, [loading, auth]);

    return (
        <Box>
            <ContentGame>
                <Game handleOpen={handleOpen} setCodeWin={setCodeWin} setWinData={setWinData}/>
            </ContentGame>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AuthLayout codeWin={codeWin} winData={winData}/>
            </Modal>
        </Box>
    )
}

export default Ganacol

const ContentGame = styled.div`
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    min-height: 60vh;
    aspect-ratio: 16/9;
    &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
        opacity: 0;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
        opacity: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        opacity: 0;
        cursor: none;
    }
    @media (max-width: 768px) {
        min-height: 90vh;
    }
`