import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LatBar from './LatBar'
import NotificationBar from './NotificationBar'
import UpBar from './UpBar'
import userAvatar from '../../../assets/img/User-Avatar.png';
import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../../../context/AuthProvider'
import useIndex from '../../../hooks/useIndex'

const Dashboard = () => {
    const serverUrl = process.env.REACT_APP_BACK_URL;
    const { auth } = useIndex();
    const [activeBar, setActiveBar] = useState(false);
    const [activeNot, setActiveNot] = useState(false);
    const [disabledNot, setDisabledNot] = useState(false);
    const [userImage, setUserImage] = useState(auth?.avatar ?? userAvatar);

    useEffect(() => {
        setUserImage(serverUrl + auth?.avatar);
    }, [auth]);

    useEffect(() => {
        if (!activeNot) {
            setTimeout(() => {
                setDisabledNot(false);
            }, 500);
        } else {
            setDisabledNot(true);
        }
    }, [activeNot]);

    useEffect(() => {
        if (disabledNot) {
            document.addEventListener('click', function (event) {
                if (!(event.target.id !== 'contNotif' && event.target.id !== 'barNot')) {
                    setActiveNot(false);
                }
            });
        }
    }, [disabledNot]);

    return (
        <AuthProvider>


            <Box display={'flex'}>
                <NotifBarContainer id="contNotif" className={disabledNot && 'active'}>
                    <NotificationBar activeNot={activeNot} setActiveNot={setActiveNot} />
                </NotifBarContainer>
                <Box id="contLat">
                    <LatBar
                        activeBar={activeBar}
                        setActiveBar={setActiveBar}
                        userAvatar={userImage}
                    />
                </Box>
                <Box
                    flex={'1'}
                    display={'flex'}
                    flexDirection={'column'}
                    overflow={'hidden'}
                >
                    <UpBar
                        activeBar={activeBar}
                        setActiveBar={setActiveBar}
                        activeNot={activeNot}
                        setActiveNot={setActiveNot}
                        userAvatar={userImage}
                    />
                    <ContentDash flex={'1'}>
                        <Outlet />
                    </ContentDash>
                </Box>
            </Box>
        </AuthProvider>
    )
}

export default Dashboard

const NotifBarContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    z-index: 100;

    display: none;
    &.active {
        display: block;
    }
`

const ContentDash = styled.div`
    width: 100%;
    height: calc(100vh - 45px);
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    background: #F5F5F5;
`