import { TabContext, TabPanel } from '@mui/lab'
import { Box, CircularProgress, Grid } from '@mui/material'
import React from 'react'
import UserAlliance from './UserAlliance'
import UserConfig from './UserConfig'
import UserHistory from './UserHistory'
import UserStadistics from './UserStadistics'
import UserCollections from './UserCollections'

const UserTabsOption = ({
    loading,
    panel,
    user,
    loadData
}) => {
    return (
        <>
            {user?.role_id != 4 ? (
                <TabContext value={panel}>
                    {/* HISTORY */}
                    <TabPanel value={'1'}>
                        <Grid container spacing={2}>
                            {loading ? (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <UserHistory user={user} />
                            )}
                        </Grid>
                    </TabPanel>

                    {/* STADISTICS */}
                    <TabPanel value={'2'}>
                        <Grid container spacing={2}>
                            {loading ? (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <UserStadistics user={user} />
                            )}
                        </Grid>
                    </TabPanel>

                    {/* CONFIGURATION */}
                    <TabPanel value={'99'}>
                        <Grid container spacing={2}>
                            {loading ? (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <UserConfig user={user} loadData={loadData} />
                            )}
                        </Grid>
                    </TabPanel>
                </TabContext>
            ) : (
                <TabContext value={panel}>
                    {/* ALLIANCE */}
                    <TabPanel value={'1'}>
                        <Grid container spacing={2}>
                            {loading ? (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <UserAlliance user={user} loadUser={loadData} />
                            )}
                        </Grid>
                    </TabPanel>

                    {/* STADISTICS */}
                    <TabPanel value={'2'}>
                        <Grid container spacing={2}>
                            {loading ? (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <UserCollections />
                            )}
                        </Grid>
                    </TabPanel>

                    {/* STADISTICS */}
                    <TabPanel value={'3'}>
                        <Grid container spacing={2}>
                            {loading ? (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <UserStadistics user={user} />
                            )}
                        </Grid>
                    </TabPanel>

                    {/* CONFIGURATION */}
                    <TabPanel value={'99'}>
                        <Grid container spacing={2}>
                            {loading ? (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <UserConfig user={user} loadData={loadData} />
                            )}
                        </Grid>
                    </TabPanel>
                </TabContext>

            )}
        </>
    )
}

export default UserTabsOption