import clientAxios from "../config";

const makeFullGame = async (token, data) => clientAxios.post(`/api/game-ganacol`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const makePublicGame = async (data) => clientAxios.post(`/api/game-ganacol-public`, data);
const getGamesByUser = async (token, hits, userId, rowsPerPage, page, codeSearch = '') => clientAxios(`/api/game-ganacol-user/${hits}/${userId}/${rowsPerPage}${codeSearch && `/${codeSearch.toUpperCase()}`}?page=${page}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const getGameById = async (token, id) => clientAxios(`/api/game-ganacol/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});



export {
    makeFullGame,
    getGamesByUser,
    getGameById,
    makePublicGame,
}