import React, { useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Paper,
    Typography,
    Tab
} from '@mui/material';
import {
    TabList,
    TabPanel,
    TabContext
} from '@mui/lab';
import ValidCodeForm from './ValidCodeForm';

const AddMoneyCard = ({
    setOpenPay
}) => {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Grid
                width={500}
                maxWidth={'90%'}
            >
                <Paper>
                    <Card>
                        <CardContent>
                            <Typography variant={'h5'} textAlign={'center'}>Recarga tu cuenta</Typography>

                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="add_money" centered>
                                        <Tab label="Codigo" value="1" />
                                        <Tab label="PSE" value="2" />
                                        <Tab label="Tarjeta" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ValidCodeForm setOpenPay={setOpenPay} />
                                </TabPanel>
                                <TabPanel value="2"><Typography variant='h4' textAlign={'center'} color={'#CCC'}>Proximamente</Typography></TabPanel>
                                <TabPanel value="3"><Typography variant='h4' textAlign={'center'} color={'#CCC'}>Proximamente</Typography></TabPanel>
                            </TabContext>

                        </CardContent>

                        <CardActions>
                            <Button onClick={() => setOpenPay(false)}>Cerrar</Button>
                        </CardActions>
                    </Card>
                </Paper>
            </Grid>
        </Box>
    )
}

export default AddMoneyCard