import { Notify, Report } from 'notiflix';
import React, { useEffect, useState } from 'react'
import Balota from '../../../components/game/Balota';
import BalotaContent from '../../../components/game/BalotaContent';

const Resultado = ({
    result,
    game,
    confirmHit,
    handleOpen,
    setCodeWin
}) => {
    const [move1, setMove1] = useState('B');
    const [move2, setMove2] = useState('I');
    const [move3, setMove3] = useState('N');
    const [move4, setMove4] = useState('G');
    const [move5, setMove5] = useState('O');
    const [move6, setMove6] = useState('50');

    const [class1, setClass1] = useState('');
    const [class2, setClass2] = useState('');
    const [class3, setClass3] = useState('');
    const [class4, setClass4] = useState('');
    const [class5, setClass5] = useState('');
    const [class6, setClass6] = useState('');

    useEffect(() => {
        if (result?.move1) {
            setMove1('');
            setMove2('');
            setMove3('');
            setMove4('');
            setMove5('');
            setMove6('');

            setClass1('');
            setClass2('');
            setClass3('');
            setClass4('');
            setClass5('');
            setClass6('');
            aleatorBalls()
        }
    }, [result]);

    const aleatorBalls = () => {
        var iterador = 1;
        var characters = "BINGO1234567890";

        var each = setInterval(function () {
            var each2 = setInterval(() => {
                var ball = characters.charAt(Math.floor(Math.random() * characters.length));
                eval(`setMove${iterador}(ball)`);
            }, 30);

            setTimeout(function () {
                clearInterval(each2);
                if (game['move' + iterador] == result['move' + iterador]) {
                    eval(`setClass${iterador}("hit")`);
                }
                eval(`setMove${iterador}(result['move${iterador}'])`);
                iterador++;
            }, 860);
        }, [870]);

        setTimeout(function () {
            clearInterval(each);
        }, 5220);

        setTimeout(function () {
            const { win, need_access, title, message } = confirmHit;

            if (win) {
                if (need_access) {
                    setCodeWin(game?.code);
                    Report.success(title, message, 'Ok', handleOpen);
                } else {
                    Report.success(title, message, 'Ok');
                }
            } else {
                Notify.failure(message || 'Operación fallida');
            }
        }, 6090);
    }

    return (
        <BalotaContent>
            <Balota move={move1} classHit={class1} />
            <Balota move={move2} classHit={class2} />
            <Balota move={move3} classHit={class3} />
            <Balota move={move4} classHit={class4} />
            <Balota move={move5} classHit={class5} />
            <Balota move={move6} classHit={class6} />
        </BalotaContent>
    )
}

export default Resultado