import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import './index.css';
import 'animate.css';
import AppRoutes from './routes/AppRoutes';
import { IndexProvider } from './context/IndexProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <CssBaseline />
        <HashRouter>
            <IndexProvider>
                <AppRoutes />
            </IndexProvider>
        </HashRouter>
    </React.StrictMode>
);