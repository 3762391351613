import { Box, Button, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Balota from '../../../components/game/Balota'
import BalotaContent from '../../../components/game/BalotaContent'
import { getGameById } from '../../../data/api/game/GameRoutes'
import useIndex from '../../../hooks/useIndex'
import validErrors from '../../../hooks/validErrors'

const GameResume = ({
    gameResult,
    handleClose
}) => {

    const { token } = useIndex();
    const [game, setGame] = useState(null);

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        try {
            const {data} = await getGameById(token, gameResult?.id);
            if (data?.success) {
                setGame(data?.data)
            }
        } catch (error) {
            validErrors(error);
        }
    }

    return (
        <Card className='animate__animated animate__fadeInRight' 
        sx={{
            width: '90%', 
            maxWidth: '500px',
            paddingLeft: '1rem',
            paddingRight: '1rem',
        }}>
            <CardContent>

                <Box marginBottom={'1rem'}>
                    <Typography variant='h4' textAlign={'center'} marginBottom={'.5rem'}>Resultado</Typography>

                    {game ? (
                        <BalotaContent>
                            <Balota isModal={true} move={game?.result?.move1} />
                            <Balota isModal={true} move={game?.result?.move2} />
                            <Balota isModal={true} move={game?.result?.move3} />
                            <Balota isModal={true} move={game?.result?.move4} />
                            <Balota isModal={true} move={game?.result?.move5} />
                            <Balota isModal={true} move={game?.result?.move6} />
                        </BalotaContent>
                    ) : (
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" width={'100%'} height={60} />
                        </Stack>
                    )}
                </Box>

                <Box>
                    <Typography variant='h5' textAlign={'center'}>Tu jugada</Typography>
                    {game ? (
                        <ResultadoContent>
                            <Balota isModal={true} move={game?.move1} />
                            <Balota isModal={true} move={game?.move2} />
                            <Balota isModal={true} move={game?.move3} />
                            <Balota isModal={true} move={game?.move4} />
                            <Balota isModal={true} move={game?.move5} />
                            <Balota isModal={true} move={game?.move6} />
                        </ResultadoContent>
                    ) : (
                        <Stack spacing={1} marginBottom={'1rem'}>
                            <Skeleton variant="rectangular" width={'100%'} height={60} />
                        </Stack>
                    )}
                </Box>
                
                <Box sx={{marginBottom: '1rem'}}>
                    {game ? (
                        <DescriptionGame>
                            <li>Fecha: {game?.created_at}</li>
                            {/* <li>Costo: ${game?.payment?.amount}</li> */}
                            <li>Aciertos: {game?.result?.hits}</li>
                            <li>Codigo: {game?.code}</li>
                        </DescriptionGame>
                    ) : (
                        <Stack >
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </Stack>
                    )}
                </Box>

                <Box>
                    <Button variant={'outlined'} fullWidth onClick={handleClose}>Cerrar</Button>
                </Box>
            </CardContent>
        </Card>
    )
}

export default GameResume

const ResultadoContent = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 1rem;
    border: 3px solid #CCC;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5px;
    background: radial-gradient(circle, rgba(34,149,214,1) 0%, rgba(8,98,143,1) 100%);
`
const DescriptionGame = styled.ul`
    width: 100%;
    margin: 1rem 0 0 0;
    padding: .5rem;
    list-style: none;
    border: 1px solid #CCC;
    border-radius: 5px;
    color: #333;
`