import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Contact = () => {
    return (
        <Container maxWidth={'xl'}>
            <ContactPageSection className="contact-page-section">
                <Container sx={{
                    padding: 0
                }}>
                    <SectionTitle>
                        <h2>Contactanos</h2>
                    </SectionTitle>
                    <Box className="inner-container">
                        <Grid container spacing={2}>

                            {/* <!--Form Column--> */}
                            <Grid xs={12} md={8} sx={{ paddingTop: 0 }} className="form-column">
                                <Box className="inner-column">

                                    {/* <!--Contact Form--> */}
                                    <ContactForm>
                                        <form method="post" action="sendemail.php" id="contact-form">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={6} className="form-group">
                                                    <input type="text" name="name" value="" placeholder="Nombre" required />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} className="form-group">
                                                    <input type="email" name="email" value="" placeholder="Correo" required />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} className="form-group">
                                                    <input type="text" name="subject" value="" placeholder="Asunto" required />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} className="form-group">
                                                    <input type="text" name="phone" value="" placeholder="Telefono" required />
                                                </Grid>
                                                <Grid item xs={12} className="form-group">
                                                    <textarea name="message" placeholder="Mensaje"></textarea>
                                                </Grid>
                                                <Grid item xs={12} className="form-group">
                                                    <button type="submit" className="theme-btn btn-style-one">Enviar</button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </ContactForm>
                                    {/* <!--End Contact Form--> */}

                                </Box>
                            </Grid>

                            {/* <!--Info Column--> */}
                            <Grid item xs={12} md={4} className="info-column">
                                <Box className="inner-column">
                                    <h2>Información</h2>
                                    <ul className="list-info">
                                        <li><i className="fa-solid fa-location-dot"></i>Calle 43a sur #78g - 28</li>
                                        <li><i className="far fa-envelope"></i>febocolorg@gmail.com</li>
                                        <li><i className="fas fa-phone"></i>+57 300 2910614 <br /> +57 4536859</li>
                                    </ul>
                                    <SocialIcons>
                                        <li className="follow">Síganos en:</li>
                                        <li><a href="#/"><i className="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#/"><i className="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#/"><i className="fa-brands fa-instagram"></i></a></li>
                                        <li><a href="#/"><i className="fa-brands fa-youtube"></i></a></li>
                                    </SocialIcons>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </ContactPageSection>
        </Container>
    )
}

export default Contact

const ContactPageSection = styled.section`
    position:relative;
    padding-bottom: 40px;
    .inner-container {
        position:relative;
        z-index:1;
        background-color:#00b8ca;
        box-shadow:0 0 15px 5px rgba(0,0,0,.1)
    }
    .form-column {
        position:relative;
        padding:0 0 0 15px;
        .inner-column {
            position:relative;
            padding:60px 45px 30px;
            background-color:#fff
        }
    }
    .info-column {
        position:relative;
        .inner-column {
            position:relative;
            padding:60px 35px
        }
        h2 {
            position:relative;
            color:#fff;
            font-size:30px;
            font-weight:700;
            line-height:1.4em;
            margin-bottom:45px
        }
        .list-info {
            position:relative;
            margin-bottom:60px;
            li {
                position:relative;
                margin-bottom:25px;
                font-size:18px;
                color:#fff;
                line-height:1.8em;
                padding-left:45px;
                &:last-child {
                    margin-bottom:0;
                }
                i {
                    position:absolute;
                    left:0;
                    top:8px;
                    color:#fff;
                    font-size:30px;
                }
            }
        }
    }
`
const SectionTitle = styled.div`
    position:relative;
    h2 {
        position:relative;
        color:#252525;
        font-size:36px;
        font-weight:700;
        display:block;
    }
    &.light h2 {
        color:#fff
    }
`
const ContactForm = styled.div`
    position:relative;
    .form-group {
        position:relative;
        margin-bottom:20px
    }
    input[type=text], 
    input[type=email], 
    textarea {
        position:relative;
        display:block;
        width:100%;
        height:60px;
        color:#222;
        font-size:14px;
        line-height:38px;
        padding:10px 30px;
        border:1px solid #ddd;
        background-color:#fff;
        transition:all .3s ease;
        -ms-transition:all .3s ease;
        -webkit-transition:all .3s ease
    }
    input[type=text]:focus, 
    input[type=email]:focus, 
    textarea:focus {
        border-color:#00b8ca
    }
    textarea {
        height:250px;
        resize:none
    }
    .theme-btn {
        font-size:16px;
        font-weight:700;
        margin-top:10px;
        text-transform:capitalize;
        padding:16px 39px;
        border:2px solid #00b8ca;
        font-family:Arimo,sans-serif;
        background:#00b8ca;
        display:inline-block;
        position:relative;
        line-height:24px;
        cursor:pointer;
        color:#fff;
        &:hover {
            color:#00b8ca;
            border-color:#00b8ca;
            background:0 0;
        }
    }
    input.error,
    select.error,
    textarea.error {
        border-color:red!important
    }
    label.error {
        display:block;
        line-height:24px;
        padding:5px 0 0;
        margin:0;
        text-transform:uppercase;
        font-size:12px;
        color:red;
        font-weight:500
    }
`
const SocialIcons = styled.ul`
    position:relative;
    li {
        position:relative;
        margin-right:18px;
        display:inline-block;
        &.follow {
            color:#fff;
            font-weight:600;
            font-size:24px;
            display:block;
            margin-bottom:20px;
        }
        a {
            position:relative;
            font-size:20px;
            color:#fff;
            -webkit-transition:all .3s ease;
            -ms-transition:all .3s ease;
            -o-transition:all .3s ease;
            -moz-transition:all .3s ease;
            transition:all .3s ease;
            &:hover {
                color:#222;
            }
        }
    }
`