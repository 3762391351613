import { Box, Container, Grid, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SecureDocuments from '../../assets/gif/SecureDocuments.gif'
import { getAllDocuments } from '../../data/api/document/DocumentRoutes'
import validErrors from '../../hooks/validErrors'
import DocumentContainer from './DocumentContainer'

const Documents = () => {

    const [documents, setDocuments] = useState();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const { data } = await getAllDocuments();
            setDocuments(data?.data);
        } catch (error) {
            validErrors(error);
        }
    }

    return (
        <Container maxWidth={'xl'}>
            <Box>
                <Grid container className='animate__animated animate__fadeIn'>
                    <Typography variant='h2' component={'h1'}>Nuestros documentos</Typography>
                    <Typography component={'p'}>¡Bienvenido/a a nuestra sección de documentos! Aquí encontrarás toda la información que necesitas para estar al día con nuestros productos y servicios. Desde manuales de usuario hasta informes técnicos, ¡tenemos todo lo que necesitas para sacar el máximo provecho de nuestros productos! Navega por nuestra amplia selección de documentos y descarga lo que necesites en cuestión de segundos. ¡Gracias por elegirnos como tu proveedor de confianza!</Typography>
                    <Typography component={'p'}>Para nosotros es importante que confíes en nosotros, por lo cual aquí encontraras los documentos que nos representan legalmente ante entidades del estado.</Typography>
                    <Typography component={'p'}>Tambíen puedes mirar nuestros <Link to={'/terms-conditions'}>Terminos y Condiciones</Link> para informarte mejor.</Typography>
                </Grid>

                <Grid container padding={'4rem 0'}>
                    <Grid item xs={12} md={7}>
                        <DocumentContainer documents={documents} />
                    </Grid>

                    <Grid item xs={12} md={5} sx={{ paddingLeft: '2rem' }}>
                        <GifDoc src={SecureDocuments} alt={'Fenabocol Documents'} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Documents

const GifDoc = styled.img`
  width: 50%;
`