import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Container, FormControl, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Notify } from 'notiflix';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { getCodesByCollection, exportIncomeCodeCollection } from '../../../data/api/incomes/IncomeRoutes';
import useIndex from '../../../hooks/useIndex';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import validErrors from '../../../hooks/validErrors';

const GameCodesTable = ({
    collection,
    nameDoc
}) => {

    const { token } = useIndex();
    const [page, setPage] = useState(1);
    const [load, setLoad] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [codes, setCodes] = useState([]);
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        loadData(collection);
    }, [page, rowsPerPage]);

    const loadData = async (id) => {
        try {
            const { data } = await getCodesByCollection(token, id, rowsPerPage, page);
            if (data?.success) {
                setCodes(data);
                setLoad(true);
            }
        } catch (error) {
            validErrors(error);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const exportCollection = async () => {
        setExporting(true);
        try {
            const response = await exportIncomeCodeCollection(token, nameDoc, collection);

            // GET FILE NAME
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'income_collection.xlsx'; // DEFAULT VALUE
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }

            // MAKE TEMPORAL URL TO DOWNLOAD FILE
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // DOWNLOAD WITH FILENAME
            document.body.appendChild(link);
            link.click();

            // CLEAR URL OBJECT
            window.URL.revokeObjectURL(url);
        } catch (error) {
            validErrors(error);
        } finally {
            setExporting(false);
        }
    }


    return (
        <>
            {load ? (
                <Container>
                    <Box sx={{marginBottom: '.5rem'}}>
                        <LoadingButton
                            onClick={exportCollection}
                            loading={exporting}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="contained"
                        >
                            Excel
                        </LoadingButton>

                        <Button>PDF</Button>
                        {/* <Button>Excel</Button> */}
                    </Box>
                    <Box sx={{ border: '1px solid rgba(51,51,51,.4)', borderRadius: '5px' }}>
                        <TableContainer sx={{ maxHeight: '500px', maxWidth: '100%', borderRadius: '5px 5px 0 0' }}>
                            <Table stickyHeader aria-label="result table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>ID</strong></TableCell>
                                        <TableCell><strong>Codigo</strong></TableCell>
                                        <TableCell><strong>Estado</strong></TableCell>
                                        <TableCell><strong>Usuario</strong></TableCell>
                                        <TableCell><strong>Vigencia</strong></TableCell>
                                        <TableCell><strong>Asignado</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {codes?.data.map((item) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={item?.uuid}>
                                            <TableCell>{item?.id}</TableCell>
                                            <TableCell>{item?.code}</TableCell>
                                            <TableCell>{item?.status}</TableCell>
                                            <TableCell>{item?.user}</TableCell>
                                            <TableCell>{item?.validity}</TableCell>
                                            <TableCell>{item?.updated_at}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>



                        <Stack spacing={2} sx={{ borderTop: '1px solid rgba(0,0,0,.1)', borderRadius: '0 0 5px 5px' }}>
                            <PaginationContainer>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant={'subtitle2'}>Filas:</Typography>
                                    <FormControl sx={{ m: 1, maxWidth: 100 }}>
                                        <Select
                                            size="small"
                                            defaultValue={rowsPerPage}
                                            onChange={handleChangeRowsPerPage}
                                            autoWidth
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Pagination
                                    count={codes?.meta.last_page}
                                    showFirstButton
                                    showLastButton
                                    onChange={handleChangePage}
                                />
                            </PaginationContainer>
                        </Stack>
                    </Box>
                </Container>
            ) : (
                <>
                    <Box display={'flex'} justifyContent={'center'}>
                        <CircularProgress />
                    </Box>
                    <Typography textAlign={'center'}>Cargando...</Typography>
                </>
            )}
        </>
    )
}

export default GameCodesTable

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`