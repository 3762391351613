import clientAxios from "../config";

const getAllDocumentType = async () => clientAxios(`/api/document-type`);
const getAllDocuments = async () => clientAxios(`/api/documents`);
const saveDocument = async (token, data) => clientAxios.post(`/api/document`, data, {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": 'multipart/form-data'
    }
});
const deleteDocument = async (token, id) => clientAxios.delete(`/api/document/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export {
    getAllDocumentType,
    getAllDocuments,
    saveDocument,
    deleteDocument
}