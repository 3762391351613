import clientAxios from "../config";

const getAllPrizes = async (token) => clientAxios('/api/prize', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const addPrize = async (token, data) => clientAxios.post('/api/prize', data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const getPrize = async (token, id) => clientAxios(`/api/prize/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const updatePrize = async (token, id, data) => clientAxios.put(`/api/prize/${id}`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
const deletePrice = async (token, id) => clientAxios.delete(`/api/prize/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export {
    getAllPrizes,
    addPrize,
    getPrize,
    updatePrize,
    deletePrice,
}