import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useAuth from '../../../hooks/useAuth'
import useIndex from '../../../hooks/useIndex'

const UpBar = ({
    setActiveBar,
    activeBar,
    setActiveNot,
    activeNot,
    userAvatar
}) => {
    const { auth } = useIndex();
    const { logout } = useAuth();

    return (
        <UpBarCont>
            {/* <!-- TOGGLE BAR --> */}
            <Box className="tglBarLat" onClick={() => setActiveBar(!activeBar)}>
                <i className="fa-solid fa-bars togBarLat"></i>
            </Box>
            {/* <!-- NOTIFICATIONS --> */}
            <Box className="notifbar">
                {/* <!-- USER --> */}
                <Box className="user">
                    <Link to={'/ganacol/dashboard/configuration'} className="useLnk">
                        <span className="username">{auth?.name ?? 'User Name'}</span>
                        <span className="userimage">
                            <img src={userAvatar} alt="user-avatar" id="usrBarSup"/>
                        </span>
                    </Link>
                </Box>
                {/* <!-- NOTIFICATIONS --> */}
                <Box className="notBarSup">
                    <i className="fa-regular fa-bell" id="notifBarSupIc" onClick={() => setActiveNot(!activeNot)}></i>
                </Box>
                {/* <!-- HOME --> */}
                <Box className="notBarSup">
                    <Link to={'/'}>
                        <i className="fa-solid fa-house" id="homeBarSupIc"></i>
                    </Link>
                </Box>
                {/* <!-- EXIT --> */}
                <Box className="notBarSup">
                    <span onClick={logout}>
                        <i className="fa-solid fa-right-from-bracket" id="exitBarSupIc"></i>
                    </span>
                </Box>
            </Box>
        </UpBarCont>
    )
}

export default UpBar

const UpBarCont = styled.div`
    width: 100%;
    height: 45px;
    background: #333;
    position: relative;
    display: flex;
    justify-content: space-between;
    .notifbar {
        height: 100%;
        overflow: hidden;
        display: flex;
        .user{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
            &:hover{
                background: #1c1c1c;
                background: rgba(255,255,255,.1);
            }
            .useLnk{
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                .username {
                    margin-right: 10px;
                    color: #fff;
                    font-family: 'Lato';
                    letter-spacing: 1px;
                }
                .userimage {
                    width: 40px;
                    aspect-ratio: 1/1;
                    display: inline-block;
                    border: 1px solid #AAA;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .notBarSup {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
            a, span {
                text-decoration: none;
            }
            i {
                color: #fff;
                font-size: 25px;
                cursor: pointer;
                &:hover {
                    text-shadow: 0 0 3px #fff;
                }
            }
        }
    }
    .tglBarLat {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .5s;
        background: #333;
        color: #fff;
        font-size: 25px;
        cursor: pointer;
        i {
            transition: .2s;
        }
        &:hover {
            i {
                text-shadow: 0 0 2px #fff;
            }
        }
        &.act {
            i {
                transform: rotate(180deg);
            }
        }
    }
`