import { Box, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import userAvatar from '../../assets/img/User-Avatar.png';

const colorsHead = {
    "Administrador": "#a569bd",
    "Jugador": "#3490dc",
    "Empresa": "#2ecc71",
    "Public": "#82e0aa"
};
let us = [];

const UserCard = ({
    user
}) => {
    const navigate = useNavigate();

    const serverUrl = process.env.REACT_APP_BACK_URL;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <UserCardContent>
            <Box>

                <i className="options fas fa-ellipsis-v" onClick={handleClick}></i>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={() => navigate(`/ganacol/dashboard/users/${user?.uuid}`)}>Perfil</MenuItem>
                    <MenuItem onClick={handleClose}>Regalo</MenuItem>
                    <MenuItem onClick={handleClose}>Deshabilitar</MenuItem>
                </Menu>

            </Box>


            <Box className={"header " + user?.role}>
                <Box className="userview">
                    <Box>
                        <Box className="useravatar" onClick={() => navigate(`/ganacol/dashboard/users/${user?.uuid}`)}>
                            <img src={serverUrl + user?.avatar ?? userAvatar} alt="user-avatar" className="useravatarimage" />
                        </Box>
                    </Box>

                    <Box className="username">
                        <h2 onClick={() => navigate(`/ganacol/dashboard/users/${user?.uuid}`)}>{user?.name}</h2>
                        <p>{user?.role}</p>
                    </Box>
                </Box>
            </Box>

            <Box className="userbody">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '.75rem 1rem'
                }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{
                            display: 'flex',
                            width: '50%',
                            padding: '0 .5rem'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                width: '50px',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#FFF',
                                borderRadius: '50%',
                                backgroundColor: '#2779BD',
                                aspectRatio: '1/1'
                            }}>
                                <i className="fas fa-chart-line"></i>
                            </Box>

                            <Box sx={{ textAlign: 'right', marginLeft: '.5rem', width: 'calc(100% - 50px)' }}>
                                <h4>{user?.games ?? 0}</h4>
                                <p className="usertext">Juegos</p>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            width: '50%',
                            padding: '0 .5rem'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                width: '50px',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#FFF',
                                borderRadius: '50%',
                                backgroundColor: '#2779BD',
                                aspectRatio: '1/1'
                            }}>
                                <i className="fas fa-calendar"></i>
                            </Box>

                            <Box sx={{ textAlign: 'right', marginLeft: '.5rem', width: 'calc(100% - 50px)' }}>
                                <h4>{user?.user_days ?? 1}</h4>
                                <p className="usertext">Dias</p>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', marginTop: '1.5rem' }}>
                        <Box sx={{
                            display: 'flex',
                            width: '50%',
                            padding: '0 .5rem'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                width: '50px',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#FFF',
                                borderRadius: '50%',
                                backgroundColor: '#2779BD',
                                aspectRatio: '1/1'
                            }}>
                                <i className="fas fa-wallet"></i>
                            </Box>

                            <Box sx={{ textAlign: 'right', marginLeft: '.5rem', width: 'calc(100% - 50px)' }}>
                                <h4>{user?.moves ?? 0}</h4>
                                <p className="usertext">Codigos</p>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            width: '50%',
                            padding: '0 .5rem'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                width: '50px',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#FFF',
                                borderRadius: '50%',
                                backgroundColor: '#2779BD',
                                aspectRatio: '1/1'
                            }}>
                                <i className="fas fa-trophy"></i>
                            </Box>

                            <Box sx={{ textAlign: 'right', marginLeft: '.5rem', width: 'calc(100% - 50px)' }}>
                                <h4>{user?.hits ?? 0}</h4>
                                <p className="usertext">Aciertos</p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>


        </UserCardContent>
    )
}

export default UserCard

const UserCardContent = styled.div`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    transform: scale(.97);
    max-width: 290px;
    height: 312px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
    position: relative;
    border-radius: .25rem;
    background: #FFF;
    h4 {
        color: #606F7B;
        font-weight: bold;
        font-size: 1.2rem;
    }
    .usertext {
        font-size: .875rem;
        color: #8795A1;
    }
    i.options {
        position: absolute;
        top: -0.5rem;
        right: -0.8rem;
        cursor: pointer;
        margin-right: 1rem;
        margin-top: .75rem;
        color: #FFF;
        font-size: 1.5rem;
        border-radius: 50%;
        padding: .4rem 1rem;
        transition: all .3s;
        &:hover {
            background: rgba(255,255,255,.3);
        }
    }
    .header {
        height: 6rem;
        border-radius: .25rem .25rem 0 0;
        background-color: #dcd934;
        &.Administrador {background-color: #2ecc71;}
        &.Jugador {background-color: #3490dc;}
        &.Empresa {background-color: #a569bd;}
        &.Public {background-color: #CCCCCC;}
        
        .userview {
            padding-top: 3rem;
            display: flex;
            align-items: center;
            .useravatar {
                width: 6rem;
                height: 6rem;
                margin-left: 1rem;
                border: 1px solid #FFF;
                border-radius: 1000px;
                aspect-ratio: 1/1;
                position: relative;
                background: #333;;
                transition: all .3s ease;
                img.useravatarimage {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    border: 1px solid #FFF;
                    border-radius: 1000px;
                }
                &:hover {
                    width: 6.3rem;
                    height: 6.3rem;
                    cursor: pointer;
                }
            }
            .username {
                width: 150px;
                margin-left: .7rem;
                h2 {
                    width: 150px;
                    margin-top: -.5rem;
                    font-size: 1.3rem;
                    color: #F1F5F8;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                p {
                    width: 150px;
                    font-size: .875rem;
                    color: #8795A1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .userbody {
        margin-top: 3rem;
    }
`