import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import GameCodesForm from './GameCodesForm';
import GameCodesTable from './GameCodesTable';
import { getAllIncomeCollection } from '../../../data/api/incomes/IncomeRoutes';
import useIndex from '../../../hooks/useIndex';
import moneyFormat from '../../../hooks/moneyFormat';
import validErrors from '../../../hooks/validErrors';

const GameCodes = () => {

    const { auth, token } = useIndex();
    const [expanded, setExpanded] = useState(false);
    const [collection, setCollection] = useState(false);
    const [addGameCodes, setAddGameCodes] = useState(false);
    const [myCollections, setMyCollections] = useState([]);
    const [loadingCollections, setLoadingCollections] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setAddGameCodes(false);
    }, [myCollections]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setCollection(panel);
    };

    const loadData = async () => {
        setLoadingCollections(true);
        try {
            const { data } = await getAllIncomeCollection(token);
            if (data?.success) {
                setMyCollections(data?.data);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setLoadingCollections(false);
        }
    }

    return (
        <Container sx={{ padding: '1rem' }}>
            <Typography variant={'h4'} component={'h2'} textAlign={'center'} marginBottom={'1rem'}>GameCodes <i className="fa-regular fa-file-code"></i></Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion
                        expanded={addGameCodes}
                        onChange={() => setAddGameCodes(!addGameCodes)}
                        sx={{
                            boxShadow: '0px 0px 11px 1px rgba(51,51,51,.4)',
                            backgroundColor: '#FFF'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<Button variant={'contained'} sx={{ padding: '5px', margin: 0, minWidth: 0, borderRadius: '50%' }}><AddIcon /></Button>}
                            aria-controls="panel1bh-content"
                            id="add_game_code"
                        >
                            <Typography variant='h6' sx={{ width: '33%', flexShrink: 0 }}>
                                Agregar
                            </Typography>
                            <Typography sx={{ color: 'text.secondary', margin: 'auto 0' }}>Crear nueva colección de codigos</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            {addGameCodes && (
                                <GameCodesForm loadData={loadData}/>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>


                <Grid item xs={12}>
                    <Divider sx={{ marginBottom: '1rem' }}><Typography color={'#CCC'}>Mis Colecciones</Typography></Divider>
                    
                    {loadingCollections ? 
                        (<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}><CircularProgress color="error" /></Box>):
                        (
                            <>
                                {myCollections.map((item) => (
                                    <Accordion
                                        expanded={expanded === item?.id}
                                        onChange={handleChange(item?.id)}
                                        key={item?.uuid}
                                        sx={{
                                            boxShadow: '0px 0px 11px 1px rgba(51,51,51,.4)',
                                            backgroundColor: '#FFF',
                                            margin: '.5rem 0',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                {item?.user_id != auth?.id && ('(Externo) - ')}
                                                {item?.name}
                                            </Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>{item?.code_count} codigos - Valor x {item?.code_amount ?? 0} ({moneyFormat(item?.total_amount)})</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {expanded === item?.id && (
                                                <GameCodesTable
                                                    collection={collection}
                                                    nameDoc={item?.name}
                                                />
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </>
                        )
                    }
                </Grid>
            </Grid>
        </Container>
    )
}

export default GameCodes