import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { loadCompanyAgreements } from '../../../data/api/agreements/AgreementRoute';
import useIndex from '../../../hooks/useIndex';
import validErrors from '../../../hooks/validErrors';
import TextEditor from './textEditor/TextEditor';
import CompanyAgreementDesc from './companyAgreements/CompanyAgreementDesc';

const CompanyAgreements = ({
    user = [],
    loadUser,
    setCountAgreement
}) => {
    const { token, auth } = useIndex();
    const [agreements, setAgreements] = useState([]);
    const [agreementPanel, setAgreementPanel] = useState('');

    const handleExpandAgreement = (agreementPanel) => (event, isExpanded) => {
        setAgreementPanel(isExpanded ? agreementPanel : false);
    };
    
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const { data } = await loadCompanyAgreements(token, user?.uuid);

            if (data?.success) {
                setAgreements(data?.data);
                setCountAgreement(data?.data.length);
            }
        } catch (error) {
            validErrors(error);
        }
    }

    return (
        <Box>
            <Typography variant='h6'>Contratos</Typography>

            {(user?.role_id == process.env.REACT_APP_ADMIN_ROLE || auth?.role_id == process.env.REACT_APP_ADMIN_ROLE) && (
                <TextEditor user={user} loadData={loadData} />
            )}

            <Box marginTop={'1rem'}>
                <Divider sx={{ margin: '1rem 0' }}><Typography sx={{ color: '#CCC' }}>Contratos</Typography></Divider>

                {agreements?.map((item) => (
                    <CompanyAgreementDesc 
                        key={item.uuid} 
                        item={item} 
                        loadData={loadData} 
                        loadUser={loadUser} 
                        handleExpandAgreement={handleExpandAgreement} 
                        agreementPanel={agreementPanel} 
                    />
                ))}
            </Box>
        </Box>
    )
}

export default CompanyAgreements