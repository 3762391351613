import { Container, Typography } from '@mui/material';
import { Notify } from 'notiflix';
import React, { useEffect, useState } from 'react'
import { loadCompanyAgreements } from '../../../data/api/agreements/AgreementRoute';
import useIndex from '../../../hooks/useIndex';
import validErrors from '../../../hooks/validErrors';
import CompanyAgreements from './CompanyAgreements';

const AgreementsPage = () => {
    const { auth, token } = useIndex();
    const [agreements, setAgreements] = useState([]);
    const [countAgreement, setCountAgreement] = useState(0);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            
        } catch (error) {
            validErrors(error);
        } finally {

        }
    }

    return (
        <Container sx={{padding: '1rem 0'}}>
            <Typography variant='h3' textAlign={'center'} >Mis contratos</Typography>
            <CompanyAgreements user={auth} loadData={loadData} setCountAgreement={setCountAgreement} />
        </Container>
    )
}

export default AgreementsPage