import { Box, Button, CircularProgress, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getAllPrizes } from '../../../data/api/prizes/PrizeRoutes';
import useIndex from '../../../hooks/useIndex';
import validErrors from '../../../hooks/validErrors';
import AddPrize from './prize/AddPrize';
import RowInfo from './prize/RowInfo'

const Prize = () => {
    const { token } = useIndex();
    const [load, setLoad] = useState(true);
    const [prizes, setPrizes] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoad(true);
        try {
            const { data } = await getAllPrizes(token);

            if (data?.success) {
                setPrizes(data?.data);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setLoad(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {load ? (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant='h4' textAlign={'center'}>Tablas de premios</Typography>

                    <Box
                        display={'flex'}
                        justifyContent={'flex-end'}
                        marginBottom={'.3rem'}
                    >
                        <Button size='small' variant='contained' color='info' onClick={() => {setOpen(true)}}>Agregar</Button>
                    </Box>
                    <TableContainer sx={{ maxHeight: '500px', maxWidth: '100%', borderRadius: '5px 5px 0 0', background: '#FFF' }}>
                        <Table stickyHeader aria-label="result table" size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Aciertos</strong></TableCell>
                                    <TableCell><strong>Premio</strong></TableCell>
                                    <TableCell><strong>Compensación</strong></TableCell>
                                    <TableCell><strong>Modificación</strong></TableCell>
                                    <TableCell><strong>Editar</strong></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {load ? (
                                    <TableRow>
                                        <TableCell colSpan={6}>Cargando...</TableCell>
                                    </TableRow>
                                ) : (
                                    prizes?.map((item) => (
                                        <RowInfo item={item} token={token} loadData={loadData} />
                                    ))
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <AddPrize token={token} loadData={loadData} handleClose={handleClose} />
            </Modal>
        </>
    )
}

export default Prize