import React from 'react'
import styled, { keyframes } from 'styled-components'

const Loader = ({
    open = true
}) => {
    return (
        <LoaderContent>
            <LoaderLoad></LoaderLoad>
        </LoaderContent>
    )
}

export default Loader

const spin = keyframes`
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
`
const LoaderContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51,51,51,.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
`
const LoaderLoad = styled.div`
    border: 5px solid rgba(255,255,255,.3);
    border-top: 5px solid #FFF;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`