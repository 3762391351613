import React, { useEffect, useState } from 'react'
import { getLastGames } from '../../../data/api/statistics/StatisticsRoute';
import useIndex from '../../../hooks/useIndex';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/system';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const LineGraphic = ({
    data = [''],
    title,
    subtitle
}) => {
    const [labels, setLabels] = useState(Object.keys(data));
    const [values, setValues] = useState([0]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        if (data) {
            let vals = [];
            labels?.map((item) => {
                vals.push(data[item]?.count);
            });
            setValues(vals);
        }
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Value'
                },
                suggestedMin: 0
            }
        }
    };

    const dataGraph = {
        labels,
        datasets: [
            {
                label: subtitle,
                data: values,
                fill: true,
                borderColor: '#FF6384',
                backgroundColor: 'rgba(0,0,0,0)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            }
        ]
    };

    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Line options={options} data={dataGraph} />
        </Box>
    )
}

export default LineGraphic