import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import PiggyBank from '../../assets/svg/PiggyBank.svg'
import LoveEarth from '../../assets/gif/LoveEarth.gif'
import PlantOnEarth from '../../assets/gif/PlantOnEarth.gif'

const Donations = () => {
    return (
        <Container maxWidth={'xl'}>
            <Box sx={{
                padding: '4rem 0'
            }}>
                {/* DONATIONS & PAYMENT GATEWAY */}
                <Container maxWidth="xl">
                    <Grid container paddingBottom={'4rem'} borderBottom={'.5px solid #CCC'} className='animate__animated animate__fadeIn'>
                        <Grid item xs={12} md={7}>
                            <Typography variant='h2' component={'h1'}>Donaciones</Typography>
                            <Typography component={'p'}>¡Ayúdanos a proteger a nuestra comunidad! Los bomberos están en la línea de frente en emergencias que amenazan la vida y la propiedad, y necesitan tu apoyo. Tu donación a través de nuestra cuenta bancaria nos permitirá adquirir equipo y tecnología de última generación, capacitar a nuestro personal y mejorar la eficacia y eficiencia de nuestros servicios. ¡Únete a nosotros y juntos podremos seguir salvando vidas y protegiendo a los que más queremos!</Typography>

                            <Button variant='contained' size='large' sx={{ borderRadius: '80px', marginTop: '2rem' }}>Dona Aquí</Button>
                        </Grid>
                        <Grid item xs={12} md={5} sx={{
                            padding: '2rem 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <ImageDon src={PiggyBank} alt={'Donations'} />
                        </Grid>
                    </Grid>
                </Container>

                {/* WATH WILL THE DONATIONS BE FOR */}
                <Grid container spacing={4} marginTop={'4rem'} className='animate__animated animate__fadeIn animate__delay-1s'>
                    <Grid item xs={12}>
                        <Typography variant='h2' component={'h3'} textAlign={'center'}>¿Para que seran las donaciones?</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Container maxWidth="xl">
                            <Grid container spacing={1}>

                                {/* SOCIAL SERVICES */}
                                <Grid item xs={12}>
                                    <Typography variant='h3'>Servicios Sociales - Bomberitos</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography component={'p'}>El curso de bomberos para niños es una oportunidad única para que los mas pequeños aprendan sobre la importancia de la prevención y la seguridad en caso de emergencias. A través de actividades prácticas y teóricas, los niños aprenderán los conceptos básicos de la prevención de incendios y los procedimientos de evacuación, así como también conocerán el funcionamiento y uso de los equipos y herramientas utilizados por los bomberos. Además, el curso también busca fomentar valores como la responsabilidad, el trabajo en equipo y la solidaridad. Este curso es una forma divertida y educativa para que los niños aprendan sobre la labor de los bomberos y cómo pueden contribuir a la seguridad de su comunidad.</Typography>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <GifDoc src={LoveEarth} alt={'Servicios Sociales'} />
                                </Grid>

                                {/* AMBIENTAL PROGRAM */}
                                <Grid item xs={12}>
                                    <Typography variant='h3' textAlign={'right'}>Programa ambiental</Typography>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <GifDoc src={PlantOnEarth} alt={'Servicios Ambientales'} />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography component={'p'}>Un programa ambiental es una iniciativa diseñada para promover la conciencia y la responsabilidad ambiental, así como para implementar prácticas sostenibles en una organización, comunidad o sociedad. Este tipo de programa puede incluir actividades como la reducción de residuos, la conservación de energía y agua, la educación y concientización sobre temas ambientales, la implementación de prácticas de reciclaje, entre otros. El objetivo de un programa ambiental es reducir el impacto ambiental negativo y promover la sostenibilidad, contribuyendo así a la protección del medio ambiente y el bienestar de las personas y la biodiversidad.</Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>

                </Grid>

            </Box>
        </Container>
    )
}

export default Donations

const ImageDon = styled.img`
  width: 50%;
  pointer-events: none;
  `
const GifDoc = styled.img`
  width: 40%;
  pointer-events: none;
`