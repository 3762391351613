import { Box } from '@mui/system'
import React from 'react'
import styled from 'styled-components'

const ExpandGallery = ({
    Images
}) => {
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <GaleryCoding>
                {Images.map((item) => (
                    <Box key={item.id} sx={{ backgroundImage: `url(${item.image})` }}></Box>
                ))}
            </GaleryCoding>
        </Box>
    )
}

export default ExpandGallery

const GaleryCoding = styled.div`
    display: flex;
    width: 80%;
    height: 20rem;
    gap: 1rem;
    div {
        flex: 1;
        border-radius: 1rem;
        background-color: #333;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition:all .8s cubic-bezier(.25, .4, .45, 1.1);
        &:hover {
        flex: 5;
        border-radius: none;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 15rem;
    }
`