import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moneyFormat from '../../../../hooks/moneyFormat';
import React, { useState } from 'react';
import useIndex from '../../../../hooks/useIndex';
import { useForm } from 'react-hook-form';
import validErrors from '../../../../hooks/validErrors';
import styled from 'styled-components';
import { cancelAgreement, uploadCompanyAgreement } from '../../../../data/api/agreements/AgreementRoute';
import { Confirm, Notify } from 'notiflix';
import { LoadingButton } from '@mui/lab';

const CompanyAgreementDesc = ({
    item,
    loadData,
    loadUser,
    handleExpandAgreement,
    agreementPanel
}) => {
    const { token, auth } = useIndex();
    const { register, handleSubmit } = useForm();

    const [fileLoaded, setFileLoaded] = useState(null);
    const [submit, setSubmit] = useState(false);

    const onSubmit = async (dataForm) => {
        setSubmit(true);
        try {
            const frm = new FormData();
            frm.append('file', fileLoaded);
            if (fileLoaded == null) { Notify.warning('No tiene ningun documento por subir.'); return; }

            const { data } = await uploadCompanyAgreement(token, item?.uuid, frm);
            if (data?.success) {
                Notify.success(data?.message);
                loadData();
                setFileLoaded(null);
                loadUser();
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
        }
    }

    const preCancel = () => {
        Confirm.show(
            'Cancelar Contrato',
            '¿Seguro deseas cancelar este contrato?',
            'Si',
            'No',
            () => { cancelAgr() },
            () => { }
        );
    }

    const cancelAgr = async () => {
        setSubmit(true);
        try {
            const { data } = await cancelAgreement(token, item?.uuid);
            if (data?.success) {
                Notify.success(data?.message);
                loadData();
                setFileLoaded(null);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
        }
    }

    return (
        <Accordion expanded={agreementPanel == item?.uuid} onChange={handleExpandAgreement(item?.uuid)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography>Contrato: {item?.id} - {item?.moves_amount} Jugadas - {moneyFormat(item?.agreement_amount)} {item?.currency}</Typography> - <Typography component={'span'} sx={{
                    color: item?.status == 'Pendiente de documento' ? '#f39c12'
                        : item?.status == 'Cancelado' ? '#e74c3c'
                            : '#1c1c1c'
                }}>
                    {item?.status}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Card>
                    <CardContent>
                        <Typography>{item?.agreement_type}</Typography>
                        <Typography>Identificador: {item?.uuid}</Typography>
                        <Typography>Creado: {item?.created_at}</Typography>
                        <Typography>Estado: <Typography component={'span'} sx={{ color: item?.status == 'Pendiente de documento' ? '#f39c12' : item?.status == 'Cancelado' ? '#e74c3c' : '#1c1c1c' }}>{item?.status}</Typography></Typography>

                        {item?.status == 'Aprobado' && (
                            <Button variant='outlined' color='info' size='small' href={`${process.env.REACT_APP_BACK_URL}/agreement-aprove/${item?.uuid}`} target={'_blank'}>Descargar Contrato</Button>
                        )}

                        {item?.status == 'Pendiente de documento' && (
                            <>
                                <Button variant='outlined' color='info' size='small' href={`${process.env.REACT_APP_BACK_URL}/agreement/${item?.uuid}`} target={'_blank'}>Descargar Contrato</Button>
                                {auth?.role_id == process.env.REACT_APP_ADMIN_ROLE && (
                                    <>
                                        <Divider sx={{ margin: '1rem 0' }}><Typography color={'#CCC'}>Subir Contrato Firmado</Typography></Divider>

                                        <form onSubmit={handleSubmit(onSubmit)} key={item?.uuid}>
                                            <UploadInput accept={'.pdf'} type={'file'} id={`upload_file_${item?.uuid}`} name={`upload_file_${item?.uuid}`} {...register('file')} onChange={(e) => setFileLoaded(e.target.files[0])} />
                                            <Box sx={{
                                                display: 'flex',
                                            }}>
                                                <UploadFile htmlFor={`upload_file_${item?.uuid}`} className={`${(fileLoaded != null) && 'success'}`}>
                                                    <i className="fa-regular fa-file"></i>
                                                    <i className="overicon fa-solid fa-plus"></i>
                                                    <span>Agregar archivo</span>
                                                </UploadFile>

                                                <FileDescription>
                                                    <li>Nombre: {fileLoaded?.name}</li>
                                                    <li>Peso: {fileLoaded?.size ?? 0}KB</li>
                                                    <li>Tipo: {fileLoaded?.type}</li>
                                                    <li>Fecha: {new Date(Date.now()).toLocaleDateString()}</li>
                                                </FileDescription>
                                            </Box>

                                            <Box
                                                display={'flex'}
                                            >
                                                <LoadingButton
                                                    fullWidth
                                                    type={'submit'}
                                                    loading={submit}
                                                    variant="outlined"
                                                >
                                                    <span>Subir documento</span>
                                                </LoadingButton>

                                                <Button fullWidth variant='outlined' color='error' onClick={preCancel}>Cancelar Contrato</Button>
                                            </Box>
                                        </form>
                                    </>
                                )}
                            </>
                        )}
                    </CardContent>
                </Card>
            </AccordionDetails>
        </Accordion>
    )
}

const UploadInput = styled.input`
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
`
const UploadFile = styled.label`
    width: 150px;
    border: 1.5px dashed #CCC;
    border-radius: 7px;
    position: relative;
    margin: .25rem;
    padding: 15px 5px;
    text-align: center;
    color: #AAA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        border-color: #85C1E9;
        i {
            color: #85C1E9;
        }
    }
    &:active {
        border-color: #21618C;
        i {
            color: #21618C;
        }
    }
        
    i.overicon {
        position: absolute;
        font-size: 2.5rem
    }
    span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: underline;
    }
    i {
        font-size: 5rem;
        color: #CCC;
    }

    &.success {
        border-color: #82E0AA;
        color: #82E0AA;
        i {
            color: #82E0AA;
        }
    }
`
const FileDescription = styled.ul`
    width: 500px;
    max-width: 100%;
    list-style: none;
    padding: .5rem 1rem;
    margin: 0;
    color: #AAA;
    li {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export default CompanyAgreementDesc