import clientAxios from "../config";

const getRoles = async (token) => clientAxios(`/api/roles`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export {
    getRoles
}