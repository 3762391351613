import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const MultipleIncomes = () => {

    const labels = ['January', 'February', 'March', 'April', 'May', 'June'];
    const values = [12, 45, 27, 73, 43, 12].map(item => item * 1000);
    const values2 = [40, 10, 13, 22, 12, 0].map(item => item * 1000);
    const values3 = [50, 5, 50, 43, 90, 28].map(item => item * 1000);
    const values4 = [10, 10, 10, 10, 10, 10].map(item => item * 1000);
    const values5 = [50, 50, 50, 50, 50, 50].map(item => item * 1000);
    const total = [162, 120, 150, 198, 205, 100].map(item => item * 1000);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Ingresos semestrales',
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Total',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: total,
            },
            {
                type: 'bar',
                label: 'PSE',
                backgroundColor: 'rgb(75, 192, 192)',
                data: values,
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar',
                label: 'Tarjeta',
                backgroundColor: 'rgb(53, 162, 235)',
                data: values2,
            },
            {
                type: 'bar',
                label: 'Efectivo',
                backgroundColor: 'rgb(248, 196, 113)',
                data: values3,
            },
            {
                type: 'bar',
                label: 'Transferencia',
                backgroundColor: 'rgb(195, 115, 211)',
                data: values4,
            },
            {
                type: 'bar',
                label: 'Codigo',
                backgroundColor: 'rgb(88, 214, 141)',
                data: values5,
            },
        ],
    };

    return <Chart options={options} data={data} />;
}

export default MultipleIncomes