import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/system';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const BarGraphic = ({
    data,
    title,
    subtitle
}) => {
    const [dataHits, setDataHits] = useState([]);
    const [totalGames, setTotalGames] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        if (data) {
            let tot = 0;
            let resultBar = [];

            data?.map((item) => {
                tot += item?.count
                resultBar.push(
                    {
                        label: item?.label + ' aciertos',
                        data: [item?.count],
                        borderColor: item?.color,
                        backgroundColor: item?.color,
                        borderWidth: 1,
                    }
                );
            });
            setDataHits(resultBar);
            setTotalGames(tot);
        }
    }

    const optionsGraph = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title + ' (' + totalGames + ')',
            },
        }
    };

    const dataGraph = {
        labels: ['Resultados'],
        datasets: dataHits
    };
    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Bar options={optionsGraph} data={dataGraph} />
        </Box>
    )
}

export default BarGraphic