import { Button, FormControl, MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import { Confirm, Loading, Notify } from 'notiflix';
import React from 'react'
import { useState } from 'react'
import validErrors from '../../../../hooks/validErrors';
import { deletePrice, updatePrize } from '../../../../data/api/prizes/PrizeRoutes';
import moneyFormat from '../../../../hooks/moneyFormat';

const RowInfo = ({
    item,
    token,
    loadData
}) => {
    const [active, setActive] = useState(false);
    const [submit, setSubmit] = useState(false);

    const [hits, setHits] = useState(item?.hits || 1);
    const [prize, setPrize] = useState(item?.prize || 1);
    const [compensationType, setCompensationType] = useState(item?.compensation || 0);

    const preSubmit = () => {
        Confirm.show(
            'Modificar Registro',
            'Seguro deseas modificar la informacion?',
            'Si',
            'No',
            () => onSubmit(),
            () => {},
        );
    }
    const onSubmit = async () => {
        setSubmit(true);
        Loading.arrows();
        try {
            let dataSend = {
                "hits": hits,
                "prize": prize,
                "compensation": compensationType
            };
            const { data } = await updatePrize(token, item?.id, dataSend);

            if (data?.success) {
                Notify.success(data?.message);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setActive(false);
            setSubmit(false);
            Loading.remove();
        }
    }

    const preDelete = () => {
        Confirm.show(
            'Eliminar Registro',
            'Seguro deseas elimiar la informacion?',
            'Si',
            'No',
            () => onDelete(),
            () => {},
        );
    }
    const onDelete = async () => {
        setSubmit(true);
        Loading.standard();
        try {
            const { data } = await deletePrice(token, item?.id);

            if (data?.success) {
                Notify.success(data?.message);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
            setActive(false);
            Loading.remove();
            loadData();
        }
    }

    return (
        <TableRow role="checkbox" tabIndex={-1} key={item?.id}>
            {/* HITS */}
            <TableCell>
                <TextField
                    variant="standard"
                    placeholder={'Aciertos'}
                    size={'small'}
                    fullWidth
                    disabled={!active}
                    value={hits}
                    type={'number'}
                    label={hits + ' Aciertos'}
                    onChange={(e) => { setHits(e.target.value) }}
                    inputProps={{
                        maxLength: 1,
                    }}
                />
            </TableCell>
            {/* PRIZE */}
            <TableCell align="center">
                <TextField
                    variant="standard"
                    placeholder={'Premio'}
                    size={'small'}
                    fullWidth
                    disabled={!active}
                    value={prize}
                    type={'number'}
                    label={moneyFormat(prize)}
                    onChange={(e) => { setPrize(e.target.value) }}
                />
            </TableCell>
            {/* COMPENSATION */}
            <TableCell align="center">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        labelId="prize-compensation-label"
                        id="prize-compensation"
                        value={compensationType}
                        onChange={(e) => { setCompensationType(e.target.value) }}
                        label="Compensacion"
                        size='small'
                        disabled={!active}
                    >
                        <MenuItem value={0}>Jugadas</MenuItem>
                        <MenuItem value={1}>COP</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            {/* UPDATED_AT */}
            <TableCell align="center">{item?.updated_at}</TableCell>
            {/* BUTTONS */}
            <TableCell align="center">
                {active ? (
                    <>
                        <Button
                            sx={{
                                minWidth: '0',
                                marginRight: '.3rem'
                            }}
                            variant={'outlined'}
                            color={'success'}
                            disabled={submit}
                            onClick={() => { preSubmit() }}
                        >
                            <i className="fa-solid fa-check"></i>
                        </Button>

                        <Button
                            sx={{
                                minWidth: '0'
                            }}
                            variant={'outlined'}
                            color={'error'}
                            onClick={() => { setActive(false) }}
                            disabled={submit}
                        >
                            <i className="fa-solid fa-x"></i>
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            sx={{
                                minWidth: '0',
                                marginRight: '.3rem'
                            }}
                            variant={'outlined'}
                            onClick={() => { setActive(true) }}
                            disabled={submit}
                        >
                            <i className="fa-solid fa-pencil"></i>
                        </Button>

                        <Button
                            sx={{
                                minWidth: '0'
                            }}
                            variant={'outlined'}
                            color={'error'}
                            disabled={submit}
                            onClick={() => {preDelete()}}
                        >
                            <i className="fa-solid fa-trash"></i>
                        </Button>
                    </>
                )}
            </TableCell>
        </TableRow>
    )
}

export default RowInfo