import { Box, Card, CardContent, CircularProgress, Container, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react';
import MultipleIncomes from './MultipleIncomes';
import DonutGraphic from './DonutGraphic';
import useIndex from '../../../hooks/useIndex';
import { getAllStatistics } from '../../../data/api/statistics/StatisticsRoute';
import LineGraphic from './LineGraphic';
import BarGraphic from './BarGraphic';
import validErrors from '../../../hooks/validErrors';

const Statistics = () => {

    const { token } = useIndex();
    const [dataGraphic, setDataGraphic] = useState([]);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoad(true);

        try {
            const { data } = await getAllStatistics(token);
            setDataGraphic(data?.data);
        } catch (error) {
            validErrors(error);
        } finally {
            setLoad(false);
        }
    }

    return (
        <Container sx={{ padding: '2rem 0' }}>
            <Grid container spacing={2}>

                {/* JUGADAS SEMANALES */}
                <Grid item xs={12} md={12} lg={6}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <CircularProgress color="error" />
                                    </Box>
                                </Box>
                            ) : (
                                <LineGraphic
                                    data={dataGraphic['week-games']}
                                    title={'Jugadas semanales'}
                                    subtitle={'Jugadas'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* RESULTADO DE JUEGOS */}
                <Grid item xs={12} md={8} lg={6}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CircularProgress color="error" />
                                </Box>
                            ) : (
                                <BarGraphic
                                    data={dataGraphic['codes-by-result']}
                                    title={'Resultado de juegos'}
                                    subtitle={'Jugadas'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* ESTADO DE CODIGOS */}
                <Grid item xs={12} md={4} lg={4}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CircularProgress color="error" />
                                </Box>
                            ) : (
                                <DonutGraphic
                                    data={dataGraphic['codes-by-company']}
                                    title={'Estado de codigos'}
                                    subtitle={'Cantidad'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* CODIGOS ASIGNADOS/UTILIZADOS */}
                <Grid item xs={12} md={6} lg={4}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CircularProgress color="error" />
                                </Box>
                            ) : (
                                <DonutGraphic
                                    data={dataGraphic['codes-registred']}
                                    title={'Codigos |Asignados/Utilizados|'}
                                    subtitle={'Cantidad'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* JUGADAS CREADAS */}
                <Grid item xs={12} md={6} lg={4}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CircularProgress color="error" />
                                </Box>
                            ) : (
                                <DonutGraphic
                                    data={dataGraphic['codes-created']}
                                    title={'Jugadas creadas'}
                                    subtitle={'Cantidad'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* JUGADAS MENSUALES */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            {load ? (
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CircularProgress color="error" />
                                </Box>
                            ) : (
                                <LineGraphic
                                    data={dataGraphic['month-games']}
                                    title={'Jugadas del ultimo mes'}
                                    subtitle={'Jugadas'}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* INFORME DE INGRESOS */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <MultipleIncomes />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Statistics