import clientAxios from "../config";

const getLastGames = async (token) => clientAxios('/api/statistics/week-games', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getMonthGames = async (token) => clientAxios('/api/statistics/month-games', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getLastMonthGames = async (token) => clientAxios('/api/statistics/last-month-games', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getCodeUsed = async (token) => clientAxios('/api/statistics/codes-by-company', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getCodeResult = async (token) => clientAxios('/api/statistics/codes-result', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getCodeRegistred = async (token) => clientAxios('/api/statistics/codes-registred', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getCodeCreated = async (token) => clientAxios('/api/statistics/codes-created', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getAllStatistics = async (token) => clientAxios('/api/statistics/all-statistics', {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getUserWeekGames = async (token, userId) => clientAxios(`/api/statistics/user/week-games/${userId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getUserCodesResult = async (token, userId) => clientAxios(`/api/statistics/user/codes-result/${userId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getAllUserStatistics = async (token, userId) => clientAxios(`/api/statistics/user/all-statistics/${userId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export {
    getLastGames,
    getCodeUsed,
    getMonthGames,
    getLastMonthGames,
    getCodeResult,
    getCodeRegistred,
    getCodeCreated,
    getAllStatistics,
    getUserWeekGames,
    getUserCodesResult,
    getAllUserStatistics,
}