import { Box, Card, CardContent, Chip, Container, FormControl, Grid, InputAdornment, MenuItem, Pagination, Select, Skeleton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserCard from '../../../components/card/UserCard'
import { getAllUsers } from '../../../data/api/users/UserRoutes'
import useIndex from '../../../hooks/useIndex'
import UserProfile from './UserProfile'
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components'
import { useDebounce } from 'react-use'
import validErrors from '../../../hooks/validErrors'


const UsersPage = () => {

    const { token } = useIndex();
    const { uuid } = useParams();

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState(null);
    const [users, setUsers] = useState(null);
    const [userSearch, serUserSearch] = useState('');
    const [load, setLoad] = useState(true);

    const [isReady, cancel] = useDebounce(() => {
        setPage(1);
        loadData(rowsPerPage, page, userSearch);
    }, 1000, [userSearch]);

    useEffect(() => {
        cancel();
    }, []);

    useEffect(() => {
        if (!uuid) {
            loadData(rowsPerPage, page, userSearch);
        }
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const loadData = async (rpp, p, us) => {
        setLoad(true);
        try {
            const {data} = await getAllUsers(token, rpp, p, us);
    
            if (data?.success) {
                setData(data);
                setUsers(data?.data);
            }
        } catch (error) { 
            validErrors(error);
        } finally {
            setLoad(false);
        }
    }

    if (uuid) {
        return <UserProfile uuid={uuid} />
    }

    return (
        <Container sx={{ padding: '1.5rem 0' }}>

            <Box sx={{ marginBottom: '1rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            boxShadow: '0px 0px 11px 1px rgba(51,51,51,.4)',
                            borderRadius: '5px',
                            padding: '1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            background: '#FFF'
                        }}>
                            {/* USER COUNT */}
                            <Stack direction="row" spacing={1}>
                                <Chip label={`Jugadores: ${data?.meta?.total}`} color="info" />
                            </Stack>

                            {/* USER PAGINATION */}
                            <PaginationContainer>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant={'subtitle2'}>Filas:</Typography>
                                    <FormControl sx={{ m: 1, maxWidth: 100 }}>
                                        <Select
                                            size="small"
                                            defaultValue={rowsPerPage}
                                            onChange={handleChangeRowsPerPage}
                                            autoWidth
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Pagination
                                    count={data?.meta?.last_page}
                                    showFirstButton
                                    showLastButton
                                    defaultPage={page}
                                    onChange={handleChangePage}
                                />
                            </PaginationContainer>
                            
                            {/* USER SEARCH */}
                            <TextField
                                id="search_user"
                                label="Usuario"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={userSearch}
                                onChange={(e) => serUserSearch(e.target.value)}
                                variant="outlined"
                                sx={{
                                    width: '100%',
                                    maxWidth: '300px'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
            }}>
                {load ?
                    [1, 2, 3, 4].map((item) => (
                        <Stack spacing={1} key={item}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="rectangular" width={210} height={60} />
                            <Skeleton variant="rounded" width={210} height={60} />
                        </Stack>
                    ))
                    :
                    users?.map((user) => (
                        <UserCard key={user?.uuid} user={user} />
                    ))
                }
            </Box>
        </Container>
    )
}

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export default UsersPage