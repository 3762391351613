import clientAxios from "../config";

const validCode = async (token, data) => clientAxios.put(`/api/income-code-assign`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getAllIncomeCollection = async (token) => clientAxios(`/api/income-collection`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const addIncomeCollection = async (token, data) => clientAxios.post(`/api/income-collection`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const getCodesByCollection = async (token, id, rowsPerPage, page) => clientAxios(`/api/income-codes-collection/${id}/${rowsPerPage}?page=${page}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

const exportIncomeCodeCollection = async (token, name, id) => clientAxios(`/api/export/income-collection/${name}/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
});

const getAllIncomeTypes = async (token) => clientAxios(`/api/income-type`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export {
    validCode,
    getAllIncomeCollection,
    addIncomeCollection,
    getCodesByCollection,
    exportIncomeCodeCollection,
    getAllIncomeTypes
}