import { Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Login from './Login'
import Register from './Register'
import FormImage from '../../assets/img/fire-g6d1eac978_1280.jpg';

const AuthLayout = ({
    codeWin,
    winData,
}) => {
    const [panel, setPanel] = useState(true);

    return (
        <LoginFormCont>
            <Box className="login">
                <Box className="login-container-wrapper" sx={{ backgroundImage: `url(${FormImage})` }}>
                    <ul className="switcher">
                        <li className={`first logo ${panel && 'active'}`} onClick={() => setPanel(true)} data-tab="login">
                            <span>Ingresa</span>
                        </li>
                        <li className={`second logo ${!panel && 'active'}`} onClick={() => setPanel(false)} data-tab="sign_up">
                            <span>Registrate</span>
                        </li>
                    </ul>
                    <Box className="tab-content">
                        {panel ? (
                            <Login codeWin={codeWin} winData={winData} />
                        ) : (
                            <Register setPanel={setPanel} codeWin={codeWin} winData={winData} />
                        )}
                    </Box>
                </Box>
            </Box>
        </LoginFormCont>
    )
}

export default AuthLayout



const LoginFormCont = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 420px;
@import url(https://fonts.googleapis.com/css?family=Varela);
.login {
    font-family: 'Varela', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    position: relative;
    overflow: hidden;
}

label {
    margin-bottom: 0;
}

a {
    color: #e1e1e1;

    &:focus, &:hover {
        color: #008080;
    }
}

.checkbox-inline + .checkbox-inline, .radio-inline + .radio-inline {
    margin-top: 6px;
}

body.login {
    background: rgba(255, 255, 255, 1);
}

.relative {
    position: relative;
}
.switcher {
    margin-bottom: 70px;
}
ul.switcher li {
    list-style-type: none;
    width: 50%;
    position: absolute;
    top: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.first {
    left: 0;
}

.second {
    right: 0;
}
.login-container-wrapper {
    max-width: 400px;
    margin: 2% auto 5%;
    padding: 40px;
    box-sizing: border-box;
    /* background: rgba(57, 89, 116, 0.8); */
    position: relative;
    box-shadow: 0px 0px 10px -5px #000;
    background-size: cover;
    background-blend-mode: saturation;
    border-radius: 10px;

    .logo, .welcome {
        font-size: 16px;
        letter-spacing: 1px;
    }

    li {
        text-align: center;
        padding: 0 15px;
        background-color: #283443;
        height: 50px;
        line-height: 50px;
        box-shadow: inset 0 -2px 0 0 #ccc;
        cursor: pointer;

        span {
            color: #fff;

            &:hover {
                color: #ccc;
                text-decoration: none;
            }

            &:active, &:focus {
                color: #fff;
                text-decoration: none;
            }
        }

        &.active {
            background-color: transparent;
            box-shadow: none;

            span {
                border-bottom: 2px solid #fff;
                padding-bottom: 5px;
            }
        }
    }
}

.login input:focus + .fa {
    color: #25a08d;
}



.login input {
    &[type="email"], &[type="password"], &[type="text"] {
        background-color: rgba(40, 52, 67, 0.9);
        border: 0px solid #4a525f;
        color: #eee;
        border-left: 45px solid #93a5ab;
        border-radius: 40px;
    }

    &:focus {
        background-color: rgba(40, 52, 67, 0.9);
        border: 0px solid #4a525f;
        color: #eee;
        border-left: 45px solid #93a5ab;
        border-radius: 40px;
        border-left: 45px solid #eee;
        outline: none;
        box-shadow: 0px 0px 8px 1px #718FA2;
    }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: rgba(40, 52, 67, 0.75) !important;
    background-image: none;
    color: rgb(0, 0, 0);
    border-color: #FAFFBD;
}

.login .checkbox {
    label, a {
        color: #ddd;
        vertical-align: top;
    }
}

input[type="checkbox"]:checked + label::before, .checkbox-success input[type="radio"]:checked + label::before {
    background-color: #25a08d !important;
}



`