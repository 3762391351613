import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useIndex from '../../hooks/useIndex'

const IndexGanacol = () => {
    const rolesGame = [1, 2, 5];
    const { auth, token } = useIndex();
    const navigate = useNavigate();

    useEffect(() => {
        rolesGame.includes(auth?.role_id) ? navigate('/ganacol/dashboard/game') : navigate('/ganacol/dashboard/codes');
    }, [auth]);

    return (
        <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <CircularProgress />
            <Typography>Cargando...</Typography>
        </Box>
    )
}

export default IndexGanacol