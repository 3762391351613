import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import TimeLineItemResume from '../../components/list/TimeLineItemResume';

const Index = () => {
    return (
        <Container maxWidth={'xl'}>
            <Container maxWidth={false} sx={{ paddingBottom: '40px' }}>
                <Typography
                    variant='h2'
                    component={'h1'}
                    fontFamily={'title-fenabo-font'}
                    fontWeight={'700'}
                    className='animate__animated animate__fadeInLeft'
                    textAlign={'center'}
                >
                    Fenabocol
                </Typography>

                <Grid container spacing={2}>
                    {/* BANER */}
                    <Grid item xs={12}>
                        <Typography
                            className='animate__animated animate__fadeIn'
                            textAlign={'justify'}
                        >
                            ¡No te quemes! En nuestra página web de bomberos encontrarás todo lo que necesitas para mantener tu hogar y comunidad seguros. Desde consejos de prevención de incendios hasta información sobre cómo actuar en caso de emergencia, nuestros expertos en bomberos están aquí para ayudarte. Además, con nuestra amplia gama de servicios de emergencia, podrás estar seguro de que estamos siempre listos para intervenir y proteger a nuestra comunidad. ¡Únete a nosotros y juntos mantengamos a salvo a los que más queremos!
                        </Typography>
                    </Grid>

                    {/* MISION */}
                    <Grid item xs={12} className='animate__animated animate__fadeIn animate__delay-1s'>
                        <Typography variant='h3' component={'h2'} textAlign={'center'}>Misión</Typography>
                        <Typography>
                            Somos una entidad de bomberos comprometida con la protección de vidas y bienes de nuestra comunidad. Trabajamos de manera constante para garantizar que nuestra comunidad se sienta segura y protegida en caso de emergencias. Estamos comprometidos con la excelencia en el servicio, la capacitación continua de nuestro personal y la inversión en tecnología y equipo de última generación para brindar una respuesta rápida y efectiva ante cualquier eventualidad.
                        </Typography>
                    </Grid>

                    {/* VISION */}
                    <Grid item xs={12} className='animate__animated animate__fadeIn animate__delay-2s'>
                        <Typography variant='h3' component={'h2'} textAlign={'center'}>Visión</Typography>
                        <Typography>
                            Ser reconocidos como una entidad de bomberos líder en la protección de nuestra comunidad, y referente en el país en la prevención y atención de emergencias. Buscamos ser una organización innovadora, comprometida con la mejora continua y la excelencia en el servicio. Aspiramos a ser un modelo a seguir en la gestión eficiente de los recursos y en la promoción de una cultura de seguridad y prevención en nuestra comunidad. <br />
                            También ser para el año 2030 una institución autosuficiente para prestar el servicio Público de gestión Integral de Riesgo Contra Incendio y Calamidades Conexas. Con más de 100.000 afiliados en nuestra Institución.
                        </Typography>
                    </Grid>

                    {/* OBJETIVOS */}
                    <Grid item xs={12} className='animate__animated animate__fadeIn animate__delay-3s'>
                        <Typography variant='h3' component={'h2'} textAlign={'center'}>Objetivos</Typography>

                        <Timeline sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                        }}>
                            <TimeLineItemResume>Brindar una respuesta rápida y efectiva ante emergencias, minimizando los daños a la propiedad y la pérdida de vidas.</TimeLineItemResume>
                            <TimeLineItemResume>Promover una cultura de seguridad y prevención en nuestra comunidad, a través de campañas de educación y concientización.</TimeLineItemResume>
                            <TimeLineItemResume>Capacitar constantemente a nuestro personal en técnicas y tecnologías de vanguardia, para brindar un servicio de excelencia.</TimeLineItemResume>
                            <TimeLineItemResume>Mantener y mejorar continuamente nuestro equipamiento y tecnología, para estar a la vanguardia en la atención de emergencias.</TimeLineItemResume>
                            <TimeLineItemResume>Desarrollar una red de colaboración con otras entidades y organizaciones, para mejorar la eficiencia y eficacia de la atención de emergencias.</TimeLineItemResume>
                            <TimeLineItemResume>Evaluar y mejorar continuamente nuestros procesos y sistemas, para garantizar la calidad y eficiencia en la prestación de servicios.</TimeLineItemResume>
                            <TimeLineItemResume>Fomentar un ambiente de trabajo seguro y saludable, para el bienestar y satisfacción de nuestro personal y sus familias.</TimeLineItemResume>
                            <TimeLineItemResume last={true}>Proveer productos y servicios que brinden a la comunidad seguridad humana, Industrial, protección de riesgo contra incendio, otros incidentes y eventos masivos.</TimeLineItemResume>
                        </Timeline>

                    </Grid>

                </Grid>
            </Container>
        </Container>
    )
}

export default Index