import { Box, Container, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { deleteDocument, getAllDocuments, saveDocument } from '../../../data/api/document/DocumentRoutes';
import DocumentContainer from '../../documents/DocumentContainer';
import { useForm } from 'react-hook-form';
import { Confirm, Notify } from 'notiflix';
import useIndex from '../../../hooks/useIndex';
import { LoadingButton } from '@mui/lab';
import validErrors from '../../../hooks/validErrors';

const Files = () => {
    const { token } = useIndex();
    const { register, handleSubmit } = useForm();

    const [submit, setSubmit] = useState(false);
    const [documents, setDocuments] = useState();
    const [fileLoaded, setFileLoaded] = useState(null);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setDocuments(null);
        const { data } = await getAllDocuments();
        setDocuments(data?.data);
    }

    const onSubmit = async () => {
        setSubmit(true);

        try {
            const frm = new FormData();
            frm.append('file', fileLoaded);

            const { data } = await saveDocument(token, frm);
            if (data?.success) {
                Notify.success(data?.message);
                loadData();
                setFileLoaded(null);
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false);
        }
    }

    const onDeleteAlert = (id) => {
        Confirm.show(
            'Alerta',
            'El archivo va a ser eliminado permanentemente, esta acción no se puede restaurar una vez realizada.',
            'Eliminar',
            'Cancelar',
            async () => {
                try {
                    const { data } = await deleteDocument(token, id);
                    if (data?.success) {
                        Notify.success(data?.message);
                        loadData();
                    }
                } catch (error) {
                    validErrors(error);
                }
            },
            () => { }
        );
    }

    return (
        <Container sx={{ padding: '1.5rem 1rem' }}>
            <Typography variant={'h4'}>Documentos Publicos</Typography>
            <Box sx={{
                border: '1px dashed #CCC',
                borderRadius: '5px',
                padding: '.7rem',
                background: '#FFF'
            }}>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {!documents ? (
                            [1, 2, 3, 4].map((item) => (
                                <Stack spacing={1} width={'150px'} key={item} display={'inline-flex'} margin={'0 .25rem'}>
                                    <Skeleton variant="rectangular" height={100} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Stack>
                            ))
                        ):(
                            <DocumentContainer documents={documents} edit={true} actionDelete={onDeleteAlert} />
                        )}

                        <Divider><Typography color={'#CCC'}>AGREGAR</Typography></Divider>

                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}>
                            <UploadFile htmlFor={'upload_file'} className={`${(fileLoaded != null) && 'success'}`}>
                                <i className="fa-regular fa-file"></i>
                                <i className="overicon fa-solid fa-plus"></i>
                                <span>Agregar archivo</span>
                            </UploadFile>

                            <FileDescription>
                                <li>Nombre: {fileLoaded?.name}</li>
                                <li>Peso: {fileLoaded?.size ?? 0}KB</li>
                                <li>Tipo: {fileLoaded?.type}</li>
                                <li>Fecha: {new Date(Date.now()).toLocaleDateString()}</li>
                            </FileDescription>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <UploadInput type={'file'} id={'upload_file'} name={'upload_file'} {...register('file')} onChange={(e) => setFileLoaded(e.target.files[0])} />

                            <LoadingButton
                                fullWidth
                                type={'submit'}
                                loading={submit}
                                variant="outlined"
                            >
                                <span>Subir documento</span>
                            </LoadingButton>
                        </form>
                    </Grid>
                </Grid>

            </Box>
        </Container>
    )
}

export default Files

const UploadInput = styled.input`
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
`
const UploadFile = styled.label`
    width: 150px;
    border: 1.5px dashed #CCC;
    border-radius: 7px;
    position: relative;
    margin: .25rem;
    padding: 15px 5px;
    text-align: center;
    color: #AAA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        border-color: #85C1E9;
        i {
            color: #85C1E9;
        }
    }
    &:active {
        border-color: #21618C;
        i {
            color: #21618C;
        }
    }
        
    i.overicon {
        position: absolute;
        font-size: 2.5rem
    }
    span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: underline;
    }
    i {
        font-size: 5rem;
        color: #CCC;
    }

    &.success {
        border-color: #82E0AA;
        color: #82E0AA;
        i {
            color: #82E0AA;
        }
    }
`
const FileDescription = styled.ul`
    width: 500px;
    max-width: 100%;
    list-style: none;
    padding: .5rem 1rem;
    margin: 0;
    color: #AAA;
    li {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`