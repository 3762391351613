import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const DonutGraphic = ({
    data,
    title,
    subtitle
}) => {
    const [labels, setLabels] = useState(['1', '2', '3']);
    const [values, setValues] = useState([0, 0, 0]);
    const [colors, setColors] = useState([0, 0, 0]);
    const [totalCodes, setTotalCodes] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        if (data) {
            let lbs = [];
            let vals = [];
            let col = [];

            data?.map((item) => {
                lbs.push(item?.label);
                vals.push(parseInt(item?.count));
                col.push(item?.color);
            });

            setTotalCodes(vals.reduce((a, b) => a + b, 0));
            setLabels(lbs);
            setValues(vals);
            setColors(col);
        }
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title + ' (' + totalCodes + ')',
            },
        },
        cutout: '60%'
    }

    const dataGraph = {
        labels,
        datasets: [
            {
                label: subtitle,
                data: values,
                backgroundColor: colors,
                hoverOffset: 4
            }
        ]
    }

    return <Doughnut data={dataGraph} options={options} />
}

export default DonutGraphic