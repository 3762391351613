import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Loading, Notify } from 'notiflix';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { validTokenResetPassword, changePassword } from '../../data/api/auth/AuthRoutes';
import validErrors from '../../hooks/validErrors';

const ResetPassword = () => {

    const { register, handleSubmit, setValue, watch } = useForm();
    const navigate = useNavigate();
    const { token, userId } = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        validToken();
    }, []);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const validToken = async () => {
        Loading.dots('Validando token');
        try {
            const {data} = await validTokenResetPassword(token, userId);
            if (data?.success) {
                Notify.success(data?.message);
                setValue('token', token);
                setData(data);
            }
        } catch (error) {
            validErrors(error);
            setData(error?.response?.data);
        } finally {
            Loading.remove();
        }
    }
    
    const onSubmit = async (dataForm) => {
        setSubmit(true);
        try {
            const { data } = await changePassword(userId, dataForm);

            if (data?.success) {
                Notify.success(data?.message);
                navigate('/ganacol');
            }
        } catch (error) {
            validErrors(error);
        } finally {
            setSubmit(false); 
        }
    }

    return (
        <Box
            width={'100%'}
            minHeight={'90vh'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            sx={{
                background: '#E2E2E2'
            }}
        >

            <Card sx={{ maxWidth: '500px', paddingTop: '.5rem' }}>
                <Typography variant='h4' textAlign={'center'}>Recupera tu contraseña</Typography>
                {!data?.success && (
                    <Typography variant={'body'} color={'error'} textAlign={'center'} sx={{display: 'block'}}>{data?.error}</Typography>
                )}
                <CardContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>

                            {/* PASSWORD */}
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel htmlFor="password">Nueva Contraseña</InputLabel>

                                    <OutlinedInput
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Nueva Contraseña"
                                        label="Nueva Contraseña"
                                        {...register('password')}
                                        disabled={submit || !data?.success}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            {/* CONSFIRM PASSWORD */}
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel htmlFor="password_confirmation">Confirmar Contraseña</InputLabel>

                                    <OutlinedInput
                                        id="password_confirmation"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Confirmar Contraseña"
                                        label="Confirmar Contraseña"
                                        {...register('password_confirmation')}
                                        disabled={submit || !data?.success}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="tog-pass-conf"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            {/* SUBMIT */}
                            <Grid item xs={12}>
                                <LoadingButton
                                    fullWidth
                                    loading={submit}
                                    loadingPosition="start"
                                    startIcon={<i className="fa-solid fa-floppy-disk"></i>}
                                    variant="contained"
                                    size="large"
                                    type='submit'
                                    disabled={!data?.success}
                                >
                                    <span>Cambiar</span>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Box>
    )
}

export default ResetPassword