import { Notify } from "notiflix";

const validErrors = (error) => {
    try {
        if (typeof error?.response.data.errors == "object") {
            try {
                const errorResponse = error.response.data.errors || {};
                Object.values(errorResponse).map((item) => {
                    item.map(it => {
                        Notify.failure(it);
                    })
                });
            } catch (err) {
                Notify.failure(err);
            }
        } else {
            Notify.failure(error?.response.data.error || error?.response.data.message);
        }
    } catch (errorCatch) {
        Notify.failure('Error desconocido.');
        console.log(errorCatch);
    }
}

export default validErrors