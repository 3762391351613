import React from 'react'
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { TimelineDot } from '@mui/lab';

const TimeLineItemResume = ({ last = false, children }) => {
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot />
                {!last && (
                    <TimelineConnector />
                )}
            </TimelineSeparator>
            <TimelineContent>{children}</TimelineContent>
        </TimelineItem>
    )
}

export default TimeLineItemResume