import { TabContext, TabPanel } from '@mui/lab';
import { Card, CardContent, CircularProgress, Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUserByUuId } from '../../../data/api/users/UserRoutes';
import useIndex from '../../../hooks/useIndex';
import UserHistory from './tabs/UserHistory';
import UserStadistics from './tabs/UserStadistics';
import UserConfig from './tabs/UserConfig';
import { useNavigate } from 'react-router-dom';
import validErrors from '../../../hooks/validErrors';
import UserTabsOption from './tabs/UserTabsOption';

const UserProfile = ({ uuid }) => {
    const serverUrl = process.env.REACT_APP_BACK_URL;
    const { token } = useIndex();
    const navigate = useNavigate();

    const [user, setUser] = useState([]);
    const [panel, setPanel] = useState('1');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        try {
            const { data } = await getUserByUuId(token, uuid);
            setUser(data?.data);
        } catch (error) {
            validErrors(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box sx={{
            minHeight: '100%',
            background: '#f0f2f5',
            padding: '1rem'
        }}>
            <Card sx={{ marginBottom: '1rem', padding: '.3rem 1rem' }}>
                <IconBack onClick={() => navigate(`/ganacol/dashboard/users`)} >
                    <i className='fa-solid fa-arrow-left'></i> Volver
                </IconBack>
            </Card>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <UserCard className="user-card user-card-side">
                        <div className="box">
                            <div className="img">
                                <img src={serverUrl + user?.avatar} />
                            </div>
                            <h2>{user?.name}<br /><span>{user?.role}</span></h2>
                            <p>Registrado el {user?.created_at} lleva un total de ({user?.active_days}) dias en el sistema, y ha realizado un total de ({user?.games_count}) juegos</p>

                            {user?.role_id != 4 ? (
                                <div className="menu">
                                    <div className={panel == '1' ? "menu-item active" : "menu-item"}>
                                        <a className="" onClick={() => setPanel('1')}>
                                            <i className="fa-solid fa-clock-rotate-left" aria-hidden="true"></i> Historial
                                        </a>
                                    </div>
                                    <div className={panel == '2' ? "menu-item active" : "menu-item"}>
                                        <a className="" onClick={() => setPanel('2')}>
                                            <i className="fa-solid fa-chart-column" aria-hidden="true"></i> Estadisticas
                                        </a>
                                    </div>
                                    <div className={panel == '99' ? "menu-item active" : "menu-item"}>
                                        <a className="" onClick={() => setPanel('99')}>
                                            <i className="fa-solid fa-gear" aria-hidden="true"></i> Configuración
                                        </a>
                                    </div>
                                </div>
                            ) : (

                                <div className="menu">
                                    <div className={panel == '1' ? "menu-item active" : "menu-item"}>
                                        <a className="" onClick={() => setPanel('1')}>
                                            <i className="fa-solid fa-handshake" aria-hidden="true"></i> Convenios
                                        </a>
                                    </div>

                                    <div className={panel == '2' ? "menu-item active" : "menu-item"}>
                                        <a className="" onClick={() => setPanel('2')}>
                                            <i className="fa-solid fa-list" aria-hidden="true"></i> Colecciones
                                        </a>
                                    </div>
                                    <div className={panel == '3' ? "menu-item active" : "menu-item"}>
                                        <a className="" onClick={() => setPanel('3')}>
                                            <i className="fa-solid fa-chart-column" aria-hidden="true"></i> Estadisticas
                                        </a>
                                    </div>
                                    <div className={panel == '99' ? "menu-item active" : "menu-item"}>
                                        <a className="" onClick={() => setPanel('99')}>
                                            <i className="fa-solid fa-gear" aria-hidden="true"></i> Configuración
                                        </a>
                                    </div>
                                </div>
                            )}

                        </div>
                    </UserCard>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Card>
                        <CardContent>
                            <UserTabsOption 
                                loading={loading} 
                                panel={panel}
                                user={user}
                                loadData={loadData}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
const IconBack = styled.span`
    font-size: 1.2rem;
    cursor: pointer;
    position: relative;
    i {
        transition: all .3s ease;
        padding: 5px;
        box-sizing: border-box;
        border: 2px solid #FFF;
    }
    &:hover {
        text-decoration: underline;
        i {
            border: 2px solid #000;
            border-radius: 50%;
        }
    }

`;
const UserCard = styled.div`
background: #fff;
box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
border-radius: 10px;
transition: 0.5s;
    &-side {
        width: 300px;
        min-height: 400px;
        margin-right: 30px;
    }

    .box {
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        .img {
            width: 50%;
            aspect-ratio: 1/1;
            border: 2px #AAA solid;
            margin: 0 auto;
            border-radius: 50%;
            overflow: hidden;
            img {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h2 {
            font-size: 20px;
            color: #262626;
            margin: 20px auto;
            span {
                font-size: 14px;
                background: #e91e63;
                color: #fff;
                display: inline-block;
                padding: 4px 10px;
                border-radius: 15px;
            }
        }
        p {
            color: #262626;
            font-size: 1rem;
        }
        span {
            display: inline-flex;
        }
        .menu {
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 0;
            &-item {
                .icon {
                    margin-right: 10px;
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    color: #AAA;
                    font-size: 16px;
                    transition: 0.5s;
                    padding: 10px;
                    border-top: 1px solid #efefef;
                    cursor: pointer;
                    i {
                        margin: 0 1rem 0 0;
                    }
                }
                &:hover a {
                    color: #1C1C1C;
                }

                &.active a {
                    color: #e91e63;
                }
            }
        }
    }
`;

export default UserProfile